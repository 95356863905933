import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import "../../App.css"

const MultiSelectDropDown = (props) => {

    const [selected, setSelected] = useState([]);

    const handleOnChange = (objs) => {
        setSelected(objs)
        props.setParentDropdownValue(objs, props.title)
        // console.log(objs)
    }
    useEffect(() => {
        if (props.selected === true) {
            setSelected([])
        }
    }, [props.selected])

    return (
        <>
            <label style={{ paddingLeft: "5px", fontSize: "small" }}>{props.title}</label>
            {/* <pre>{JSON.stringify(selected)}</pre> */}
            <MultiSelect
                className="brand-category-multi-select-custom"
                options={props.options}
                value={selected}
                onChange={handleOnChange}
                labelledBy="Select"
                disableSearch={true}
                singleSelect={true}
            />
        </>
    );
};

export default MultiSelectDropDown;