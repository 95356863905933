// We are defining all the API ENDPOINTS at one location
//Neccessary changes will be applied to everywhere

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const GRAPH_BASE_URL = process.env.REACT_APP_GRAPH_BASE_URL;
export const AUTH = "auth/"
export const LOGIN = "login/"
export const REFRESH = "refresh/"
export const ACCOUNTS = "accounts/"
export const USERS = "users/"
export const DATES = "dates/"
export const INPUT_FORMS = "input_forms/"
export const COUNTRIES = "countries/"
export const FORMS = "forms/"
export const EDGE_ECOMM = "edge_ecomm/"
export const KBIS = "kbis/"
export const ADMIN = "admin/"
export const REQUESTS = "requests/"
export const LIST = "list/"
export const EDIT_CHECK = "edit_check/"
export const HANDLE = "handle/"
export const CONSOLIDATED_DATA = "consolidated_data/"
export const REJECT_CHECK = "reject-check/"
export const REQUEST_STATUS = "request-status/"
export const emission_2030_targets = "roadmap/"
export const EXPORT = "export/"
export const SKU_PERF = "sku_perf/"
export const EXCLUSIONS = "exclusions/"
export const BEST_PERFORMING = "best_performing/"
export const NEWLISTANDDELIST = "newlistanddelist/"
export const STATUSES = "statuses/"
export const TYPES = "types/"
export const MATERIALS = "materials/"
export const CUTOFFTIME = "cut_off_time/"
export const MFA = "mfa/"
export const MFA_IMPORT_DATA = "mfa_import_data/"
export const FILTERS = "filters/"
export const BRAND_CATEGORY_MAPPINGS = "brand_category_mappings/"
export const CHANNEL_CATEGORY_MAPPINGS = "channel_mappings/"
export const DELETE = "delete/"
export const INPUT_SUGGESTIONS = "input_suggestions/"
export const CHANNEL_INPUT_SUGGESTIONS = "input_suggestions/"
export const BUSINESS_UNITS = "business_units/"
export const QUARTERS = "quarters/"
export const AVAILABLE_QUARTERS = "available_quarters/"
export const CATEGORIES = "categories/"
export const BULK_UPDATE = "bulk-update/"
export const PACKAGE_MAPPINGS = "package_mappings/"
export const DIM_COMPANY_MAPPINGS = "company_mappings/"
export const STMS = "stms/"
export const WEEKS = "weeks/"
// Custom Hierarchy
export const CUST_HIER = "customhier/"
export const ADD_NEW_HIER = "addhier/"
export const EDIT_HIER = "edithier/"
export const IMPORT_DATA = "importdata/"
export const TABLE_DATA = "tabledata/"
export const DELETE_DATA = "deletedata/"
export const COUNTRY_LIST = "countrylist/"
export const HIERARCHY_LIST = "hierarchylist/"
export const EXPORT_DATA = "exportdata/"
export const OBJECT_LIST = "objtypelist/"

//manual adjustment number
export const OPSRPT = "ops_rpt/"
export const MANUALADJUSTMENT = "manual_adjustment/"
export const IMPORTEXCEL = "import/"
export const UPDATE = "update/"
export const SALESORG = "salesorg/"
export const CCHWEEK = "cchweek/"
export const RAWDATA = "rawData/"
export const FIRST = "first/"

//ereatil
export const CATEGORY_BRAND_MAPPING= "category_brand_mapping/";
export const BEYOND_CORE_ASSORTMENT = "beyond_core_assortment/";
export const LAST_RECORD = "lastRecord/";
export const CREATE = "create/";
export const KBI_ID = "KBI_ID/";
export const ACTIVE_CUSTOMERS = "active_customers";
export const UPLOAD_NSR_BP = "nsr_bp/";
export const UPLOAD_VOLUME_BP = "volume_bp/";
export const UPLOAD_BC_BP = "bc_bp/";
export const GET_BP = "get_business_plan";

//price forecast
export const PRC_FRC = "price_forecast/"
export const IMPORT = "import_data/"
export const DATA = "data/"