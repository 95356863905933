import React, { FC, useEffect, useState } from "react";
import { Input, Select } from "antd";
import { COUNTRY, COUNTRY_CODE, DROPDOWN_COLUMNS, PRIORITY, PRIORITY_DROPDOWN } from "./constants";
import { useSelector } from "react-redux";


const EditableCell:FC<any> = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	...restProps
}) => {
	const [editing, setEditing] = useState(false);
	const inputRef = React.useRef<any>(null);
	let options:{label:string, value:string}[] = [];

	const isDropdown = DROPDOWN_COLUMNS.includes(dataIndex)

    const countries = useSelector((state:any) => state.eCommCustomers.countries);
	if (dataIndex === COUNTRY) options = [...countries, { label: 'Armenia', value: 'AM' }];
    if (dataIndex === PRIORITY) options = PRIORITY_DROPDOWN

	useEffect(() => {
		if (editing) inputRef.current?.focus();
	}, [editing]);

	const toggleEdit = () => {
		setEditing(!editing);
	};

	const saveDropdown = async (newValue:{label:string, value:string}) => {
		handleSave({
			...record,
			[dataIndex]: newValue.label,
			...(dataIndex === COUNTRY ? { [COUNTRY_CODE]: newValue.value } : {})
		});
	}

	const save = async (values:any) => {
		try {
			toggleEdit();
			handleSave({
				...record,
				[dataIndex]: values.target.value
			});
		} catch (errInfo) {
			console.log('Save failed:', errInfo);
		}
	};

	let childNode = children;


	if (editable) {
		childNode = editing ? (
			<>
				{isDropdown &&
					<Select
						style={{width: '100%'}}
						labelInValue
						defaultValue={{
							label: record[dataIndex],
							value: record[COUNTRY_CODE]
						}}
						options={options}
						onChange={saveDropdown}
					/>
				}
				{!isDropdown && <Input defaultValue={record[dataIndex]} ref={inputRef} onPressEnter={save} onBlur={save} />}
				
			</>
		) : (
			<div
				className="editable-cell-value-wrap"
				style={{
					paddingInlineEnd: 24,
				}}
				onClick={toggleEdit}
			>
				{children}
			</div>
		);
	}
	return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;