export const SET_MESSAGE = 'SET_E_RETAIL_MESSAGE';

export const SET_COUNTRIES_LOADING = 'SET_E_RETAIL_COUNTRIES_LOADING';
export const SET_COUNTRIES = 'SET_E_RETAIL_COUNTRIES';

export const SET_PERIODS_LOADING = 'SET_E_RETAIL_PERIODS_LOADING';
export const SET_PERIODS = 'SET_E_RETAIL_PERIODS';

export const SET_KBI_MASTER = 'SET_KBI_MASTER';
export const SET_KBI_MASTER_UNEDITED = 'SET_KBI_MASTER_UNEDITED';
export const SET_KBI_MASTER_LOADING = 'SET_KBI_MASTER_LOADING';

export const SET_BEYOND_CORE_RAW_TABLE = 'SET_BEYOND_CORE_RAW_TABLE';
export const SET_BEYOND_CORE_RAW_TABLE_LOADING = 'SET_BEYOND_CORE_RAW_TABLE_LOADING';


export const SET_BEYOND_CORE_MASTER_LIST = 'SET_BEYOND_CORE_MASTER_LIST';
export const SET_BEYOND_CORE_MASTER_LIST_LOADING = 'SET_BEYOND_CORE_MASTER_LIST_LOADING';
export const SET_BEYOND_CORE_KBI_INPUTS = 'SET_BEYOND_CORE_KBI_INPUTS';
export const SET_BEYOND_CORE_KBI_INPUTS_PREV_PERIOD = 'SET_BEYOND_CORE_KBI_INPUTS_PREV_PERIOD';

export const SET_KBI_COMMENT = 'SET_KBI_COMMENT';
export const SET_LAST_EDITED_BY = 'SET_LAST_EDITED_BY';
export const SET_KBI_INPUT_VALUES_LOADING = 'SET_KBI_INPUT_VALUES_LOADING';
export const SET_KBI_INPUT_VALUES = 'SET_KBI_INPUT_VALUES';
export const SET_KBI_INPUT_VALUES_PREV_PERIOD = 'SET_KBI_INPUT_VALUES_PREV_PERIOD';


export const SET_BEYOND_CORE_KBIS = 'SET_BEYOND_CORE_KBIS';
export const SET_BEYOND_CORE_KBIS_LOADING = 'SET_BEYOND_CORE_KBIS_LOADING';

export const SET_BP_DATA = 'SET_BP_DATA';
export const UPLOADING_BP_DATA = 'UPLOADING_BP_DATA';

export const RESET_DATA_E_RETAIL = 'RESET_DATA_E_RETAIL';
