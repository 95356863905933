import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { read, utils, writeFile } from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import MultiSelectDropDown from './multiSelectDropdown'
import toast, { Toaster } from 'react-hot-toast';
import Papa from 'papaparse'

import { EmissionColumns,RoadmapTargetColumns, quarter } from './EmissionColumns';
import {
    getEmissionData, sendUploadedRoadmapFile, updatingDropdown,
    editEmissionRow, deleteEmissionRow
} from '../../actions/emission_action';
import Loading from '../loadingHome';
//import { country, region, bu, Relations } from './EmissionFilter'
import { useHistory, } from "react-router-dom";
import TableList from './TableList'
import { CSVLink } from "react-csv";
import DeleteModal from './DeleteModal';
import EditModal from './EditModal';
import CommunityParticipantTable from '../StewardShip/PeopleCommunity/CommunityPartTable';


const RoadmapTarget = () => {

    const history = useHistory();
    const dispatch = useDispatch()
    const EmissionList = useSelector((state) => state.emissions.tableData)
    const country = useSelector((state) => state.emissions.countryList)
    const region = useSelector((state) => state.emissions.regionList)
    const bu = useSelector((state) => state.emissions.buList)
    const headers = useSelector((state) => state.emissions.columnList)
    const loading = useSelector((state) => state.emissions.loading)
    const matrix = useSelector((state) => state.emissions.matrix)
    const toastData = useSelector((state) => state.emissions.toast)

    const [selectedRegion, setSelectedRegion] = useState([])
    const [selectedBU, setSelectedBU] = useState([])
    const [selectedCountry, setSelectedCountry] = useState([])
    const [selectedQuarter, setSelectedQuarter] = useState([])
    const [tableData, setTableData] = useState()
    const [exportData, setExportData] = useState([])
    const [checkboxList, setCheckboxList] = useState([])
    const [checkboxId, setCheckboxId] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [editTable, setEditTable] = useState(false)
    const [selectedValue, setSelectedValue] = useState(EmissionColumns)
    const [editRow, setEditRow] = useState({})
    const [selectedDropDown, setSelectedDropDown] = useState([])
    const disabledOption = ['region', 'bu', 'country', 'quarter']

    useEffect(() => {
        let updatedRegionData = []
        if (selectedRegion.length > 0) {
            for (let char of EmissionList) {
                if (selectedRegion.includes(char.region)) {
                    updatedRegionData.push(char)
                }
            }
        }
        else {
            updatedRegionData = EmissionList
        }
        let upDatedBuData = []
        if (selectedBU.length > 0) {
            for (let char of updatedRegionData) {
                if (selectedBU.includes(char.bu)) {
                    upDatedBuData.push(char)
                }
            }
        } else {
            upDatedBuData = updatedRegionData
        }
        let updatedCountryData = []
        if (selectedCountry.length > 0) {
            for (let char of upDatedBuData) {
                if (selectedCountry.includes(char.country)) {
                    updatedCountryData.push(char)
                }
            }
        }
        else {
            updatedCountryData = upDatedBuData
        }
        let updatedQuarterData = []
        if (selectedQuarter.length > 0) {
            for (let char of updatedCountryData) {
                if (selectedQuarter.includes(char.quarter)) {
                    updatedQuarterData.push(char)
                }
            }
        }
        else {
            updatedQuarterData = updatedCountryData
        }
        setTableData(updatedQuarterData)
    },
        [selectedBU, selectedCountry, selectedRegion, selectedQuarter])

    useEffect(() => {
        if (tableData && tableData.length > 0) {
            let res = tableData.map(({ id, selected,quarter,"Scope 1 + 2 Intensity (g CO2e/lpb)": scope,...rest }) => ({ ...rest }));
            setExportData(res)
            // console.log("res",res)
        }
    }, [tableData])
    useEffect(() => {
        toast.promise(dispatch(getEmissionData('emission_2030_targets','B020')), {
            loading: "Loading. wait a moment!",
            success: 'Data loaded Successfully!',
            error: 'Something went wrong.',
        },
            {
                position: 'top-center',
                className: 'toast-popup',
            });
        dispatch(getEmissionData('emission_2030_targets','B020'))
    }, [])

    useEffect(() => {
        setTableData(EmissionList)
        let res = EmissionList.map(({ id, selected,quarter,"Scope 1 + 2 Intensity (g CO2e/lpb)": scope,...rest }) => ({ ...rest,scope }));;
        setExportData(res)
    }, [EmissionList])

    useEffect(() => {
        if (toastData.message && toastData.message.length > 0 && toastData.type === "error") {
            toast.error(toastData.message, { position: 'top-right', duration: 2000, });
        }
        else if (toastData.message && toastData.message.length > 0 && toastData.type === "success") {
            toast.success(toastData.message, { position: 'top-right', duration: 2000, });
        }
    }, [toastData.message])

    useEffect(() => {
        if (selectedDropDown === 'Region') {
            dispatch(updatingDropdown(selectedDropDown, selectedRegion, matrix))
            if (selectedCountry.length !== 0) {
                dispatch(updatingDropdown('Country', selectedCountry, matrix))
            }
            if (selectedBU.length !== 0) {
                dispatch(updatingDropdown('BU', selectedBU, matrix))
            }
        }
        if (selectedDropDown === 'BU') {
            dispatch(updatingDropdown(selectedDropDown, selectedBU, matrix))
            if (selectedCountry.length !== 0) {
                dispatch(updatingDropdown('Country', selectedCountry, matrix))
            }
            // if (selectedRegion.length !== 0) {
            //     dispatch(updatingDropdown('Region', selectedRegion, matrix))
            // }
        }
        if (selectedDropDown === 'Country') {
            dispatch(updatingDropdown(selectedDropDown, selectedCountry, matrix))
            // if (selectedBU.length !== 0) {
            //     dispatch(updatingDropdown('BU', selectedBU, matrix))
            // }
            // if (selectedRegion.length !== 0) {
            //     dispatch(updatingDropdown('Region', selectedRegion, matrix))
            // }
        }
    }, [selectedRegion, selectedBU, selectedCountry])

    const setDropDownValues = (val, valType) => {
        if (valType === 'Region') {
            let regionVal = []
            if (val.length > 0) {
                for (let char of val)
                    regionVal.push(char.value)
            }
            setSelectedRegion(regionVal)
        }
        if (valType === 'BU') {
            let buVal = []
            if (val.length > 0) {
                for (let char of val)
                    buVal.push(char.value)
            }
            setSelectedBU(buVal)
        }
        if (valType === 'Country') {
            let countryVal = []
            if (val.length > 0) {
                for (let char of val)
                    countryVal.push(char.value)
            }
            setSelectedCountry(countryVal)
        }
        if (valType === 'Quarter') {
            let quarterVal = []
            if (val.length > 0) {
                for (let char of val)
                    quarterVal.push(char.value)
            }
            setSelectedQuarter(quarterVal)
        }
        setSelectedDropDown(valType)
    }

    const setSelectedListInParent = (value) => {
        let ids = []
        let lists = []
        for (let char of value) {
            ids.push(char.id)
            lists.push(char)
        }
        setCheckboxList(lists)
        setCheckboxId(ids)
    }
    const initialData = () => {
        dispatch(getEmissionData('emission_2030_targets','B020'))
    }
    const handleEditClickInParent = (row) => {
        setOpenEditModal(true)
        let obj = {}
        for (let header of headers) {
            obj[header.key] = row[header.key]
        }
        obj['id'] = row.id
        setEditRow(obj)
    }

    const handleEditTable = () => {
        setEditTable(true)
    }

    const handleOnChange = (e) => {

        Papa.parse(e.target.files[0], {
            header: true,
            complete: (results) => {
                // console.log("filedata",results.data,tableData)
                //dispatch(sendUploadedFile(tableData, results.data))
                toast.promise(dispatch(sendUploadedRoadmapFile(tableData, results.data)), {
                    loading: "Loading. wait a moment!",
                    success: 'Data added successfully.',
                    error: 'Something went wrong.',
                },
                    {
                        position: 'top-center',
                        className: 'toast-popup',
                    });
            }
        })
        setTimeout(() => {
            e.target.value = ''
        }, 1500)

    }
    const closeDeleteModal = () => {
        setOpenDeleteModal(false)
    }
    const handleCancelButton = (isclick) => {
        setEditTable(isclick)
    }
    const closeEditModal = () => {
        setOpenEditModal(false)
    }
    const handleDeleteButton = () => {
        setOpenDeleteModal(true)
    }

    const handleDelete = () => {
        setCheckboxId([])
        setCheckboxList([])
        setOpenDeleteModal(false)
        setSelectedBU([])
        setSelectedCountry([])
        setSelectedRegion([])
        setSelectedQuarter([])
        toast.promise(dispatch(deleteEmissionRow('emission_2030_targets','B020',tableData, checkboxList, checkboxId)), {
            loading: "Loading. wait a moment!",
            success: 'Row(s) deleted successfully!',
            error: 'Something went wrong.',
        },
            {
                position: 'top-center',
                className: 'toast-popup',
            });
        //dispatch(deleteEmissionRow(tableData, checkboxList, checkboxId))
        //let res = tableData.filter(tdata => !checkboxId.includes(tdata.id))
        //setTableData(res)

    }
    const handleSelectChange = (event) => {
        setSelectedValue(event)
        if (event.value !== selectedValue.value) {
            setEditTable(false)
            dispatch({
                type: 'SHOW_TOAST',
                payload: {}
            })
            dispatch({
                type: 'SET_INITIAL_STATUS',
            })
        }
    }
    const handleEdit = (rowData) => {

        setEditRow(rowData);
        setCheckboxId(rowData.id);
        setSelectedDropDown(rowData.dropDown);
        setCheckboxList(rowData.selected);
        setOpenEditModal(false);
        let updatedTableData = EmissionList.map((rowData) => {
            if (rowData.id === rowData.id) {
                return { ...rowData, dropDown: selectedDropDown };
            }
            return rowData;
        });

        toast.promise(dispatch(editEmissionRow('emission_2030_targets','B020', updatedTableData, rowData)), {
            loading: "Loading. wait a moment!",
            success: 'Row edited successfully!',
            error: 'Something went wrong.',
        },
            {
                position: 'top-center',
                className: 'toast-popup',
            });

        setTableData(updatedTableData);
        // setPreviousTableData(updatedTableData);
        setSelectedQuarter([])
        setSelectedBU([])
        setSelectedCountry([])
        setSelectedRegion([])
        setEditTable(false);
    };
    // console.log("header",headers)

    const filterdata = (header) => {
            return  header.filter(val => val.label != "Quarter" && val.label != "Scope 1 + 2 Intensity (g CO2e/lpb)")
        }


    function changeObjectSequence(obj, newSequence) {
        const newObj = {...obj};
        const addedbyObj = {};
        for (let prop of newSequence) {
          if (newObj.hasOwnProperty(prop)) {
            addedbyObj[prop] = obj[prop];
          }
          else{
            newObj[prop] = obj[prop];
          }
        }
        delete (newObj?.added_by)
        delete (newObj?.updated_by)
        delete (newObj?.created_date)
        delete (newObj?.updated_date)
        let objjj = {
            ...newObj,
            ...addedbyObj
        };
        return objjj;
      }

    const chnageExportData = (data) => {
        const newExport = []
        const newSequence =  [
            "added_by",
            "updated_by",
            "created_date",
            "updated_date",
    ]
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            const newMyObj = changeObjectSequence(element, newSequence);
            newExport.push(newMyObj) 
        }
        return newExport;
    }

    function changeHeaderRank(header) {
        const arrOne = []
        const arrTwo = []
        header = header.filter(val => val.key !== "cut_off");
        header.map(val => {
            if (val.label == "Added_By" || val.label == "Updated_By" || val.label == "Created_Date" || val.label == "Updated_Date" || val.label == "added_by" || val.label == "updated_by" || val.label == "created_date" || val.label == "updated_date") {
                arrOne.push(val)
            }
            else {
                arrTwo.push(val)
            }
        })
        const seq = ["Created_Date", "created_date", "Added_By", "added_by", "Updated_Date","updated_date", "Updated_By","updated_by"];
        const newArr = []
        seq.map(val => {
            const head = header.filter(newval => newval.key == val);
            if (head?.length > 0) {
                newArr.push(head[0])
            }
        })
        const mergedArray = arrTwo.concat(newArr);
        return mergedArray
    }

    const headermapping = (header) => {
        const hea = {};
        header = changeHeaderRank(header);
        header.map(val => {
            const key = val.key;
            hea[key] = val.label;
        })
        return hea
    }

    // Map header names to values
    const mappedData = exportData.map(item => {
        const ndata = headermapping(headers); 
        ndata['added_by'] = 'Created_by'
        return Object.keys(ndata).reduce((obj, key) => {
            obj[ndata[key]] = item[key];
            return obj;
        }, {});
    });
    const handleExport = () => {
        const wb = utils.book_new();
        mappedData.forEach((item) => {
            item['Abs Emission 1 + 2 + 3 (tonnes)'] = parseFloat(item['Abs Emission 1 + 2 + 3 (tonnes)'].replace(/,/g, ''))
            item['Abs Emission Scope 1 + 2 (tonnes)'] = parseFloat(item['Abs Emission Scope 1 + 2 (tonnes)'].replace(/,/g, ''))
            item['Abs Emission Scope 3 (tonnes)'] = parseFloat(item['Abs Emission Scope 3 (tonnes)'].replace(/,/g, ''))
        })
        const ws = utils.json_to_sheet(mappedData);
        utils.book_append_sheet(wb, ws, 'Data');
        writeFile(wb, '2030_Roadmap_Target.xlsx');
    }
    return (
        <div>
            {/* {region && region.length > 0 && bu && bu.length > 0 && country && country.length > 0
                && year && year.length && */}
            <div className="user-view-container">
                <h5 style={{ marginLeft: "30rem" }}>This is 2030 Roadmap Target</h5>
                <div className="brand-category-header-container row">

                    < div className="col-10 brand-category-filter-container">
                        <div className="brand-category-dropdown-container">
                            <MultiSelectDropDown title="Region" selected={selectedRegion.length === 0} options={region} setParentDropdownValue={setDropDownValues} />
                        </div>
                        <div className="brand-category-dropdown-container">
                            <MultiSelectDropDown title="BU" selected={selectedBU.length === 0} options={bu} setParentDropdownValue={setDropDownValues} />
                        </div>
                        <div className="brand-category-dropdown-container">
                            <MultiSelectDropDown title="Country" selected={selectedCountry.length === 0} options={country} setParentDropdownValue={setDropDownValues} />
                        </div>
                        <div style={{ margin: "2rem 0rem 0rem 0rem", fontSize: ".75rem" }}>
                            <input
                                type={"file"}
                                id={"csvFileInput"}
                                accept={".csv"}
                                onChange={handleOnChange}
                            />
                        </div>
                    </div>

                    <div className="col-2 brand-category-add-delete-export-button-container">
                    <button onClick={handleExport} className="ex-pc brand-category-export-button mt-3 col" type="button" value="Export Data" >Export</button>

                        {/* <CSVLink filename='2030_roadmap_targets' headers={changeHeaderRank(headers)} data={exportData}>
                            <button className="ex-pc brand-category-export-button mt-3 col" type="button" value="Export Data" >Export</button>
                        </CSVLink> */}
                        <div className="ym-zw">
                            {!editTable ? (
                                <button className="bt-pc brand-category-button mt-3 col" type="button" value="+ Add New" onClick={() => handleEditTable()}><FontAwesomeIcon icon={faEdit} className="search-icon" size="1x" /> Edit</button>
                            ) : (
                                <button className="bt-pc brand-category-button mt-3 col" type="button" value="+ Add New" ><FontAwesomeIcon icon={faEdit} className="search-icon" size="1x" /> Edit</button>
                            )}
                        </div>
                    </div>
                    {tableData && tableData.length > 0 &&
                        <div style={{ marginLeft: "-0.8rem", marginTop: "0.5rem" }} className="brand-category-table-container">
                            <CommunityParticipantTable columns={RoadmapTargetColumns}
                                tableDataList={tableData}
                                initialData={initialData}
                                editTable={editTable}
                                handleEdit={handleEdit}
                                handleCancelButton={handleCancelButton}
                                isRoadmap = {true}

                                // setSelectedListInParent={setSelectedListInParent}
                                // handleEditClickInParent={handleEditClickInParent}
                            />
                            
                        </div>
                    }
                    {openEditModal ?
                    <EditModal
                        message={"Edit"}
                        openDialogueBox={openEditModal}
                        handleEditDialogueBox={closeEditModal}
                        handleEdit={handleEdit}
                        rowSelected={editRow}
                        disabledOption={disabledOption}
                        type={selectedValue.value === 'people_and_community' ? 'pnc' : 'zwc'}
                    /> : ''}
                </div>
            </div>
            {/* // } */}
            <Toaster />
        </div >

    )
}

export default RoadmapTarget