import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import '@rmwc/chip/styles';
import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import toast, { Toaster } from 'react-hot-toast';
import DropDownMultiSelectMFACustom from '../../components/dropDownMultiSelectMFACustom'
import ChannelCategoryDialogueBox from '../../components/brandCategoryDialogueBox';
import DimCompanyFormModal from '../../components/dimCompanyFormModal';
import TableMFADimCompany from '../../components/tableMFADimCompany';
import { BASE_URL, DIM_COMPANY_MAPPINGS, FILTERS, INPUT_SUGGESTIONS, INPUT_FORMS, MFA, EXPORT, DELETE, MFA_IMPORT_DATA } from '../../constant/apiEndpoints';
import { dimCompanyInitailColumnsForm } from '../../constant/constants';
import { channelSuggestionData } from '../../constant/utils';
import APIService from '../../services/APIService';
import Papa from 'papaparse';
const tableHeadColour = '#F2F2F2';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const initialColumns = dimCompanyInitailColumnsForm

//Material UI Styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: tableHeadColour,
        color: theme.palette.common.black,
        fontWeight: 600,
        cursor: 'pointer'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

}));
//Material UI Styles


const RequestStatus3 = (props) => {

    const [adminIds, setAdminIds] = useState(props.adminIds);
    const [dimCompanyMappingList, setDimCompanyMappingList] = useState([]); //Table Data Handle
    const [countryList, setCountryList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [businessUnitList, setBusinessUnitList] = useState([]);
    const [selectedCountryList, setSelectedCountryList] = useState([]);
    const [selectedRegionList, setSelectedRegionList] = useState([]);
    const [selectedBusinessUnitList, setSelectedBusinessUnit] = useState([]);
    const [selectedList, setSelectedList] = useState([]); //Button Handle
    const [columns, setColumns] = useState(initialColumns); //Columns Handle
    const [dropDownTimer, setDropDownTimer] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [selectedIdToEdit, setSelectedIdToEdit] = useState();
    const [openDeleteDialogueBox, setOpenDeleteDialogueBox] = useState(false);
    const [suggestions, setSuggestions] = useState(channelSuggestionData);

    const setSelectedListInParent = (value) => {
        setSelectedList(value)
    }

    const handleIsFormModalOpen = (value) => {
        setIsFormModalOpen(value);
        setSelectedIdToEdit();
    }

    const handleDeleteDialogueBox = () => {
        setOpenDeleteDialogueBox(!openDeleteDialogueBox)
    }

    const handleDeleteButton = () => {
        setOpenDeleteDialogueBox(true)
    }

    //Support Function For Filter List
    const createStringFromObjectList = (objs) => {
        let newString = ""
        for (var i = 0; i < objs.length; i++) {
            if (newString.length === 0) {
                newString = objs[i].value
            } else {
                newString = newString + "," + objs[i].value
            }
        }
        return encodeURIComponent(newString)
    }

    //AJAX Call To Load SKU Performance List
    const loadDimCompanyMappingAPICall = async (selectedHomeFilter = "", selectedRegionFilter = "", selectedBusinessUnitFilter = "" ) => {
        let url = BASE_URL + INPUT_FORMS + MFA + DIM_COMPANY_MAPPINGS + `?selected_form=${props.formId}` + selectedHomeFilter + selectedRegionFilter + selectedBusinessUnitFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in channel exlusion list API", response.results.message)
        } else {
            setDimCompanyMappingList(response.results);
        }
        setLoading(false);
    }
    const loadChannelSuggestion = async () => {
        let url = BASE_URL + INPUT_FORMS + MFA + DIM_COMPANY_MAPPINGS + INPUT_SUGGESTIONS + `?selected_form=${props.formId}`
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Channel List API", response.results.message)
        } else {
            if (response.results) {
                setSuggestions(response.results);
            }
        }
    }
    const handleDelete = async (comment) => {
        let url = BASE_URL + INPUT_FORMS + MFA + DIM_COMPANY_MAPPINGS + DELETE
        let body = {
            "form_id": props.formId,
            "comment": comment,
            "ids": selectedList.map((ele) => { return ele.dim_company_id })
        }
        let response = await new APIService().delete(url, body)
        if (response.error) {
            if (response.status == 400) {
                if (response.results.message.includes("This field may not be blank.")) {
                    toast.error('Comment is required!', { position: 'top-center', className: 'toast-popup', duration: 2000 });
                } else {
                    toast.error('Something went wrong.', { position: 'top-center', className: 'toast-popup', duration: 2000 });
                }
            }
        } else {
            toast.success('Data deleted successfully.', { position: 'top-center', className: 'toast-popup', duration: 1000 });
            loadDimCompanyMappingList(true, selectedCountryList, selectedRegionList, selectedBusinessUnitList);
            handleDeleteDialogueBox();
            setSelectedList([])
        }
    }
    const handleExportButton = async () => {

        let selectedCountryFilter = getCountryFilter(null)

        let selectedRegionFilter = getRegionFilter(null)

        let selectedBusinessUnitFilter = getBusinessUnitFilter(null)

        let url = BASE_URL + INPUT_FORMS + MFA + DIM_COMPANY_MAPPINGS + EXPORT + `?selected_form=${props.formId}` + selectedCountryFilter + selectedRegionFilter + selectedBusinessUnitFilter
        fetch(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('access_token')).access}`,
            })
        }).then(response =>
            response.blob().then(blob => {
                var today = new Date()
                let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
                let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = "dim_company_" + date + "_" + time + ".csv";
                a.click();
            })
        );
    }
    const getCountryFilter = (countryIDs) => {
        //Getting User Selected Countries By State/User Action
        let selectedHomeIDs = countryIDs !== null ? countryIDs : selectedCountryList
        let selectedHomeFilter = ""
        if (selectedHomeIDs.length !== 0) {
            selectedHomeFilter = `&selected_country=${createStringFromObjectList(selectedHomeIDs)}`
        }
        return selectedHomeFilter
    }

    const getRegionFilter = (regionIDs) => {
        //Getting User Selected Countries By State/User Action
        let selectedRegionIDs = regionIDs !== null ? regionIDs : selectedRegionList
        let selectedRegionFilter = ""
        if (selectedRegionIDs.length !== 0) {
            selectedRegionFilter = `&selected_region=${createStringFromObjectList(selectedRegionIDs)}`
        }
        return selectedRegionFilter
    }

    const getBusinessUnitFilter = (businessUnitIDs) => {
        //Getting User Selected Countries By State/User Action
        let selectedBusinessUnitIDs = businessUnitIDs !== null ? businessUnitIDs : selectedBusinessUnitList
        let selectedBusinessUnitFilter = ""
        if (selectedBusinessUnitIDs.length !== 0) {
            selectedBusinessUnitFilter = `&selected_business_unit=${createStringFromObjectList(selectedBusinessUnitIDs)}`
        }
        return selectedBusinessUnitFilter
    }

    const loadCountryList = async (regionIDs = selectedRegionList, businessUnitIDs = selectedBusinessUnitList) => {
        //Brand filter
        let selectedRegionFilter = getRegionFilter(regionIDs)
        //Category SAP Filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnitIDs)

        let url = BASE_URL + INPUT_FORMS + MFA + DIM_COMPANY_MAPPINGS + FILTERS + `?selected_form=${props.formId}` + `&filter_type=country` + selectedRegionFilter + selectedBusinessUnitFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setCountryList(response.results);
            }
        }
    }

    //AJAX Call To Load Category SAP List
    const loadRegionList = async (countryIDs = selectedCountryList, businessUnitIDs = selectedBusinessUnitList) => {
        //Brand filter
        let selectedHomeFilter = getCountryFilter(countryIDs)
        //Summarized Brand filter
        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnitIDs);

        let url = BASE_URL + INPUT_FORMS + MFA + DIM_COMPANY_MAPPINGS + FILTERS + `?selected_form=${props.formId}` + `&filter_type=region` + selectedHomeFilter + selectedBusinessUnitFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setRegionList(response.results);
            }
        }
    }

    const loadBusinessUnitList = async (countryIDs = selectedCountryList, regionIDs = selectedRegionList) => {
        //Summarized Brand filter
        let selectedHomeFilter = getCountryFilter(countryIDs);
        //Category SAP Filter
        let selectedRegionFilter = getRegionFilter(regionIDs)

        let url = BASE_URL + INPUT_FORMS + MFA + DIM_COMPANY_MAPPINGS + FILTERS + `?selected_form=${props.formId}` + `&filter_type=business_unit` + selectedHomeFilter + selectedRegionFilter
        let response = await new APIService().get(url);
        if (response.error) {
            console.log("error in Brand Category Mapping List API", response.results.message)
        } else {
            if (response.results.length > 0) {
                setBusinessUnitList(response.results);
            }
        }
    }

    const loadDimCompanyMappingList = async (useTimer, countryIDs, regionIDs, businessUnitIDs) => {
        //Channel filter
        let selectedHomeFilter = getCountryFilter(countryIDs)

        let selectedRegionFilter = getRegionFilter(regionIDs)

        let selectedBusinessUnitFilter = getBusinessUnitFilter(businessUnitIDs)

        //Clearing Timeout Before Adding New Timeout(Previous Timeout)
        if (dropDownTimer) {
            clearTimeout(dropDownTimer);
        }
        //Add Timeout Before Hitting API
        if (useTimer) {
            setDropDownTimer(
                setTimeout(function () {
                    // loadBrandCategoryMappingAPICall(selectedBrandFilter, selectedSummarizedBrandFilter, selectedCategorySAPFilter);
                    toast.promise(loadDimCompanyMappingAPICall(selectedHomeFilter, selectedRegionFilter, selectedBusinessUnitFilter), {
                        loading: "Loading. wait a moment!",
                        success: 'Data load success.',
                        error: 'Something went wrong.',
                    },
                        {
                            position: 'top-center',
                            className: 'toast-popup',
                        });
                }, 1500)
            )
        } else {
            loadDimCompanyMappingAPICall();
        }
    }

    const setDropDownValues = (objs, valueType) => {
        if (valueType === "Country") {
            setSelectedCountryList(objs)
            //To load relevent values in other dropdown filters
            loadRegionList(objs, selectedBusinessUnitList);
            loadBusinessUnitList(objs, selectedRegionList);
        }
        else if (valueType === "Region") {
            setSelectedRegionList(objs)
            //To load relevent values in other dropdown filters
            loadCountryList(objs, selectedBusinessUnitList);
            loadBusinessUnitList(selectedCountryList, objs);
        }
        else if (valueType === "Business Unit") {
            setSelectedBusinessUnit(objs)
            //To load relevent values in other dropdown filters
            loadCountryList(selectedRegionList, objs);
            loadRegionList(selectedCountryList, objs);
        }
        //Loading Brand Mapping List In Table
        loadChannelMappingsBasedOnFilters(objs, valueType);
    }


    const loadChannelMappingsBasedOnFilters = (objs, valueType) => {
        //Loading Brand Category Values which is dependent on Brand Dropdown
        if (valueType === "Country") {
            loadDimCompanyMappingList(true, objs, selectedRegionList, selectedBusinessUnitList);
        }
        //Loading Brand Category Values which is dependent on Summarized Brand Dropdown
        if (valueType === "Region") {
            loadDimCompanyMappingList(true, selectedCountryList, objs, selectedBusinessUnitList);
        }
        //Loading Brand Category Values which is dependent on Category SAP Dropdown
        if (valueType === "Business Unit") {
            loadDimCompanyMappingList(true, selectedCountryList, selectedRegionList, objs);
        }
    }

    const handleEditClickInParent = (id) => {
        setIsFormModalOpen(true)
        setSelectedIdToEdit(id)
    }

    const postSubmitAndEditData = () => {
        loadDimCompanyMappingList(true, selectedCountryList, selectedRegionList, selectedBusinessUnitList);
    }

    const UploadImportData = async (data) => {
        let url = BASE_URL + INPUT_FORMS + MFA + DIM_COMPANY_MAPPINGS + MFA_IMPORT_DATA
        let body = {
            "form_id": props.formId,
            "data": data,
        }
        let response = await new APIService().post(url, body)
        if (response.status == 200){
            loadDimCompanyMappingList(true, selectedCountryList, selectedRegionList, selectedBusinessUnitList);
        }
        else if (response.status == 400){
            toast.error(response.results.message, { position: 'top-center', className: 'cutoff-popup', duration: 2000 });
        }
        else{
            toast.error(response.results.message, { position: 'top-center', className: 'cutoff-popup', duration: 2000 });
        }
    }

    const handleOnChange = (e) => {

        Papa.parse(e.target.files[0], {
            skipEmptyLines: true,
            header: true,
            complete: (results) => {
                toast.promise(UploadImportData(results.data), {
                    loading: "Loading. wait a moment!",
                    success: 'Data added successfully.',
                    error: 'Something went wrong.',
                },
                    {
                        position: 'top-center',
                        className: 'toast-popup',
                    });
            }
        })
        setTimeout(() => {
            e.target.value = ''
        }, 1500)

    }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            loadCountryList()
            loadRegionList()
            loadBusinessUnitList()
            loadChannelSuggestion()
            loadDimCompanyMappingList(false, selectedCountryList, selectedRegionList, selectedBusinessUnitList);
        }
        return function cleanup() {
            mounted = false
        }

    }, [])


    return (
        <div className="user-view-container">
            <div className="brand-category-header-container row">
                <div className="col-10 brand-category-filter-container">
                    <div className="brand-category-dropdown-container">
                        <DropDownMultiSelectMFACustom title="Country" options={countryList} setParentDropdownValue={setDropDownValues} />
                    </div>
                    <div className="brand-category-dropdown-container">
                        <DropDownMultiSelectMFACustom title="Region" options={regionList} setParentDropdownValue={setDropDownValues} />
                    </div>
                    <div className="brand-category-dropdown-container">
                        <DropDownMultiSelectMFACustom title="Business Unit" options={businessUnitList} setParentDropdownValue={setDropDownValues} />
                    </div>
                    <div style={{ margin: "2rem 0rem 0rem 0rem", fontSize: ".75rem" }}>
                        <input
                            type={"file"}
                            id={"csvFileInput"}
                            accept={".csv"}
                            onChange={handleOnChange}
                        />
                    </div>
                </div>
                {props.adminIds.includes(props.formId) ?
                    (<div className="col-2 brand-category-add-delete-export-button-container">
                        <button className="brand-category-export-button mt-3 col" type="button" value="Export Data" onClick={handleExportButton}>Export</button>

                        {selectedList.length === 0 ?
                             <button className="brand-category-button mt-3 col" type="button" value="+ Add New" onClick={() => handleIsFormModalOpen(true)}><FontAwesomeIcon icon={faPlus} className="search-icon" size="1x" /> Add New</button>
                            : <button className="brand-category-button mt-3 col" type="button" value="Delete" onClick={handleDeleteButton}><FontAwesomeIcon icon={faTrash} className="search-icon" size="1x" /> Delete</button>}
                    </div>) : ""}
            </div>
            <div className="brand-category-body">
                <div className='other-mfa-table-holder'>
                    <div className='channel-category-chips-container'>
                        {/* CSS Purpose */}
                    </div>
                    {/* Loader(While Data Loading) */}
                    {loading ? <div className="table-data-note"><Spinner animation="border" />
                        <p></p>
                    </div> : ""}
                    {/* Loader(While Data Loading) */}
                    {dimCompanyMappingList.length > 0 ?
                        <div className="brand-category-table-container">
                            <TableMFADimCompany columns={columns}
                                dimCompanyMappingList={dimCompanyMappingList}
                                setSelectedListInParent={setSelectedListInParent}
                                handleEditClickInParent={handleEditClickInParent}
                                formId={props.formId} adminIds={adminIds} />
                        </div>
                        : ''}
                    {/* No Data Message */}
                    {dimCompanyMappingList.length === 0 && loading === false ? (<div className="table-data-note"><p>No data found.</p>
                        <p></p>
                    </div>) : ""}
                    {/* No Data Message */}
                </div>

            </div>
            {/* Loader */}
            <Toaster />
            {/* Loader */}
            {isFormModalOpen ?
                <DimCompanyFormModal isFormModalOpen={isFormModalOpen} handleIsFormModalOpen={handleIsFormModalOpen}
                    formId={props.formId} selectedIdToEdit={selectedIdToEdit} suggestions={suggestions}
                    postSubmitAndEditData={postSubmitAndEditData}
                />
                : ""}
            {/* Message Dialogue Box */}
            {openDeleteDialogueBox ? <ChannelCategoryDialogueBox openDialogueBox={openDeleteDialogueBox}
                handleDeleteDialogueBox={handleDeleteDialogueBox}
                messageType="Delete"
                message="Are you sure you want to delete this Channel"
                handleDelete={handleDelete} /> : ""}
            {/* Message Dialogue Box */}

        </div>
    );

};

export default RequestStatus3;