import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAddDetails } from '../../actions/steward_action';
import Water from './Water/Water';
import Waste from './Waste/Waste';
import Sourcing from './Sourcing/Sourcing';
import PeopleCommunity from './PeopleCommunity/PeopleCommunity';
import CommunityInvestment from './CommunityInvestment/CommunityInvestment';

const StewardShip = () => {
    const dispatch = useDispatch()
    const buttonList = useSelector((state) => state.emissions.formList)
    //const buttonList = ['Water', 'Waste', 'Sourcing', 'People and Community', 'Community Investment']
    const [selectedValue, setSelectedValue] = useState(buttonList[0])
    const tabList = {
        water_stewardship: 'Water Stewardship',
        waste: 'Packaging Waste', sourcing: 'Sourcing',
        people_and_community: 'People and Communities', community_investments: 'Community Investment'
    }

    const handleClick = (e) => {
        if (e.target.value !== selectedValue) {
            dispatch({
                type: 'SET_INITIAL_STATUS',
            })
            setSelectedValue(e.target.value)
        }
    }

    useEffect(() => {
        dispatch(getAddDetails())
    }, [])

    return (
        <div >
            {buttonList.map((btn) => {
                return (
                    <span style={{ marginLeft: "1rem" }}
                        key={btn}>
                        <button style={{
                            margin: "1rem",
                            backgroundColor: selectedValue === btn ? "#FBC02D" : "white",
                            border: selectedValue === btn ? "1px solid #FBC02D" : "1px solid #E0E0E0",
                            color: "black",
                            borderRadius: "5px",
                            fontSize: ".85rem",
                            width: "12rem",
                            height: "2rem"
                        }}
                            onClick={handleClick}
                            value={btn} >
                            {tabList[btn]}
                        </button >
                    </span>
                )
            }
            )}
            <div>
                {selectedValue === 'water_stewardship' && <Water />}
                {selectedValue === 'waste' && <Waste />}
                {selectedValue === 'sourcing' && <Sourcing />}
                {selectedValue === 'people_and_community' && <PeopleCommunity />}
                {selectedValue === 'community_investments' && <CommunityInvestment />}
            </div>
        </div>
    )
}

export default StewardShip