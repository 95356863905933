import React, { useState, useEffect } from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import { red } from '@mui/material/colors';
import DialogueBox from '../../components/dialogueBox';
import RejectDialogueBox from '../../components/rejectDialogueBox';
import { BASE_URL, EDGE_ECOMM, INPUT_FORMS, ADMIN, LIST, REQUESTS, HANDLE, REJECT_CHECK, BEYOND_CORE_ASSORTMENT } from '../../constant/apiEndpoints';
import APIService from '../../services/APIService';
import Spinner from 'react-bootstrap/Spinner';
import {  Spin } from 'antd';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Admin1 = (props) => {
    const [isLoadingUi, setIsLoadingUi] = React.useState(false);
    const [selectedForm, setSelectedForm] = React.useState(props.formId)
    const [masterChecked, setMasterChecked] = React.useState(false);
    const [selectedList, setSelectedList] = React.useState([]);
    const [valuesSelected, setValuesSelected] = React.useState("");
    const [selectedListBeyond, setSelectedListBeyond] = React.useState([]);
    const [selectedCombinedList, setSelectedCombinedList] = React.useState([]);
    const [kbiRequests, setKbiRequests] = React.useState([]);
    const [openDialogueBox, setOpenDialogueBox] = React.useState(false);
    const [openRejectDialogueBox, setOpenRejectDialogueBox] = React.useState(false);
    const [messageType, setMessageType] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [loading, setLoading] = useState(true);

    // Select/Unselect Table rows
    const onMasterCheck = (e) => {
        const tempList = kbiRequests.map((kbi) => ({
            ...kbi,
            selected: e.target.checked
        }));

        setMasterChecked(e.target.checked);
        setKbiRequests(tempList);

        setSelectedCombinedList(tempList.filter((element) => element.selected));
        setSelectedList(tempList.filter((element) => element.selected && !element.isBeyond));
        setSelectedListBeyond(tempList.filter((element) => element.selected && element.isBeyond));
        setValuesSelected("all");
    }

    // Update List Item's state and Master Checkbox State
    const onItemCheck = (e, item) => {
        const tempList = kbiRequests.map((kbi) => {
            if (kbi.country_id === item.country_id && kbi.date === item.date) {
                return {
                    ...kbi,
                    selected: e.target.checked
                }
            }
            return kbi;
        });

        //To Control Master Checkbox State
        const totalItems = tempList.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        setMasterChecked(totalItems === totalCheckedItems);
        setKbiRequests(tempList);

        const combinedList = tempList.filter((e) => e.selected);
        const newList = tempList.filter((e) => e.selected && !e.isBeyond);
        const newListBeyond = tempList.filter((e) => e.selected && e.isBeyond);
        
        setSelectedCombinedList(combinedList)
        setSelectedList(newList);
        setSelectedListBeyond(newListBeyond);
        
        if (newList.length > 0 && newListBeyond.length > 0) {
            setValuesSelected("all");
        } else if (newList.length > 0) {
            setValuesSelected("eretail");
        } else if (newListBeyond.length > 0) {
            setValuesSelected("beyond");
        }
    }

    const showDialogueBox = (msgType, msg) => {
        setMessage(msg);
        setMessageType(msgType);
        handleDialogueBox();
    }

    // Grouping the objects by category
    // Grouping the objects by category in a single array
    const groupedData = (data, category)  => {
        const sortedData = data.sort((a, b) => a[category].localeCompare(b[category]));
        return sortedData
    }

    const setupKBIRequests = (requests, requestsBeyond) => {
        let newRequests = [];
        for (let i = 0; i < requests?.length; i++) {
            let currentObj = requests[i]
            currentObj['selected'] = false
            currentObj['isBeyond'] = false
            newRequests.push(currentObj)
        }
        let newRequestsBeyond = [];
        
        for (let i = 0; i < requestsBeyond?.length; i++) {
            let currentObj = requestsBeyond[i]
            currentObj['selected'] = false
            currentObj['isBeyond'] = true
            newRequestsBeyond.push(currentObj)
        }
        const newArray = newRequests.concat(newRequestsBeyond);
        let newArr =  groupedData(newArray, "country_title")
        console.log("newArray", newArray)
        setKbiRequests(newArr);
        setLoading(false);
        // setKbiRequestsBeyond(newRequestsBeyond)
    }

    function hasValue(val){
        if (val?.length === 0 || val == null) {
            return ""
          } 
          else{
            return "-"
          }
    }

    function hasValueBrand(val){
        if (val?.length === 0 || val == null) {
            return ""
          } 
          else{
            return val
          }
    }

    const loadKBIRequests = async () => {
        setIsLoadingUi(true)
        let url = BASE_URL + INPUT_FORMS + EDGE_ECOMM + ADMIN + REQUESTS + LIST + `?selected_form=${selectedForm}`
        let urlBeyond = BASE_URL + INPUT_FORMS + EDGE_ECOMM + BEYOND_CORE_ASSORTMENT + ADMIN + REQUESTS + LIST + `?selected_form=${selectedForm}`
       
        let response = await new APIService().get(url);
        let responseBeyond = await new APIService().get(urlBeyond);
        
        const transformedData = responseBeyond.results.map(
            ({ kbi_title, brand_owner, ...rest }) => {
        
             let hasBrandOwner = hasValue(brand_owner)
             let nbrand_owner = hasValueBrand(brand_owner)
              return {
                kbi_title: `${kbi_title} ${hasBrandOwner} ${nbrand_owner}`,
                ...rest,
              };
            }
          );
      
          transformedData.map((val) => {
            delete val.brand_owner;
          });
      
        if (response.error) { 
            setIsLoadingUi(false)
            console.log("error in kbivalues API", response.results.message)
        } else {
            setIsLoadingUi(false)
            setupKBIRequests(response.results, transformedData);
           
        }
    }

    const handleDialogueBox = () => {
        setOpenDialogueBox(!openDialogueBox)
    }

    const handleRejectDialogueClose = () => {
        setOpenRejectDialogueBox(!openRejectDialogueBox);
    }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            loadKBIRequests();
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    // Event to get selected IDs
    const getSelectedRows = () => selectedList.map(({id}) => id);
    const getSelectedRowsBeyond = () => selectedListBeyond.map(({id}) => id);

    const constructError = (response, badRequestMessage = "Reason field is may not be blank!") => {
        setIsLoadingUi(false)
        if (response.status === 400) {
            if (response.results.message.includes("Reason")) {
                showDialogueBox("Error", badRequestMessage)
            } else {
                showDialogueBox("Error", response.results.message)
            }
        } else if (response.status === 404 || response.status === 403) {
            showDialogueBox("Error", response.results.message)
        }
    }

    const performSubmit = async (is_approved, reason = "") => {
        setIsLoadingUi(true)
        if(valuesSelected === "all"){
            handleAll(is_approved, reason)
        }else if(valuesSelected === "eretail"){
            handleEretail(is_approved, reason)
        }
        else if(valuesSelected === "beyond"){
            handleBeyond(is_approved, reason)
        }
        else{
            setIsLoadingUi(false)
        }   
    }

    async function handleAll(is_approved, reason) {
        const eretailIds = getSelectedRows();
        const beyondIds = getSelectedRowsBeyond();

        const url = BASE_URL + INPUT_FORMS + EDGE_ECOMM + ADMIN + REQUESTS + HANDLE
        let body = {
            "form_id": selectedForm,
            "admin_response": is_approved,
            "request_ids": eretailIds,
        }
        
        const urlBeyond = BASE_URL + INPUT_FORMS + EDGE_ECOMM + BEYOND_CORE_ASSORTMENT + ADMIN + REQUESTS + HANDLE
        let bodyBeyond = {
            "form_id": selectedForm,
            "admin_response": is_approved,
            "request_ids": beyondIds,
        }
        if (!is_approved) {
            body['reason'] = reason;
            bodyBeyond['reason'] = reason;
        }
        
        const response = await new APIService().post(url, body);
        const responseBeyond = await new APIService().post(urlBeyond, bodyBeyond);
        
        if (response.error) {
            constructError(response);
        } else if (responseBeyond.error) {
            constructError(responseBeyond);
        } else {
            setIsLoadingUi(false)
            if (response.results.request_result || responseBeyond.results.request_result) {
                showDialogueBox("Success", "Approved")
            } else {
                setOpenRejectDialogueBox(false);
                showDialogueBox("Success", "Rejected")
            }
            loadKBIRequests(); //Reload Request Data
        }
    }
    async function handleEretail(is_approved, reason){
        let url = BASE_URL + INPUT_FORMS + EDGE_ECOMM + ADMIN + REQUESTS + HANDLE
        let body = {
            "form_id": selectedForm,
            "admin_response": is_approved,
            "request_ids": getSelectedRows(),
        }
      
      
        if (!is_approved) {
            body['reason'] = reason
        }
        
        let response = await new APIService().post(url, body)
       
        if (response.error) {
            constructError(response);
        } else {
            setIsLoadingUi(false)
            if (response.results.request_result) {
                showDialogueBox("Success", "Approved")
            } else {
                setOpenRejectDialogueBox(false);
                showDialogueBox("Success", "Rejected")
            }
            loadKBIRequests(); //Reload Request Data
        }
    }

    async function handleBeyond(is_approved, reason) {
        const urlBeyond = BASE_URL + INPUT_FORMS + EDGE_ECOMM + BEYOND_CORE_ASSORTMENT + ADMIN + REQUESTS + HANDLE
        let bodyBeyond = {
            "form_id": selectedForm,
            "admin_response": is_approved,
            "request_ids": getSelectedRowsBeyond(),
        }
        if (!is_approved) {
            bodyBeyond['reason'] = reason
        }
      
        const responseBeyond = await new APIService().post(urlBeyond, bodyBeyond)
        if (responseBeyond.error) {
            constructError(responseBeyond);
        }
        else {
            setIsLoadingUi(false)
            if (responseBeyond.results.request_result) {
                showDialogueBox("Success", "Approved")
            } else {
                setOpenRejectDialogueBox(false);
                showDialogueBox("Success", "Rejected")
            }
            loadKBIRequests(); //Reload Request Data
        }
    }

    async function handleAllReject() {
        const url = BASE_URL + INPUT_FORMS + EDGE_ECOMM + ADMIN + REQUESTS + REJECT_CHECK
        let body = {
            "form_id": selectedForm,
            "request_ids": getSelectedRows()
        }
        let urlBeyond = BASE_URL + INPUT_FORMS + EDGE_ECOMM + BEYOND_CORE_ASSORTMENT + ADMIN + REQUESTS + REJECT_CHECK
        let bodyBeyond = {
            "form_id": selectedForm,
            "request_ids": getSelectedRowsBeyond(),
        }
        let response = await new APIService().post(url, body)
        let responseBeyond = await new APIService().post(urlBeyond, bodyBeyond)
        if (response.error) {
            constructError(response, response.results.message);
        } 
        if (responseBeyond.error) {
            constructError(responseBeyond, responseBeyond.results.message);
        }
        else {
            setIsLoadingUi(false)
            if (response.results.request_result || responseBeyond.results.request_result) {
                setOpenRejectDialogueBox(true);
            } else {
                showDialogueBox("Error", "You have to select single Country and Period combination for rejection.")
            }
        }
    }

    async function handleEretailReject() {
        let url = BASE_URL + INPUT_FORMS + EDGE_ECOMM + ADMIN + REQUESTS + REJECT_CHECK
        let body = {
            "form_id": selectedForm,
            "request_ids": getSelectedRows()
        }
       
        let response = await new APIService().post(url, body)
       
        if (response.error) {
            constructError(response, response.results.message);
        } 
        
        else {
            setIsLoadingUi(false)
            if (response.results.request_result) {
                setOpenRejectDialogueBox(true);
            } else {
                showDialogueBox("Error", "You have to select single Country and Period combination for rejection.")
            }
        }
    }

    async function handleBeyondReject() {
        
        let urlBeyond = BASE_URL + INPUT_FORMS + EDGE_ECOMM + BEYOND_CORE_ASSORTMENT + ADMIN + REQUESTS + REJECT_CHECK
        let bodyBeyond = {
            "form_id": selectedForm,
            "request_ids": getSelectedRowsBeyond(),
        }
        let responseBeyond = await new APIService().post(urlBeyond, bodyBeyond)
       
        if (responseBeyond.error) {
            constructError(responseBeyond, responseBeyond.results.message);
        }
        else {
            setIsLoadingUi(false)
            if (responseBeyond.results.request_result) {
                setOpenRejectDialogueBox(true);
            } else {
                showDialogueBox("Error", "You have to select single Country and Period combination for rejection.")
            }
        }
    }
    
    const performRejectProcess = async () => {
        setIsLoadingUi(true)
        if(valuesSelected === "all"){
            handleAllReject()
        }else if(valuesSelected === "eretail"){
            handleEretailReject()
        }
        else if(valuesSelected === "beyond"){
            handleBeyondReject()
        }  else{
            setIsLoadingUi(false)
        }

    }

    const performApprovalProcess = async () => {
        performSubmit(true);
    }

    return (
        <Spin tip="Loading" spinning={isLoadingUi} size="large">
        <div className="admin-container m-5">
            {/* Loader(While Data Loading) */}
            {loading ? <div className="admin-loading-text container mt-4 p-2"><Spinner className="admin-request-spinner" animation="border" />
                    <p></p>
                </div> : ""}
            {/* Loader(While Data Loading) */}
            {/* Table(If Data Present) */}
            {kbiRequests.length > 0 ?
                (<div className="container table-container row mt-4 p-2">
                    <div className="col-md-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <Checkbox id="mastercheck" checked={masterChecked} onChange={(e) => onMasterCheck(e)}
                                            {...label}
                                            // defaultChecked
                                            size="small"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 5 },
                                                color: red[500],
                                                '&.Mui-checked': {
                                                    color: red[500],
                                                },
                                            }} />
                                    </th>
                                    <th scope="col">Country</th>
                                    <th scope="col">Period</th>
                                    <th scope="col">KBI Title</th>
                                    <th scope="col">KBI Value</th>
                                    <th scope="col">Previous KBI Value</th>
                                    <th scope="col">Comment</th>
                                    <th scope="col">Requested By</th>
                                    <th scope="col">Requested On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {kbiRequests.map((kbi) => (
                                    <tr key={kbi.id} className={kbi.selected ? "selected" : ""}>
                                        <th scope="row">
                                            {/* <input
                                            type="checkbox"
                                            checked={user.selected}
                                            className="form-check-input"
                                            id="rowcheck{user.id}"
                                            onChange={(e) => onItemCheck(e, user)}
                                        /> */}
                                            <Checkbox id="rowcheck{kbi.id}" checked={kbi.selected} onChange={(e) => onItemCheck(e, kbi)}
                                                {...label}
                                                // defaultChecked
                                                size="small"
                                                sx={{
                                                    '& .MuiSvgIcon-root': { fontSize: 5 },
                                                    color: red[500],
                                                    '&.Mui-checked': {
                                                        color: red[500],
                                                    },
                                                }} />
                                        </th>
                                        <td title={kbi.country_title}>{kbi.country_title}</td>
                                        <td title={kbi.date}>{kbi.date}</td>
                                        <td title={kbi.kbi_title}>{kbi.kbi_title}</td>
                                        <td title={kbi.kbi_value}>{kbi.kbi_value}</td>
                                        <td title={kbi.prev_kbi_value}>{kbi.prev_kbi_value >= 0 ? kbi.prev_kbi_value : "-"}</td>
                                        <td title={kbi.comment}>{kbi.comment}</td>
                                        <td title={kbi.added_by}>{kbi.added_by}</td>
                                        <td title={kbi.added_at}>{kbi.added_at}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>) : ""}
                {/* Table(If Data Present) */}
                {/* No Data Message */}
                {kbiRequests.length === 0 && loading === false ? (<div className="admin-loading-text container mt-4 p-2"><p>No data found.</p>
                    <p></p>
                </div>) : ""}
            <div className="col-12">
                <div className="admin-button-container">
                    <span className="p-3">
                        <input className="admin-button-reject" type="button"
                            value="Reject" onClick={() => performRejectProcess()}
                            disabled={selectedCombinedList.length <= 0} />
                    </span>
                    <span className="p-3">
                        <input className="admin-button-approve" type="button"
                            value="Approve" onClick={() => performApprovalProcess()}
                            disabled={selectedCombinedList.length <= 0} />
                    </span>
                </div>
            </div>
            {/* Message Dialogue Box */}
            {openDialogueBox ? <DialogueBox openDialogueBox={openDialogueBox}
                handleDialogueBox={handleDialogueBox}
                messageType={messageType}
                message={message} /> : ""}
            {/* Message Dialogue Box */}
            {/* Reject Dialogue Box */}
            {openRejectDialogueBox ? <RejectDialogueBox openRejectDialogueBox={openRejectDialogueBox}
                handleRejectDialogueClose={handleRejectDialogueClose}
                performSubmit={performSubmit} /> : ""}
            {/* Reject Dialogue Box */}
        </div>
  </Spin>
    );

};

export default Admin1;