
export const MONTHS = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];

export const BEYOND_CORE_COLUMNS = [
    {
        dataIndex: 'country_name',
        title: 'Country',
        filterSearch: true,
        onFilter: (value:string, record:any) => record.country_name.startsWith(value as string),
        sorter: (a:any, b:any) => a.country_name.localeCompare(b.country_name)
    },
    {
        dataIndex: 'year',
        title: 'Year',
        filterSearch: true,
        onFilter: (value:Number, record:any) => record.year === value,
        sorter: (a:any, b:any) => a.year - b.year
    },
    {
        dataIndex: 'period',
        title: 'Month',
        filterSearch: true,
        onFilter: (value:string, record:any) => record.period.startsWith(value as string),
        filters: MONTHS.map((m) => ({
            text: m,
            value: m
        }))
    },
    {
        dataIndex: 'kbi',
        title: 'KBI',
        sorter: (a:any, b:any) => a.kbi.localeCompare(b.kbi)
    },
    {
        dataIndex: 'category',
        title: 'Category',
        sorter: (a:any, b:any) => a.category.localeCompare(b.category)
    },
    {
        dataIndex: 'brand_owners',
        title: 'Brand owners',
        filterSearch: true,
        onFilter: (value:string, record:any) => record.brand_owners.startsWith(value as string),
        sorter: (a:any, b:any) => a.brand_owners.localeCompare(b.brand_owners)
    },
    {
        dataIndex: 'value',
        title: 'Value',
        sorter: (a:any, b:any) => a.value - b.value
    },
]

export const NSR_COLUMNS = [
    {
        dataIndex: 'country',
        title: 'Country',
        filterSearch: true,
        onFilter: (value:string, record:any) => record.country.startsWith(value as string),
        sorter: (a:any, b:any) => a.country.localeCompare(b.country)
    },
    {
        dataIndex: 'year',
        title: 'Year',
        filterSearch: true,
        onFilter: (value:Number, record:any) => record.year === value,
        sorter: (a:any, b:any) => a.year - b.year
    },
    {
        dataIndex: 'period',
        title: 'Month',
        filterSearch: true,
        onFilter: (value:string, record:any) => record.period.startsWith(value as string),
        filters: MONTHS.map((m) => ({
            text: m,
            value: m
        }))
    },
    {
        dataIndex: 'kbi_name',
        title: 'KBI Name',
        filterSearch: true,
        sorter: (a:any, b:any) => a.kbi_name.localeCompare(b.kbi_name),
        onFilter: (value:string, record:any) => record.kbi_name.startsWith(value as string),
    },
    {
        dataIndex: 'kbi',
        title: 'KBI',
        sorter: (a:any, b:any) => a.kbi.localeCompare(b.kbi)
    },
    {
        dataIndex: 'value',
        title: 'Value',
        sorter: (a:any, b:any) => a.value - b.value
    },
];

export const VOLUME_COLUMNS = [
    {
        dataIndex: 'country',
        title: 'Country',
        filterSearch: true,
        onFilter: (value:string, record:any) => record.country.startsWith(value as string),
        sorter: (a:any, b:any) => a.country.localeCompare(b.country)
    },
    {
        dataIndex: 'year',
        title: 'Year',
        filterSearch: true,
        onFilter: (value:Number, record:any) => record.year === value,
        sorter: (a:any, b:any) => a.year - b.year
    },
    {
        dataIndex: 'period',
        title: 'Month',
        filterSearch: true,
        onFilter: (value:string, record:any) => record.period.startsWith(value as string),
        filters: MONTHS.map((m) => ({
            text: m,
            value: m
        }))
    },
    {
        dataIndex: 'kbi_name',
        title: 'KBI Name',
        filterSearch: true,
        sorter: (a:any, b:any) => a.kbi_name.localeCompare(b.kbi_name),
        onFilter: (value:string, record:any) => record.kbi_name.startsWith(value as string),
    },
    {
        dataIndex: 'kbi',
        title: 'KBI',
        sorter: (a:any, b:any) => a.kbi.localeCompare(b.kbi)
    },
    {
        dataIndex: 'value',
        title: 'Value',
        sorter: (a:any, b:any) => a.value - b.value
    },
];