import { Card, Col, Row, Spin } from "antd";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../../../../actions/eComm/eCommRetailFSAActions";
import * as selectors from "../../../../reducer/ECommRetail/selectors";
import { IBeyondCoreMasterList, IBusinessPlan, IKBIBrandCategory, KBIInputState } from "../../../../reducer/ECommRetail/_interfaces";

type IProps = {
    inputDisabled:boolean;
    prevPeriodTitle: string;
}

const BeyondCoreCard:FC<IProps> = ({inputDisabled, prevPeriodTitle}) => {
    const dispatch = useDispatch();
    const beyondCoreLoading:boolean = useSelector(selectors.beyondCoreLoading);
    const categoryBrandMasterList:IBeyondCoreMasterList = useSelector(selectors.categoryBrandMasterList);
    const beyondCoreKBIInputs:KBIInputState = useSelector(selectors.beyondCoreKBIInputs);
    const beyondCoreKBIInputsPrevPeriod:KBIInputState = useSelector(selectors.beyondCoreKBIInputsPrevPeriod);
    const businessPlan:IBusinessPlan = useSelector(selectors.businessPlan);


    //Dynamic Input onChange Handler
    const inputOnChangeCategoryBrand = (e:React.ChangeEvent<HTMLInputElement>) => {
        const re = /^[0-9]*\.?[0-9]*$/;
        const value = e.target.value;
        const name = e.target.name;

        if (value === "" || re.test(value)) {
            dispatch(actions.setBeyondCoreKBIInputs({ ...beyondCoreKBIInputs, [name]: value } as any))
        }
    };
    
    const getRelDiff = (num_one:number, num_two:number) => {
        return 100 * Math.abs((num_one - num_two) / ((num_one + num_two) / 2))
    }

    const getChange = (num_one:number, num_two:number) => {
        return (num_one - num_two) / num_two * 100
    }
    return (
        <Col span={12}>
            <Spin tip="Loading" spinning={beyondCoreLoading} size="large">
                {categoryBrandMasterList &&
                    Object.keys(categoryBrandMasterList).map((key:any) => {
                        return (
                            <Card key={key} style={{ marginBottom: 15 }}>
                                
                                <Row style={{ height: 60 }}>
                                    <Col span={17} />
                                    <Col span={4}>
                                        <p className="user-view-kbi-unit">{`vs. ${prevPeriodTitle}`}</p>
                                    </Col>
                                    <Col span={3}>
                                        <p className="user-view-kbi-unit">{`vs. BP`}</p>
                                    </Col>
                                </Row>
                                {categoryBrandMasterList[key].map((kbi:IKBIBrandCategory) => {
                                    const current = beyondCoreKBIInputs[kbi.kbi_id];
                                    const prev = beyondCoreKBIInputsPrevPeriod[kbi.kbi_id];
                                    const bpValue = businessPlan?.[kbi.kbi_id]?.value;

                                    return (
                                        <Row key={kbi.kbi_id} style={{ height: 60 }}>
                                            <Col span={kbi.brand_owners === "" || kbi.brand_owners == null ? 5 : 9}>
                                                <p className="user-view-input-label-text">
                                                    {kbi.brand_owners === "" || kbi.brand_owners == null
                                                        ? kbi.category
                                                        : kbi.brand_owners}
                                                </p>
                                            </Col>
                                            <Col span={kbi.brand_owners === "" || kbi.brand_owners == null ? 9 : 5}>
                                                <input
                                                    className="text-input-box"
                                                    name={kbi.kbi_id}
                                                    value={
                                                        current > -1
                                                            ? current
                                                            : ''
                                                    }
                                                    onChange={inputOnChangeCategoryBrand}
                                                    disabled={inputDisabled}
                                                    type="text"
                                                    // step="any"
                                                    // min="0"
                                                />
                                            </Col>
                                            <Col span={3}>
                                                <p className="user-view-kbi-unit">
                                                    {kbi.unit}
                                                </p>
                                            </Col>

                                            <Col span={4}>
                                                <p className="user-view-kbi-unit">
                                                    {
                                                        !isNaN(getChange(current, prev))
                                                            ? `${getChange(current, prev).toFixed(2)}%`
                                                            : 'N/A'
                                                    }
                                                </p>
                                            </Col>
                                            <Col span={3}>
                                                <p className="user-view-kbi-unit">{
                                                        !isNaN(getChange(current, bpValue))
                                                            ? `${getChange(current, bpValue).toFixed(2)}%`
                                                            : 'N/A'
                                                }</p>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Card>
                        );
                    })
                }
            </Spin>
        </Col>
    );
}

export default BeyondCoreCard;