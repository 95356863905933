import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addUpdatingDropdown } from "../../actions/steward_action";
import toast, { Toaster } from "react-hot-toast";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Select from "react-select";
import {
  month as checkMonth,
  year as checkYear,
  dates,
  intVal,
  floatVal,
  textVal,
} from "../Emission/EmissionColumns";

import { month, year, sweetnerList as Sweetener } from "./StewardColumn";
import { quarter } from "../Emission/EmissionColumns";
import { getAddDetails } from "../../actions/steward_action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "66vw",
  // height: '434px',
  minHeight: "50vh",
  bgcolor: "background.paper",
  border: "2px solid FFFFFF",
  boxShadow: 24,
  borderRadius: "8px",
  p: "1.5vh 3vh",
  overflow: "auto",
  // display: 'flex'
};
export default function AddModal(props) {
  const dispatch = useDispatch();
  const disabledField = [
    "% Sustainable Sourced",
    "% Primary Packaging Collected for recycling or reuse",
    "% Consumer packaging recyclable",
  ];
  const country = useSelector((state) => state.steward.addCountryList);
  const region = useSelector((state) => state.steward.addRegionList);
  const bu = useSelector((state) => state.steward.addBUList);
  const plant = useSelector((state) => state.steward.addPlantList);
  const matrix = useSelector((state) => state.steward.addMatrix);
  const yearList = useSelector((state) => state.steward.yearList);
  const tableData = useSelector((state) => state.steward.tableData);
const [newRowData, setNewRowData] = useState()
  const [rowData, setRowData] = useState(props.rowSelected);
  const [addCountry, setAddCountry] = useState("");
  const [addRegion, setAddRegion] = useState("");
  const [addBU, setAddBU] = useState("");
  const [addPlant, setAddPlant] = useState("");
  const [addType, setAddType] = useState();

  const handleDialogueBox = () => {
    props.handleAddDialogueBox();
  };

  const handleOnSubmit = () => {
       const keysToRemove = [
    "added_by",
    "updated_by",
    "created_date",
    "updated_date",
    "cut_off",
  ];
       let newRowDataUpdated = { ...rowData };
    keysToRemove.forEach(key => delete newRowDataUpdated[key]);
   
    for (let char in newRowDataUpdated) {
      if (rowData[char].length === 0 && char !== "id") {
        toast.error("Value cannot be empty of " + char.toUpperCase(), {
          position: "top-center",
          className: "toast-popup",
          duration: 1500,
        });
        return;
      }
    }
    for (let char in rowData) {
      if (dates.includes(char)) {
        if (
          rowData[char].includes("/") &&
          rowData[char].length === 8 &&
          checkMonth.includes(rowData[char].split("/")[0].toLowerCase()) &&
          checkYear.includes(parseInt(rowData[char].split("/")[1]))
        ) {
          continue;
        } else {
          toast.error("Please enter correct format of " + char, {
            position: "top-center",
            className: "toast-popup",
            duration: 1500,
          });
          return;
        }
      // } else if (intVal.includes(char)) {
      //   if (
      //     /^([0-9])*$/.test(rowData[char].toString().replace(/,/g, "")) === true
      //   ) {
      //     rowData[char] = parseFloat(rowData[char]).toLocaleString();
      //     continue;
      //   } else {
      //     toast.error("Please enter correct format of " + char, {
      //       position: "top-center",
      //       className: "toast-popup",
      //       duration: 1500,
      //     });
      //     return;
      //   }
       } 
        else if (floatVal.includes(char)) {
          if (
            /^([0-9]*[.]{1}[0-9]*)$/.test(
              rowData[char].toString().replace(/,/g, "")
              ) === true ||
              /^([0-9])*$/.test(rowData[char].toString().replace(/,/g, "")) === true
              ) {
                rowData[char] = parseFloat(rowData[char]).toLocaleString('en-US');
          continue;
        } else {
          toast.error("Please enter correct format of " + char, {
            position: "top-center",
            className: "toast-popup",
            duration: 1500, 
          });
          return;
        }
      }
    }
    let obj = rowData;
    if (props.type === "water" || props.type === "zwc") {
      let startMonth = checkMonth.indexOf(
        rowData["Start Date"].substr(0, 3).toLowerCase()
      );
      let endMonth = checkMonth.indexOf(
        rowData["End Date"].substr(0, 3).toLowerCase()
      );
      let startYear = rowData["Start Date"].substr(4);
      let endYear = rowData["End Date"].substr(4);
      if (
        endYear < startYear ||
        (startYear == endYear && endMonth < startMonth)
      ) {
        toast.error("Start Date cannot be greater than End Date ", {
          position: "top-center",
          className: "toast-popup",
          duration: 1000,
        });
        return;
      }
      obj = {
        ...rowData,
        month:
          checkMonth.indexOf(rowData["Start Date"].substr(0, 3).toLowerCase()) +
          1,
        year: parseInt(rowData["Start Date"].substr(4)),
      };
    } else if (props.type === "part_org") {
      for (let char of yearList) {
        if (obj.year === char.value) {
          // toast.error(`Data for ${obj.year} already present.`, { position: 'top-center', className: 'toast-popup', duration: 1000, });
          // return
        }
      }
    } else if (props.type === "sourcing") {
      for (let char of tableData) {
        if (
          obj["year"] == char["year"] &&
          obj["quarter"] == char["quarter"] &&
          obj["Ingredient"] == char["Ingredient"]
        ) {
          toast.error(
            `Data for selected year, quarter and sweetner already present.`,
            { position: "top-center", className: "toast-popup", duration: 1000 }
          );
          return;
        }
      }
      if (rowData["% Sustainable Sourced"] > 100) {
        toast.error("% Sustainable Sourced cannot be more than 100 % ", {
          position: "top-center",
          className: "toast-popup",
          duration: 1500,
        });
        return;
      }
    }
    if (props.type === "waste") {
      if (
        rowData["% Primary Packaging Collected for recycling or reuse"] > 100
      ) {
        toast.error(
          "% Primary Packaging Collected for recycling or reuse cannot be more than 100 % ",
          { position: "top-center", className: "toast-popup", duration: 1500 }
        );
        return;
      }
    }
    obj = {
      ...obj,
      id: props.rowSelected.id,
    };
    props.handleAdd(obj);
  };

  const handleOnCancel = () => {
    props.handleAddDialogueBox();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "Sustainable Volume (tonnes)") {
      setRowData({
        ...rowData,
        [name]: value,
        "% Sustainable Sourced": (
          (value / rowData["Total Volume (tonnes)"]) *
          100
        ).toFixed(2),
      });
    } else if (name === "Total Volume (tonnes)") {
      setRowData({
        ...rowData,
        [name]: value,
        "% Sustainable Sourced": (
          (rowData["Sustainable Volume (tonnes)"] / value) *
          100
        ).toFixed(2),
      });
    } else if (name === "Total Recovered for Recycling in Units") {
      setRowData({
        ...rowData,
        [name]: value,
        "% Consumer packaging recyclable": 100,
        "% Primary Packaging Collected for recycling or reuse": (
          (value / rowData["Total Placed in Markets in Units"]) *
          100
        ).toFixed(2),
      });
    } else if (name === "Total Placed in Markets in Units") {
      setRowData({
        ...rowData,
        [name]: value,
        "% Consumer packaging recyclable": 100,
        "% Primary Packaging Collected for recycling or reuse": (
          (rowData["Total Recovered for Recycling in Units"] / value) *
          100
        ).toFixed(2),
      });
    } else if (name === "Start Date" || name === "End Date") {
      setRowData({
        ...rowData,
        [name]: value.charAt(0).toUpperCase() + value.slice(1),
      });
    } else {
      setRowData({
        ...rowData,
        [name]: value,
      });
    }
  };
  const handleSelectChange = (e, char) => {
    if (char === "region") {
      setAddType("Region");
      setAddRegion(e.label);
    } else if (char === "country") {
      setAddType("Country");
      setAddCountry(e.label);
    } else if (char === "bu") {
      setAddType("BU");
      setAddBU(e.label);
    } else if (char === "plant") {
      setAddType("Plant");
      setAddPlant(e.label);
    }
    setRowData({
      ...rowData,
      [char]:
        char === "month"
          ? checkMonth.indexOf(e.value.toLowerCase()) + 1
          : e.label,
    });
  };
  useEffect(() => {
    dispatch(getAddDetails());
  }, []);

  useEffect(() => {
    var selected_country = addCountry;
    // const country_check = ["Estonia", "Lithuania", "Latvia"];
    // if (selected_country){
    //     if (country_check.some(a => selected_country == selected_country)) selected_country = "Baltics"
    // }

    if (addType === "Region") {
      dispatch(addUpdatingDropdown(addType, addRegion, matrix));
      if (addPlant !== "") {
        dispatch(addUpdatingDropdown("Plant", addPlant, matrix));
      }
      if (addCountry !== "") {
        dispatch(addUpdatingDropdown("Country", selected_country, matrix));
      }
      if (addBU !== "") {
        dispatch(addUpdatingDropdown("BU", addBU, matrix));
      }
    }
    if (addType === "BU") {
      dispatch(addUpdatingDropdown(addType, addBU, matrix));
      if (addPlant !== "") {
        dispatch(addUpdatingDropdown("Plant", addPlant, matrix));
      }
      if (addCountry !== "") {
        dispatch(addUpdatingDropdown("Country", selected_country, matrix));
      }
      // if (addRegion !== '') {
      //     dispatch(addUpdatingDropdown('Region', addRegion, matrix))
      // }
    }
    if (addType === "Country") {
      dispatch(addUpdatingDropdown(addType, selected_country, matrix));
      if (addPlant !== "") {
        dispatch(addUpdatingDropdown("Plant", addPlant, matrix));
      }
      // if (addBU !== '') {
      //     dispatch(addUpdatingDropdown('BU', addBU, matrix))
      // }
      // if (addRegion !== '') {
      //     dispatch(addUpdatingDropdown('Region', addRegion, matrix))
      // }
    }
    if (addType === "Plant") {
      dispatch(addUpdatingDropdown(addType, addPlant, matrix));
      // if (addCountry !== '') {
      //     dispatch(addUpdatingDropdown('Country', addCountry, matrix))
      // }
      // if (addBU !== '') {
      //     dispatch(addUpdatingDropdown('BU', addBU, matrix))
      // }
      // if (addRegion !== '') {
      //     dispatch(addUpdatingDropdown('Region', addRegion, matrix))
      // }
    }
  }, [addRegion, addBU, addCountry, addPlant]);

  useEffect(() => {
    let rowsAdded = { ...props.rowSelected };
    for (let val in rowsAdded) {
      rowsAdded[val] = "";
    }
    setRowData(rowsAdded);
  }, []);

  useEffect(() => {
    const keysToRemove = [
      "added_by",
      "updated_by",
      "created_date",
      "updated_date",
      "cut_off",
    ];
   
    let newRowDataUpdated = { ...rowData };
    keysToRemove.forEach(key => delete newRowDataUpdated[key]);
    setNewRowData(newRowDataUpdated);
  }, []);

  return (
    <div>
      <Modal
        open={props.openDialogueBox}
        onClose={handleDialogueBox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="brand-category-modal-form-header">
            <p>Add Details</p>
            <div
              className="brand-category-modal-form-close-button"
              onClick={handleOnCancel}
            >
              X
            </div>
          </div>
          <br />
          <div style={{ display: "flex", flexFlow: "wrap" }}>
            {newRowData && Object.keys(newRowData).map((char) => {
              if (
                !props.disabledOption.includes(char) &&
                char !== "id" &&
                char !== "selected"
              ) {
                if (intVal.includes(char) || floatVal.includes(char)) {
                  return (
                    <div style={{ marginBottom: "2rem" }} key={char}>
                      <div style={{ fontSize: ".75rem" }}>
                        {char.toUpperCase()}
                        <span style={{ color: "#E61C2A" }}> *</span>{" "}
                      </div>
                      <input
                        value={rowData[char]}
                        name={char}
                        type="number"
                        autoComplete="off"
                        disabled={disabledField.includes(char)}
                        style={{
                          width: props.type !== "waste" ? "15rem" : "25rem",
                          height: "2.25rem",
                          margin: ".4rem 2rem .4rem 0rem",
                        }}
                        onChange={handleChange}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div style={{ marginBottom: "2rem" }} key={char}>
                      <div style={{ fontSize: ".75rem" }}>
                        {char.toUpperCase()}
                        <span style={{ color: "#E61C2A" }}> *</span>{" "}
                      </div>
                      <input
                        value={rowData[char]}
                        name={char}
                        autoComplete="off"
                        placeholder={
                          dates.includes(char) === true ? "mon/yyyy" : ""
                        }
                        disabled={disabledField.includes(char)}
                        style={{
                          width: props.type !== "waste" ? "15rem" : "25rem",
                          height: "2.25rem",
                          margin: ".4rem 2rem .4rem 0rem",
                        }}
                        onChange={handleChange}
                      />
                    </div>
                  );
                }
              }
              if (char !== "id" && char !== "selected") {
                return (
                  <div key={char} style={{ marginBottom: "2rem" }}>
                    <div style={{ fontSize: ".75rem" }}>
                      {char.toUpperCase()}
                      <span style={{ color: "#E61C2A" }}> *</span>{" "}
                    </div>
                    <div
                      style={{
                        width: props.type !== "waste" ? "15rem" : "25rem",
                        margin: ".4rem 2rem .4rem 0rem",
                      }}
                    >
                      <Select
                        maxMenuHeight={150}
                        defaultValue={""}
                        onChange={(e, char) => handleSelectChange(e, char.name)}
                        options={
                          char === "country"
                            ? country
                            : char === "bu"
                            ? bu
                            : char === "region"
                            ? region
                            : char === "plant"
                            ? plant
                            : char === "year"
                            ? year
                            : char === "quarter"
                            ? quarter
                            : char === "month"
                            ? month
                            : Sweetener
                        }
                        name={char}
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="brand-category-modal-form-footer row m-0">
            <div className="brand-category-modal-form-input col">
              <div style={{ float: "right" }}>
                <button
                  className="brand-category-form-modal-cancel-button mt-3"
                  onClick={handleOnCancel}
                >
                  Cancel
                </button>
                <button
                  className="brand-category-form-modal-submit-button mt-3"
                  onClick={handleOnSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Toaster />
    </div>
  );
}
