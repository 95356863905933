import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Autocomplete, TextField } from '@mui/material';

const SKUSingleSelectAutocomplete = (props) => {

    const [selectedValue, setSelectedValue] = React.useState("")
    const [selectedLabel, setSelectedLabel] = React.useState("")

    const handleOnChange = (e, option)=>{
            if (option){
          props.setParentDropdownValue(option.id, props.title)
          setSelectedValue(option.id)
        //   console.log(option.id)
        }
          else{
              props.setParentDropdownValue("", props.title)
              setSelectedValue("")
          }
        }

    //useEffect Method
    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (props.preSelectedValue !== undefined) {
                // props.setParentDropdownValue(props.preSelectedValue, props.title)
                setSelectedValue((props.preSelectedValue));
            }
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    return (
        <>
            {/* <label style={{ paddingLeft: "5px", fontSize: "small" }}>{props.title}</label> */}
            <Autocomplete
  disablePortal
  required
  id="combo-box-demo"
  options={props.options}
  getOptionLabel={(option) => option.reference_title ?? option.title}
  onChange={handleOnChange}
  renderInput={(params) => <TextField className="w-75 my-2"  {...params} size={"small"} placeholder={props.title} />}
/>
        </>
    );
};

export default SKUSingleSelectAutocomplete;