export const message = (state:any) => state.eCommRetail.message;

export const countries = (state:any) => state.eCommRetail.countries;
export const countriesLoading = (state:any) => state.eCommRetail.countriesLoading;

export const periods = (state:any) => state.eCommRetail.periods;
export const periodsLoading = (state:any) => state.eCommRetail.periodsLoading;

export const kbiMaster = (state:any) => state.eCommRetail.kbiMaster;
export const kbiMasterLoading = (state:any) => state.eCommRetail.kbiMasterLoading;
export const kbiMasterUnedited = (state:any) => state.eCommRetail.kbiMasterUnedited;

export const beyondRawTable = (state:any) => state.eCommRetail.beyondRawTable;
export const beyondRawTableLoading = (state:any) => state.eCommRetail.beyondRawTableLoading;

export const categoryBrandMasterList = (state:any) => state.eCommRetail.categoryBrandMasterList;
export const beyondCoreLoading = (state:any) => state.eCommRetail.beyondCoreLoading;
export const beyondCoreKBIInputs = (state:any) => state.eCommRetail.beyondCoreKBIInputs;
export const beyondCoreKBIInputsPrevPeriod = (state:any) => state.eCommRetail.beyondCoreKBIInputsPrevPeriod;

export const comment = (state:any) => state.eCommRetail.comment;
export const lastEditedBy = (state:any) => state.eCommRetail.lastUpdate;
export const kbiValuesLoading = (state:any) => state.eCommRetail.kbiValuesLoading;
export const kbiValues = (state:any) => state.eCommRetail.kbiValues;
export const kbiValuesPrevPeriod = (state:any) => state.eCommRetail.kbiValuesPrevPeriod;

export const beyondCoreKBIs = (state:any) => state.eCommRetail.beyondCoreKBIs;
export const beyondCoreKBIsLoading = (state:any) => state.eCommRetail.beyondCoreKBIsLoading;

export const businessPlan = (state:any) => state.eCommRetail.businessPlan;
export const uploading = (state:any) => state.eCommRetail.uploading;