import { IState } from "./_interfaces";
import { SET_COUNTRIES, SET_COUNTRIES_LOADING, SET_DATA, SET_DATA_FOR_APPROVAL, SET_DATA_LOADING, SET_ERROR, SET_SUCCESS, SET_UPDATED_ROWS } from "./constants";

const initialState:IState = {
    countriesLoading: false,
    countries: [],

    dataLoading: false,
    data: [],

    updatedRows: [],
    
	error: '',
	success: '',

    dataForApproval: {
        created: [],
        updated: [],
        originals: [],
    }
}


const ECommCustomersReducer = (state = initialState, action:any) => {
    const { type, payload } = action;

    switch (type) {
        case SET_COUNTRIES_LOADING:
            return {
                ...state,
                countriesLoading: payload
            }
        case SET_COUNTRIES:
            return {
                ...state,
                countries: payload,
            }
        case SET_DATA:
            return {
                ...state,
                data: payload
            }
        case SET_DATA_LOADING:
            return {
                ...state,
                dataLoading: payload,
            }
        case SET_UPDATED_ROWS:
            return {
                ...state,
                updatedRows: payload.newUpdatedRows,
                ...(payload.data ? {data: payload.data} : {}),
            }

        case SET_ERROR:
            return {
                ...state,
                error: payload,
            }

        case SET_SUCCESS:
            return {
                ...state,
                success: payload,
            }

        case SET_DATA_FOR_APPROVAL:
            return {
                ...state,
                dataForApproval: payload
            }
        default:
            return state;
    }
}

export default ECommCustomersReducer