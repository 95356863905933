import { Card, Col, Row, Spin } from "antd";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../../../../actions/eComm/eCommRetailFSAActions";
import * as selectors from "../../../../reducer/ECommRetail/selectors";
import { IBeyondCoreMasterList, IBusinessPlan, IKBIBrandCategory, KBIInputState } from "../../../../reducer/ECommRetail/_interfaces";

type IProps = {
    inputDisabled:boolean;
    prevPeriodTitle: string;
    year:number;
    month: number;
}

const BeyondCoreCard:FC<IProps> = ({inputDisabled, prevPeriodTitle, year, month}) => {
    const dispatch = useDispatch();
    const beyondCoreLoading:boolean = useSelector(selectors.beyondCoreLoading);
    const categoryBrandMasterList:IBeyondCoreMasterList = useSelector(selectors.categoryBrandMasterList);
    const beyondCoreKBIInputs:KBIInputState = useSelector(selectors.beyondCoreKBIInputs);
    const beyondCoreKBIInputsPrevPeriod:KBIInputState = useSelector(selectors.beyondCoreKBIInputsPrevPeriod);
    const businessPlan:IBusinessPlan = useSelector(selectors.businessPlan);


    //Dynamic Input onChange Handler
    const inputOnChangeCategoryBrand = (e:React.ChangeEvent<HTMLInputElement>, catIndex:number) => {
        const re = /^[0-9]*\.?[0-9]*$/;
        const value = e.target.value;
        const name = e.target.name;

        if (value === "" || re.test(value)) {
            const categoryBrand = categoryBrandMasterList[catIndex];
            const targetBrand = categoryBrand.find(({kbi_id}) => kbi_id === name);

            let saveObject:{[x:string]: string | number} = {
                [name]: value,
            }
            if (targetBrand?.brand_owners) {
                const type = targetBrand.brand_owners.split(' ')[targetBrand.brand_owners.split(' ').length - 1];
                const targetCategoryName = targetBrand.category + ' ' + type;
                const targetCategory = categoryBrand.find(({category}) => category === targetCategoryName);

                const targetKBICategory = targetCategory?.kbi_id;
                if (targetKBICategory) {
                    const valuesForCategory = categoryBrand
                        .filter((cat) =>
                            cat.brand_owners
                            && cat.brand_owners.split(' ')[cat.brand_owners.split(' ').length - 1] === type
                            && cat.kbi_id !== name
                        )
                        .reduce((a, e) => a + (Number(beyondCoreKBIInputs?.[e.kbi_id]) || 0), 0)
    
                    saveObject = {
                        ...saveObject,
                        [targetKBICategory]: valuesForCategory + (value === "" ? 0 : Number(value))
                    }
                }
            }
            dispatch(actions.setBeyondCoreKBIInputs({ ...beyondCoreKBIInputs, ...saveObject } as any))
        }
    };
    
    const getRelDiff = (num_one:number, num_two:number) => {
        return 100 * Math.abs((num_one - num_two) / ((num_one + num_two) / 2))
    }

    const getChange = (num_one:number | null, num_two:number | null) => {
        if (num_one != null && num_two != null) {
            return (num_one - num_two) / num_two * 100
        }
        return NaN;
    }

    const sortKBIs = (a:IKBIBrandCategory, b:IKBIBrandCategory) => {
        if (a.brand_owners < b.brand_owners) {
            return -1;
        }
        if (a.brand_owners > b.brand_owners) {
            return 1;
        }
        return 0;
    }

    return (
        <Col span={12}>
            <Spin tip="Loading" spinning={beyondCoreLoading} size="large">
                {categoryBrandMasterList &&
                    Object.keys(categoryBrandMasterList).map((key:any) => {
                        return (
                            <Card key={key} style={{ marginBottom: 15 }}>
                                
                                <Row style={{ height: 60 }}>
                                    <Col span={17} />
                                    <Col span={4}>
                                        <p className="user-view-kbi-unit">{`vs. ${prevPeriodTitle}`}</p>
                                    </Col>
                                    <Col span={3}>
                                        <p className="user-view-kbi-unit">{`vs. BP`}</p>
                                    </Col>
                                </Row>
                                {categoryBrandMasterList[key].sort(sortKBIs).map((kbi:IKBIBrandCategory) => {
                                    const current = beyondCoreKBIInputs[kbi.kbi_id];
                                    const prev = beyondCoreKBIInputsPrevPeriod[kbi.kbi_id];
                                    const bpValue = businessPlan?.[kbi.kbi_id]?.value;

                                    const isCategory = kbi.brand_owners === "" || kbi.brand_owners == null;

                                    const isDisabled = inputDisabled || (isCategory && categoryBrandMasterList[key].length > 2)
                                    return (
                                        <Row key={kbi.kbi_id} style={{ height: 60 }}>
                                            <Col span={isCategory ? 5 : 9}>
                                                <p className="user-view-input-label-text">
                                                    {isCategory
                                                        ? kbi.category
                                                        : kbi.brand_owners}
                                                </p>
                                            </Col>
                                            <Col span={isCategory ? 9 : 5}>
                                                <input
                                                    className={`text-input-box ${isDisabled ? 'text-input-box-disabled' : ''}`}
                                                    name={kbi.kbi_id}
                                                    value={
                                                        current != null && current > -1
                                                            ? isDisabled && typeof current === "number"
                                                                ? Number(current)
                                                                : current
                                                            : ''
                                                    }
                                                    onChange={(e) => {
                                                        inputOnChangeCategoryBrand(e, key)
                                                    }}
                                                    disabled={isDisabled}
                                                    type="number"
                                                    // step="any"
                                                    // min="0"
                                                />
                                            </Col>
                                            <Col span={3}>
                                                <p className="user-view-kbi-unit">
                                                    {
                                                        isCategory
                                                            ? kbi.unit
                                                            : year >= 2025
                                                                ? kbi.unit
                                                                : '%'
                                                    }
                                                </p>
                                            </Col>

                                            <Col span={4}>
                                                <p className="user-view-kbi-unit">
                                                    {
                                                        (!isNaN(getChange(current, prev))
                                                            && isFinite(getChange(current, prev))) && (year !== 2025 || month !== 1)
                                                                ? `${getChange(current, prev).toFixed(2)}%`
                                                                : 'N/A'
                                                    }
                                                </p>
                                            </Col>
                                            <Col span={3}>
                                                <p className="user-view-kbi-unit">
                                                    {
                                                        (!isNaN(getChange(current, bpValue))
                                                            && isFinite(getChange(current, bpValue)))
                                                                ? `${getChange(current, bpValue).toFixed(2)}%`
                                                                : 'N/A'
                                                    }
                                                </p>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Card>
                        );
                    })
                }
            </Spin>
        </Col>
    );
}

export default BeyondCoreCard;