

export const SET_COUNTRIES_LOADING = 'SET_COUNTRIES_LOADING';
export const SET_COUNTRIES = 'SET_COUNTRIES';

export const SET_DATA_LOADING = 'SET_DATA_LOADING';
//Common
export const SET_ERROR = 'SET_ACTIVE_CUSTOMERS_ERROR';
export const SET_SUCCESS = 'SET_ACTIVE_CUSTOMERS_SUCCESS';


//List page
export const SET_DATA = 'SET_DATA';
export const SET_UPDATED_ROWS = 'SET_UPDATED_ROWS';

// Admin page
export const SET_DATA_FOR_APPROVAL = 'SET_DATA_FOR_APPROVAL';
