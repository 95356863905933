import { FC } from "react";
import { Spin, Table, Button, message } from "antd";
import { ColumnGroupType, ColumnType } from "antd/es/table";

type IProps = {
    data: any[];
    tableColumns:any;//(ColumnGroupType<any> | ColumnType<any>)[];
}

const PreviewTable:FC<IProps> = ({ data, tableColumns }) => {

	const components = {
		body: {
			row: EditableRow,
			// cell: EditableCell,
		},
	};

    return (
        <Table
            columns={tableColumns}
            size="small"
            dataSource={data}
            pagination={{
                pageSize: 100
            }}
            components={components}
            // rowClassName={() => 'editable-row'}
            bordered
            // scroll={{
            //     x: 2620,
            //     y: 570
            // }}
        />
    )
}
export default PreviewTable;

const EditableRow = ({ index, ...props }:any) => {
	return (
		<tr {...props} />
	);
};