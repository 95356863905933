import React, { useEffect } from "react";
import { Col, Card, Row, Typography } from "antd";

import "../../App.css";
import DropDownCustom from "../../components/dropDownCustom";
import DialogueBox from "../../components/dialogueBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Spin } from "antd";
import {
  ACCOUNTS,
  BASE_URL,
  COUNTRIES,
  DATES,
  EDGE_ECOMM,
  EDIT_CHECK,
  INPUT_FORMS,
  KBIS,
  USERS,
  CATEGORY_BRAND_MAPPING,
  BEYOND_CORE_ASSORTMENT,
  RAWDATA
} from "../../constant/apiEndpoints";
import APIService from "../../services/APIService";

const User1 = (props) => {
  const [isLoadingUi, setIsLoadingUi] = React.useState(false);
  const [numberOfBeyond, setNumberOfBeyond] = React.useState(0);
  const [isLoadingBeyond, setIsLoadingBeyond] = React.useState(false);
  const [isLoadingLeft, setIsLoadingLeft] = React.useState(false);
  const [countryList, setCountryList] = React.useState([]);
  const [dateList, setDateList] = React.useState([]);
  // const [selectedCountry, setSelectedCountry] = React.useState("")

  const [selectedForm, setSelectedForm] = React.useState(props.formId);
  const [kbiMasterList, setKbiMasterList] = React.useState([]);
  const [beyondRawData, setBeyondRawData] = React.useState([]);
  const [loadingCategoryBrand, setLoadingCategoryBrand] = React.useState(true);
  const [categoryBrandMasterList, setCategoryBrandMasterList] = React.useState(
    []
  );
  const [categoryBrandMasterListUnEdited, setCategoryBrandMasterListUnEdited] =
    React.useState([]);
  const [selectedCountryID, setSelectedCountryID] = React.useState("");
  const [selectedCountryTitle, setSelectedCountryTitle] = React.useState("");
  const [selectedPeriodID, setSelectedPeriodID] = React.useState("");
  const [selectedPeriodTitle, setSelectedPeriodTitle] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [inputDisabled, setInputDisabled] = React.useState(true);
  const [kbiInput, setKbiInput] = React.useState({});
  const [kbiInputUnEdited, setkbiInputUnEdited] = React.useState({});
  const [kbiInputCategoryBrand, setKbiInputCategoryBrand] = React.useState({});
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState("-");
  const [lastAddedBy, setLastAddedBy] = React.useState("-");
  const [openDialogueBox, setOpenDialogueBox] = React.useState(false);
  const [messageType, setMessageType] = React.useState("");
  const [message, setMessage] = React.useState("");

  //Dynamic Input onChange Handler
  const handleCommentOnChange = (e) => {
    setComment(e.target.value);
  };

  const formatKbiInput = () => {
    let kbis = kbiInput;

    let newKbis = [];
    const keys = Object.keys(kbis);

    keys.forEach((key, index) => {
      newKbis.push({
        kbi_id: key,
        kbi_value: kbis[key],
      });
    });

    return newKbis;
  };

  //Setup KBI Input Initial State
  const setupKbiInputState =async (kbis, kbiType, countryTitle="", periodTitle="") => {
    let kbiState = {};
    let comment = "";
    let lastSubmittedAt = "";
    let lastSubmittedBy = "";

    if (kbiType === "master") {
      for (var i = 0; i < kbis.length; i++) {
        kbiState[kbis[i].kbi_id] = "";
      }
      setIsLoadingLeft(false);
    } else if (kbiType === "values") {
      if (kbis.length !== 0) {
        for (let i = 0; i < kbiMasterList.length; i++) {
          let kbi_obj = kbis.find((x) => x.kbi === kbiMasterList[i].kbi_id);
          let kbi_value = 0;
          if (kbi_obj) {
            kbi_value = kbi_obj.kbi_value;
          }
          kbiState[kbiMasterList[i].kbi_id] = kbi_value;
        }
        lastSubmittedAt = kbis[0].added_at; //Setting Last Submitted At
        lastSubmittedBy = kbis[0].added_by; //Setting Last Added At
        comment = kbis[0].comment; //Setting Comment
        setIsLoadingLeft(false);
      }
    }

   
    setLastUpdatedAt(lastSubmittedAt); //Setting Last Updated At
    setLastAddedBy(lastSubmittedBy); //Setting Last Added By
    setKbiInput(kbiState); //Setting KBI Input
    setComment(comment); //Setting Comment
    setIsLoadingLeft(false);
  };

  const setupBrandCategoryKbiInputState = (kbis, kbiType, data, countryTitle, periodTitle) => {
    let kbiState = {};
    
    if (kbiType === "master") {
      for (var i = 0; i < kbis.length; i++) {
        kbiState[kbis[i].kbi_id] = "";
      }
    } else if (kbiType === "values") {
      if (kbis.length !== 0) {
        const parts = periodTitle.split("-");
        const month = parts[0];
        const year = parts[1];
        const kbiD = kbis.map(obj => ({ ...obj }));
        let kbi = []
        for (let i = 0; i < kbiD.length; i++) {
          const val = kbiD[i];
          if(val?.brand_owners == "" && val.category?.includes("NSR")){
            val.brand_owners = "NSR"
            val.category = val.category.replace("NSR", "").trim()
          }
          else if(val?.brand_owners == "" && val.category?.includes("Volume")){
            val.brand_owners = "Volume";
            val.category = val.category.replace("Volume", "").trim()
          }
          kbi.push(val)
        }

        let filterWithCountrymonthyear = beyondRawData.filter(val => val.country == countryTitle && val.month == month && val.year == year)
  
        for (let i = 0; i < kbis.length; i++) {
          let kbi_obj = data.find((x) => x.kbi === kbis[i].kbi_id);
          
          let kbi_value = 0;
         
          if (kbi_obj) {
            if(kbi_obj.kbi_value){
              kbi_value = kbi_obj.kbi_value;
            }
          }
          else {
           
            let kbi_obj_raw = filterWithCountrymonthyear.find((x) => x.brand_owner == kbi[i].brand_owners && x.category == kbi[i].category); 
           
            if(kbi_obj_raw){
              kbi_value = kbi_obj_raw.value;
              if(kbi_value == null){
                kbi_value = 0;
              }
            }

            } 

          kbiState[kbis[i].kbi_id] = kbi_value;
        }
      }
    }
// console.log("kbiInputCategoryBrand", kbiState)
    setKbiInputCategoryBrand(kbiState); //Setting KBI Input
  };
  const showDialogueBox = (msgType, msg) => {
    setMessage(msg);
    setMessageType(msgType);
    handleDialogueBox();
  };

  //AJAX Call To Load Countries
  const loadCountries = async () => {
    let url =
      BASE_URL + ACCOUNTS + USERS + COUNTRIES + `?form_id=${props.formId}`;
    let response = await new APIService().get(url);
    if (response.error) {
      console.log("error in user countrylist API", response.results.message);
    } else {
      setCountryList(response.results);
    }
  };

  //AJAX Call To Load Dates
  const loadDates = async () => {
    let url = BASE_URL + ACCOUNTS + DATES;
    let response = await new APIService().get(url);
    if (response.error) {
      console.log("error in datelist API", response.results.message);
    } else {
      setDateList(response.results);
    }
  };

  //AJAX Call To Load KBI Values
  const loadKBIValues = async (periodID, countryID, countryTitle, periodTitle) => {
    setIsLoadingLeft(true);
    let selected_date = dateList.find((x) => x.id === parseInt(periodID));

    let url =
      BASE_URL +
      INPUT_FORMS +
      EDGE_ECOMM +
      `?selected_form=${selectedForm}&selected_country=${countryID}&selected_year=${selected_date.year}&selected_period=${selected_date.month}`;
    let response = await new APIService().get(url);
    if (response.error) {
      setIsLoadingLeft(false);
      console.log("error in kbivalues API", response.results.message);
    } else {
      setupKbiInputState(response.results, "values", countryTitle, periodTitle);

      if (response.results.length > 0 && response.results[0].comment !== null) {
        setComment(response.results[0].comment);
      } else {
        setComment("");
      }
    }
  };

  const loadKBIValuesForCategoryBrand = async (periodID, countryID, kbiData, countryTitle, periodTitle) => {
    let selected_date = dateList.find((x) => x.id === parseInt(periodID));

    let url =
      BASE_URL +
      INPUT_FORMS +
      EDGE_ECOMM +
      BEYOND_CORE_ASSORTMENT +
      `?selected_form=${selectedForm}&selected_country=${countryID}&selected_year=${selected_date.year}&selected_period=${selected_date.month}`;
    let response = await new APIService().get(url);

    if (response.error) {
      setIsLoadingBeyond(false);
      console.log("error in kbivalues API", response.results.message);
    } else {
     
      await setupBrandCategoryKbiInputState(kbiData, "values", response?.results, countryTitle, periodTitle);
      setIsLoadingBeyond(false);
    }
  };
  const loadCategoryBrandMapping = async (periodID, countryID, countryTitle, periodTitle) => {
    setCategoryBrandMasterList([]);
    setIsLoadingBeyond(true);
    let url =
      BASE_URL +
      INPUT_FORMS +
      EDGE_ECOMM +
      CATEGORY_BRAND_MAPPING +
      `?selected_form=${selectedForm}&selected_country=${countryID}`;
    let response = await new APIService().get(url);

    if (response.error) {
      setIsLoadingBeyond(false);
      console.log("error in kbivalues API", response.results.message);
    } else {
      if (response.results.length > 0 && response.results[0].comment !== null) {
        let data = response.results;
        const UpdatedData = data.reduce((acc, curr) => {
          if (!acc[curr.category_id]) {
            acc[curr.category_id] = [];
          }
          acc[curr.category_id].push(curr);
          return acc;
        }, {});
        setCategoryBrandMasterListUnEdited(data);
        setCategoryBrandMasterList(UpdatedData);
        await setupBrandCategoryKbiInputState(data, "master", data, countryTitle, periodTitle);
        await loadKBIValuesForCategoryBrand(periodID, countryID, data, countryTitle, periodTitle);
      } else {
        setIsLoadingBeyond(false);
      }
    }
  };
  //Ajax Call To Load KBI Master
  const loadKBIMaster = async () => {
    let url =
      BASE_URL +
      INPUT_FORMS +
      EDGE_ECOMM +
      KBIS +
      `?selected_form=${selectedForm}`;
    let response = await new APIService().get(url);
    if (response.error) {
      console.log("error in user datelis API", response.results.message);
    } else {
      const data = await response.results.sort((a, b) => a.rank - b.rank);
      setKbiMasterList(data);
      setupKbiInputState(data, "master");
      let kbiss = {}
      data.map(val => {
        kbiss[val.kbi_id] = "";
      })
      setkbiInputUnEdited(kbiss)
    }

  };


  const loadBeyondValuesRawTable = async () => {
    let url =
      BASE_URL +
      INPUT_FORMS +
      EDGE_ECOMM +
      BEYOND_CORE_ASSORTMENT +
      RAWDATA +
      `?selected_form=${selectedForm}`;
    let response = await new APIService().get(url);
    if (response.error) {
      console.log("error in user datelis API", response.results.message);
    } else {
      setBeyondRawData(response.results);
      
    }
  };

  //Dropdown Handler As Parent Component
  const setDropDownValues = async (id, title, valueType) => {
   
    if (valueType === "Country") {
      setSelectedCountryID(id);
      setSelectedCountryTitle(title);
    } else if (valueType === "Period") {
      setSelectedPeriodID(id);
      setSelectedPeriodTitle(title);
    }
    setInputDisabled(true); //On Every Country And Period Change Edit Button Should Be Disabled
    if (valueType === "Country") {
    }
    //Loading KBI Values which is dependent on Country and Period Dropdown
    if (selectedPeriodID || selectedCountryID) {
      //Check if one of Country/Period selected
      if (selectedPeriodID && valueType === "Country") {
        //Period is already selected

        await loadCategoryBrandMapping(selectedPeriodID, id, title, selectedPeriodTitle);
        await loadKBIValues(selectedPeriodID, id, title, selectedPeriodTitle);

        //(period, country)
      } else if (selectedCountryID && valueType === "Period") {
        //Country is already selected
        await loadCategoryBrandMapping(id, selectedCountryID, selectedCountryTitle, title);
        await loadKBIValues(id, selectedCountryID, selectedCountryTitle, title); //(period, country)
      }
    }
  };

  //Setting Initial Dropdown Related Input Fields
  const setInitialDropdownRelatedInputData = () => {
    if (countryList.length !== 0) {
      setSelectedCountryTitle(countryList[0].title);
    }
    if (dateList.length !== 0) {
      setSelectedPeriodTitle(dateList[0].title);
    }
  };

  const handleDialogueBox = () => {
    setOpenDialogueBox(!openDialogueBox);
  };

  //Handling Edit Button
  const handleEdit = async () => {
    //Country and Period required validation
    if (selectedCountryID !== "" && selectedPeriodID !== "") {
      if (inputDisabled) {
        let selected_date = dateList.find(
          (x) => x.id === parseInt(selectedPeriodID)
        );
        let url =
          BASE_URL +
          INPUT_FORMS +
          EDGE_ECOMM +
          EDIT_CHECK +
          `?selected_form=${selectedForm}&selected_country=${selectedCountryID}&selected_year=${selected_date.year}&selected_period=${selected_date.month}`;
        let response = await new APIService().get(url);

        if (response.error) {
          console.log("error in user datelis API", response.results.message);
        } else {
          if (response.results.is_editable === true) {
            setInputDisabled(!inputDisabled);
            setComment(""); //Business Requirement To Empty Comment Box On Form Edit.
          } else {
            showDialogueBox(
              "Error",
              "Previous update request for selected country and period is pending with Admin for approval. Data entry not allowed for now."
            );
          }
        }
      } else {
        setInputDisabled(!inputDisabled);
      }
    } else {
      showDialogueBox(
        "Error",
        "Select Country and Period first to enter data."
      );
    }
  };

  //Dynamic Input onChange Handler
  const inputOnChange = (e) => {
    const re = /^[0-9]*\.?[0-9]*$/;
    const value = e.target.value;
    const name = e.target.name;

    if (value === "" || re.test(value)) {
      setKbiInput((prevState) => {
        return { ...prevState, [name]: value };
      });
       setkbiInputUnEdited((prevState) => {
        return { ...prevState, [name]: value };
      });
    }
   
  };

  //Dynamic Input onChange Handler
  const inputOnChangeCategoryBrand = (e) => {
    const re = /^[0-9]*\.?[0-9]*$/;
    const value = e.target.value;
    const name = e.target.name;

    if (value === "" || re.test(value)) {
      // console.log("kbi", kbiInputCategoryBrand)
      setKbiInputCategoryBrand((prevState) => {
        return { ...prevState, [name]: value };
      });
    }
  
  };

  const getRequestBody = async () => {
    let selected_date = dateList.find(
      (x) => x.id === parseInt(selectedPeriodID)
    );
    // let values = await formatKbiInputCategoryBrand(kbiInput);

    let newKbis = [];
    const keys = Object.keys(kbiInputUnEdited);
    keys.forEach((key, index) => {
      newKbis.push({
        kbi_id: key,
        kbi_value: parseFloat(kbiInput[key]),
      });
    });

    var count = 0;
    for (let i = 0; i < newKbis.length; i++) {
      const element = newKbis[i]?.kbi_value;
      if (isBlank(element)) {
        count += 1;
      }
    }
    let body = {};
    if (count > 0) {
      body = null;
    } else {
     body = {
      country_id: selectedCountryID,
      form_id: selectedForm,
      year: selected_date.year,
      period: selected_date.month,
      kbi_inputs: formatKbiInput(),
    };
    if (comment !== "") {
      body["comment"] = comment;
    }
  }
  
  return body;
  };

  function isBlank(str) {
    if (str == 0) {
      return false;
    } else {
      return !str || /^\s*$/.test(str);
    }
  }
  //New
  const formatKbiInputCategoryBrand = (kbis) => {
    

    let newKbis = [];
    const keys = Object.keys(kbis);
    keys.forEach((key, index) => {
      newKbis.push({
        kbi_id: key,
        kbi_value: parseFloat(kbis[key]),
      });
    });
    var count = 0;
    for (let i = 0; i < newKbis.length; i++) {
      const element = newKbis[i]?.kbi_value;
      if (isBlank(element)) {
        count += 1;
      }
    }

    if (count > 0) {
      setIsLoadingUi(false);
      return null;
    } else {
      return newKbis;
    }
  };
  const getRequestBodyCategoryBrand = async () => {
    let selected_date = dateList.find(
      (x) => x.id === parseInt(selectedPeriodID)
    );
    let values = await formatKbiInputCategoryBrand(kbiInputCategoryBrand);
    let body = {};
    if (values == null) {
      body = null;
    } else {
      body = {
        country_id: selectedCountryID,
        form_id: selectedForm,
        year: selected_date.year,
        period: selected_date.month,
        kbi_inputs: values,
      };
      if (comment !== "") {
        body["comment"] = comment;
      }
    }
    return body;
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingUi(true);
    let url = BASE_URL + INPUT_FORMS + EDGE_ECOMM;
    let urlTwo = BASE_URL + INPUT_FORMS + EDGE_ECOMM + BEYOND_CORE_ASSORTMENT;
    let valuesBeyond = await getRequestBodyCategoryBrand();
    let valuesKbi = await getRequestBody();
//     console.log(kbiInputCategoryBrand,"kbiInputCategoryBrand")
//     console.log(kbiInput,"kbiInput")
        if (valuesBeyond == null) { 
          setIsLoadingUi(false);
      showDialogueBox("Error", "All fields are required and data must be valid!");
    } else if(valuesKbi == null){
      setIsLoadingUi(false);
      showDialogueBox("Error", "All fields are required and data must be valid!");
    }
    
    else {
      let response = await new APIService().post(url, valuesKbi);
      let responseTwo = await new APIService().post(urlTwo, valuesBeyond);

      if (response.error || responseTwo.error) {
        if (response.status == 400 || responseTwo.status) {
          setIsLoadingUi(false);
          if (
            response.results?.message?.includes("Comment") ||
            responseTwo.results?.message?.includes("Comment")
          ) {
            // showDialogueBox("Validation Error", response.results.message)
            showDialogueBox(
              "Error",
              "Comment field is mandatory for historical data. Please add to proceed."
            );
          } else {
            showDialogueBox(
              "Error",
              "All fields are required and data must be valid!"
            );
          }
        }
      } else {
        setIsLoadingUi(false);
        if (response.results.is_request_raised) {
          showDialogueBox(
            "Success",
            "Update request for historical data has been successfully submitted to Admin for approval."
          );
        } else {
          showDialogueBox("Success", "Data has been saved successfully.");
        }
        setInputDisabled(!inputDisabled);
      }
    }
  };

  //useEffect Method
  useEffect(() => {
    // let accessToken = JSON.parse(localStorage.getItem('access_token'))
    let mounted = true;
    if (mounted) {
      loadCountries();
      loadBeyondValuesRawTable();
      loadDates();
      loadKBIMaster();
      // Set Country And Period Input Data
      setInitialDropdownRelatedInputData();
    }
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getTotalNSR = () => {
    let totalNSR = 0;
    if (kbiInput["KEECOM001"])
      totalNSR = totalNSR + parseFloat(kbiInput["KEECOM001"]);
    if (kbiInput["KEECOM002"])
      totalNSR = totalNSR + parseFloat(kbiInput["KEECOM002"]);
    if (kbiInput["KEECOM003"])
      totalNSR = totalNSR + parseFloat(kbiInput["KEECOM003"]);
    if (kbiInput["KEECOM004"])
      totalNSR = totalNSR + parseFloat(kbiInput["KEECOM004"]);
    if (kbiInput["KEECOM025"])
      totalNSR = totalNSR + parseFloat(kbiInput["KEECOM025"]);
    return totalNSR;
  };

  const getTotalVolume = () => {
    let totalVolume = 0;
    if (kbiInput["KEECOM005"])
      totalVolume = totalVolume + parseFloat(kbiInput["KEECOM005"]);
    if (kbiInput["KEECOM006"])
      totalVolume = totalVolume + parseFloat(kbiInput["KEECOM006"]);
    if (kbiInput["KEECOM007"])
      totalVolume = totalVolume + parseFloat(kbiInput["KEECOM007"]);
    if (kbiInput["KEECOM008"])
      totalVolume = totalVolume + parseFloat(kbiInput["KEECOM008"]);
    if (kbiInput["KEECOM026"])
      totalVolume = totalVolume + parseFloat(kbiInput["KEECOM026"]);
    return totalVolume;
  };

  return (
    <Spin tip="Loading" spinning={isLoadingUi} size="large">
      {" "}
      <div className="user-view-container">
        <form onSubmit={onSubmit}>
          {/* Body Header */}
          <div className="user-view-header row p-0 m-0 mb-4">
            <div className="col-5 p-0 m-0 row">
              <div className="col-5 p-0 m-0">
                <DropDownCustom
                  title="Country"
                  options={countryList}
                  setParentDropdownValue={setDropDownValues}
                />
              </div>
              <div className="col-5 p-0 m-0">
                <DropDownCustom
                  title="Period"
                  options={dateList}
                  setParentDropdownValue={setDropDownValues}
                />
              </div>
            </div>
            <div className="col-3 p-0 m-0 my-auto user-view-added-at-container">
              <span>
                <label className="user-view-text-header">Last Updated At</label>
              </span>
              <span>
                <label className="user-view-text-value">{lastUpdatedAt}</label>
              </span>
            </div>
            <div className="col-4 p-0 m-0 my-auto user-view-added-by-container">
              <span>
                <label className="user-view-text-header">
                  Last Submitted By
                </label>
              </span>
              <span>
                <label className="user-view-text-value">{lastAddedBy}</label>
              </span>
            </div>
          </div>
          {/* Body Header */}
          {/* Input Tags */}
          <Row span={24} style={{ height: "60px", paddingLeft: "20px" }}>
            <Col span={12}>
              <Row span={24}>
                <Col span={5}>
                  <p className="user-view-input-label-text">Country</p>
                </Col>
                <Col span={13}>
                  <input
                    className="text-input-box-disabled"
                    type="text"
                    value={
                      selectedCountryTitle === "--Select--"
                        ? ""
                        : selectedCountryTitle
                    }
                    disabled
                  />
                </Col>
                <Col span={6}></Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row span={24} justify="end">
                <FontAwesomeIcon
                  icon={faEdit}
                  className="navlink-icon"
                  onClick={handleEdit}
                  size="2x"
                  style={{ color: inputDisabled ? "#E61C2A" : "#9B9595" }}
                />
              </Row>
            </Col>
          </Row>
          <Row span={24} style={{ height: "60px", paddingLeft: "20px" }}>
            <Col span={12}>
              <Row span={24}>
                <Col span={5}>
                  <p className="user-view-input-label-text">Period</p>
                </Col>
                <Col span={13}>
                  <input
                    className="text-input-box-disabled"
                    type="text"
                    value={
                      selectedPeriodTitle === "--Select--"
                        ? ""
                        : selectedPeriodTitle
                    }
                    disabled
                  />
                </Col>
                <Col span={6}></Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row span={24} justify="center">
                <Typography.Title level={4} style={{ color: "#E61C2A" }}>
                  Beyond The Core Assortment
                </Typography.Title>
              </Row>
            </Col>
          </Row>
          {/* KBI Input Tags For Loop(Dynamic) */}
          <Row gutter={16}>
            <Col span={12}>
              <Spin tip="Loading" spinning={isLoadingLeft} size="large">
                <Card>
                  {kbiMasterList.map((kbi) => (
                    <>
                      {kbi.kbi_id === "KEECOM009" ? (
                        <>
                          <Row span={24} style={{ height: "60px" }}>
                            <Col span={5}>
                              <p className="user-view-input-label-text">
                                {kbi.title}
                              </p>
                            </Col>
                            <Col span={14}>
                              <input
                                className="text-input-box"
                                name={kbi.kbi_id}
                                value={
                                  kbiInput[kbi.kbi_id]
                                    ? kbiInput[kbi.kbi_id]
                                    : ""
                                }
                                onChange={inputOnChange}
                                disabled={inputDisabled}
                                type="text"
                                step="any"
                                min="0"
                              />
                            </Col>
                            <Col span={5}>
                              <p className="user-view-kbi-unit">{kbi.unit}</p>
                            </Col>
                          </Row>
                          {/* Total NSR(Only To Show) */}
                          <Row span={24} style={{ height: "60px" }}>
                            <Col span={5}>
                              <p className="user-view-input-label-text">
                                Total NSR
                              </p>
                            </Col>
                            <Col span={14}>
                              <input
                                className="text-input-box-disabled"
                                name="total_nsr"
                                value={getTotalNSR() ? getTotalNSR() : 0}
                                // onChange={inputOnChange}
                                disabled={true}
                                type="number"
                                step="any"
                                min="0"
                              />
                            </Col>
                            <Col span={5}>
                              <p className="user-view-kbi-unit">('000 EUR)</p>
                            </Col>
                          </Row>
                          {/* Total NSR(Only To Show) */}
                          {/* Total Volume(Only To Show) */}
                          <Row span={24} style={{ height: "60px" }}>
                            <Col span={5}>
                              <p className="user-view-input-label-text">
                                Total Volume
                              </p>
                            </Col>
                            <Col span={14}>
                              <input
                                className="text-input-box-disabled"
                                name="total_nsr"
                                value={getTotalVolume() ? getTotalVolume() : 0}
                                // onChange={inputOnChange}
                                disabled={true}
                                type="number"
                                step="any"
                                min="0"
                              />
                            </Col>
                            <Col span={5}>
                              <p className="user-view-kbi-unit">(UC '000)</p>
                            </Col>
                          </Row>
                          <Row span={24} justify="center">
                            <Typography.Title
                              level={4}
                              style={{ color: "#E61C2A" }}
                            >
                              Market Share Per Category
                            </Typography.Title>
                          </Row>
                          {/* Total Volume(Only To Show) */}
                        </>
                      ) : (
                        <Row span={24} style={{ height: "60px" }}>
                          <Col span={5}>
                            <p className="user-view-input-label-text">
                              {kbi.title}
                            </p>
                          </Col>
                          <Col span={14}>
                            <input
                              className="text-input-box"
                              name={kbi.kbi_id}
                              value={
                                kbiInput[kbi.kbi_id] > -1
                                  ? kbiInput[kbi.kbi_id]
                                  : ""
                              }
                              onChange={inputOnChange}
                              disabled={inputDisabled}
                              type="text"
                              step="any"
                              min="0"
                            />
                          </Col>
                          <Col span={5}>
                            <p className="user-view-kbi-unit">{kbi.unit}</p>
                          </Col>
                        </Row>
                      )}
                    </>
                  ))}
                </Card>
              </Spin>
            </Col>

            {/* second card */}

            <Col span={12}>
              <Spin tip="Loading" spinning={isLoadingBeyond} size="large">
                {categoryBrandMasterList &&
                  Object.keys(categoryBrandMasterList).map((key) => {
                    return (
                      <>
                        <Card style={{ marginBottom: "15px" }}>
                          {categoryBrandMasterList[key].map((kbi) => {
                            return (
                              <Row span={24} style={{ height: "60px" }}>
                                <Col span={kbi.brand_owners == "" || kbi.brand_owners == null ? 5 : 10}>
                                  <p className="user-view-input-label-text">
                                    {kbi.brand_owners == "" || kbi.brand_owners == null
                                      ? kbi.category
                                      : kbi.brand_owners}
                                  </p>
                                </Col>
                                <Col span={kbi.brand_owners == "" || kbi.brand_owners == null ? 14 : 9}>
                                  <input
                                    className="text-input-box"
                                    name={kbi.kbi_id}
                                    value={
                                      kbiInputCategoryBrand[kbi.kbi_id] > -1
                                        ? kbiInputCategoryBrand[kbi.kbi_id]
                                        : ""
                                    }
                                    onChange={inputOnChangeCategoryBrand}
                                    disabled={inputDisabled}
                                    type="text"
                                    // step="any"
                                    // min="0"
                                  />
                                </Col>
                                <Col span={5}>
                                  <p className="user-view-kbi-unit">
                                    {kbi.unit}
                                  </p>
                                </Col>
                              </Row>
                            );
                          })}
                        </Card>
                      </>
                    );
                  })}
              </Spin>
            </Col>
          </Row>

          {/* KBI Input Tags For Loop(Dynamic) */}
          {/* Comment Box */}
          <Row
            span={24}
            style={{ height: "60px", paddingLeft: "20px", paddingTop: "10px" }}
          >
            <Col span={2}>
              <p className="user-view-input-label-text-comment">Comment</p>
            </Col>
            <Col span={14}>
              <textarea
                className="text-input-textarea"
                name="w3review"
                rows="4"
                cols="50"
                disabled={inputDisabled}
                value={comment}
                onChange={handleCommentOnChange}
              />
            </Col>
            <Col span={8}></Col>
          </Row>
          {/* Comment Box */}
          {/* Input Tags */}
          {/* Submit Button */}
          <div className="user-view-input-layer-submit row pr-3 m-0 ">
            <div className="col-12 m-0">
              <input
                className="form-submit-button col"
                type="submit"
                value="Submit"
                disabled={inputDisabled}
                style={{
                  backgroundColor: !inputDisabled ? "#E61C2A" : "#9B9595",
                }}
              />
            </div>
          </div>
          {/* Submit Button */}
        </form>
        {/* Message Dialogue Box */}
        {openDialogueBox ? (
          <DialogueBox
            openDialogueBox={openDialogueBox}
            handleDialogueBox={handleDialogueBox}
            messageType={messageType}
            message={message}
          />
        ) : (
          ""
        )}
        {/* Message Dialogue Box */}
      </div>
    </Spin>
  );
};

export default User1;
