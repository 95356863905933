import React, { useState, useEffect, createRef } from "react";
import {
  Col,
  Card,
  Row,
  Typography,
  Button,
  Tabs,
  Space,
  AutoComplete,
  Select,
  Tooltip,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ExclamationCircleFilled } from "@ant-design/icons";

import EditIcon from "@mui/icons-material/Edit";
import { Spin, Modal, Input, Form } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import {
  ACCOUNTS,
  BASE_URL,
  COUNTRIES,
  DATES,
  EDGE_ECOMM,
  EDIT_CHECK,
  INPUT_FORMS,
  KBIS,
  USERS,
  CATEGORY_BRAND_MAPPING,
  BEYOND_CORE_ASSORTMENT,
  ADMIN,
  EXPORT,
  UPDATE,
  LAST_RECORD,
  CREATE,
  KBI_ID,
  DELETE,
} from "../../constant/apiEndpoints";
import APIService from "../../services/APIService";

import DropDownCustomEretail from "../../components/dropDownCustomEretail";
import TableBeyondCoreAssortment from "../../components/tableBeyondCoreAssortment";
const { confirm } = Modal;

const UNITS = [
  {
    type: 'NSR',
    name: '(\'000 EUR)',
    multiply_factor: 1000
  },
  {
    type: 'Volume',
    name: '(UC \'000)',
    multiply_factor: 1000
  }
]

const CategoryBrandMapping = (props) => {
  const initialColumns = [
    {
      dataIndex: "country_title",
      title: "Country",
    },
    { dataIndex: "category", title: "Category" },

    {
      dataIndex: "brand_owners",
      title: "Brand Owners",
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },

    {
      dataIndex: "added_by",
      title: "Added By",
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      dataIndex: "added_at",
      title: "Added On",
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      dataIndex: "responded_by",
      title: "Updated By",
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      dataIndex: "responded_at",
      title: "Updated On",
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 10,
      render: (_, record) => (
        <Button onClick={() => handleEdit(record)}>Edit</Button>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [isLoadingModalAdd, setIsLoadingModalAdd] = useState(false);
  const [isLoadingModalEdit, setIsLoadingModalEdit] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [countryListForModal, setCountryListForModal] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [brandOwnersList, setBrandOwnersList] = useState([]);
  const [dataSourceCategories, setDataSourceCategoriess] = useState([]);
  const [dataSourceBrandOwners, setDataSourceBrandOwners] = useState([]);
  const [selectedCountryList, setSelectedCountryList] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [selectedBrandOwnersList, setSelectedBrandOwnersList] = useState([]);
  const [existingDataSourceCountries, setExistingDataSourceCountries] =
    useState([]);
  const [existingDataSourceCategories, setExistingDataSourceCategoriess] =
    useState([]);
  const [existingDataSourceBrandOwners, setExistingDataSourceBrandOwners] =
    useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryNewModal, setSelectedCountryNewModal] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryNewModal, setSelectedCategoryNewModal] = useState("");
  const [selectedBrandOwners, setSelectedBrandOwners] = useState([]);
  const [selectedcategoryIdData, setSelectedcategoryIdData] = useState({});
  // const [selectedCountry, setSelectedCountry] = useState("");
  //table
  const [selectedList, setSelectedList] = useState([]); //Button Handle
  const [columns, setColumns] = useState(initialColumns);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const [originalData, setOriginalData] = useState([]);
  const [originalDataWithoutFilter, setOriginalDataWithoutFilter] = useState(
    []
  );
  const [filteredData, setFilteredData] = useState([]);
  const [isLoadingUi, setIsLoadingUi] = React.useState(false);
  const [active, setActive] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = React.useState(false);
  const [exportData, setExportData] = React.useState([]);

  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);

  const handleOkAdd = () => {
    setIsModalOpenAdd(false);
  };
  const handleCancelAdd = () => {
    setIsModalOpenAdd(false);
  };
  const csvLink = createRef();
  const headers = [
    {
      key: "country_title",
      label: "Country",
    },
    { key: "category", label: "Category" },

    { key: "brand_owners", label: "Brand Owners" },

    { key: "added_by", label: "Added By" },
    { key: "added_at", label: "Added On" },
    { key: "responded_by", label: "Responded By" },
    { key: "responded_at", label: "Responded On" },
  ];

  function handleEdit(row) {
    form.setFieldsValue({
      brandOwner: row.brand_owners,
    });
    setEditableData(row);
    console.log("hhh", row);
    setIsModalOpenEdit(true);
  }

  async function findDuplicates(array) {
    const counts = {};
    const duplicates = [];

    // Count occurrences of each element
    array.forEach((element) => {
      counts[element] = (counts[element] || 0) + 1;
    });

    // Find duplicates
    for (const element in counts) {
      if (counts[element] > 1) {
        duplicates.push({ element, count: counts[element] });
      } else if (counts[element] == 1) {
        duplicates.push({ element, count: counts[element] });
      }
    }

    return duplicates;
  }
  const handleAdd = () => {
    setIsModalOpenAdd(true);
  };
  const handleDelete = async () => {
    let categoryIds = [];
    let deleteIds = selectedList;
    for (let i = 0; i < selectedList.length; i++) {
      const element = selectedList[i];
      const data = originalDataWithoutFilter.filter(
        (val) => val.kbi_id == element
      );
      const category_id = data[0].category_id;
      categoryIds.push(category_id);
    }
    const category_iddd = await findDuplicates(categoryIds);
    for (let index = 0; index < category_iddd.length; index++) {
      const elementToCheck = category_iddd[index].element;
      const data = originalDataWithoutFilter.filter(
        (val) => val.category_id == elementToCheck
      );

      if (data.length - 2 == category_iddd[index].count) {
        data.map((val) => {
          deleteIds.push(val.kbi_id);
        });
      }
    }

    const uniqueArray = [...new Set(deleteIds)];
    let deleteArrAyOfIds = [];
    for (let index = 0; index < uniqueArray.length; index++) {
      const element = uniqueArray[index];
      let obj = {
        kbi_id: element,
      };
      deleteArrAyOfIds.push(obj);
    }
    setSelectedList([]);

    await DeleteData(deleteArrAyOfIds);
  };
  const exportDataHandle = async () => {
    setIsLoadingExport(true);

    let url =
      BASE_URL +
      INPUT_FORMS +
      EDGE_ECOMM +
      CATEGORY_BRAND_MAPPING +
      ADMIN +
      EXPORT +
      `?selected_form=${props.formId}`;
    let response = await new APIService().get(url);

    if (response?.error) {
      setIsLoadingExport(false);
      console.log("error in kbivalues API", response?.results?.message);
    } else {
      if (response.results.length > 0) {
        var filteredArray = await response?.results.filter(
          (obj) =>
            !obj.category.includes("NSR") && !obj.category.includes("Volume")
        );
        setExportData(filteredArray);
        setActive(true);
        setIsLoadingExport(false);
      } else {
        setExportData([]);
        setActive(true);
        setIsLoadingExport(false);
      }
    }
  };
  const onFinishEdit = async (value) => {
    setIsLoadingModalEdit(true);
    const data = {
      kbi_id: editableData.kbi_id,
      brand_owners: value.brandOwner,
    };
    let url =
      BASE_URL +
      INPUT_FORMS +
      EDGE_ECOMM +
      CATEGORY_BRAND_MAPPING +
      ADMIN +
      UPDATE +
      `?selected_form=${props.formId}`;
    let response = await new APIService().post(url, data);

    if (response?.error) {
      setIsLoadingModalEdit(false);
      console.log("error in kbivalues API", response.results.message);
    } else {
      if (response?.status == 201) {
        setIsLoadingModalEdit(false);
        setIsModalOpenEdit(false);
        await loadCategoryBrandMapping();
      }
    }
  };

  async function loadcorrespondingValues(
    countryIds,
    categoryIds,
    brandOwnersIds
  ) {
    if (
      countryIds.length == 0 &&
      categoryIds.length == 0 &&
      brandOwnersIds.length == 0
    ) {
      const uniquecategoriesN = await toFilterWith(originalData, "category");
      const uniquebrandOwnersN = await toFilterWith(
        originalData,
        "brand_owners"
      );
      const uniquecountryListN = await toFilterWith(
        originalData,
        "country_title"
      );

      setFilteredData(originalData);
      setCategoryList(uniquecategoriesN);
      setBrandOwnersList(uniquebrandOwnersN);
      setCountryList(uniquecountryListN);
    } else if (
      countryIds.length > 0 &&
      categoryIds.length > 0 &&
      brandOwnersIds.length > 0
    ) {
      const exists = originalData.filter(
        (item) =>
          countryIds.includes(item.country_title) &&
          categoryIds.includes(item.category) &&
          brandOwnersIds.includes(item.brand_owners)
      );

      const uniqueccountriesN = await toFilterWith(exists, "country_title");
      const uniquecategoriesN = await toFilterWith(exists, "category");
      const uniquebrandOwnersN = await toFilterWith(exists, "brand_owners");
      setFilteredData(exists);
      setCategoryList(uniquecategoriesN);
      setBrandOwnersList(uniquebrandOwnersN);
      setCountryList(uniqueccountriesN);
    } else if (
      countryIds.length > 0 &&
      categoryIds.length == 0 &&
      brandOwnersIds.length == 0
    ) {
      const exists = originalData.filter((item) =>
        countryIds.includes(item.country_title)
      );

      const uniqueccountriesN = await toFilterWith(exists, "country_title");
      const uniquecategoriesN = await toFilterWith(exists, "category");
      const uniquebrandOwnersN = await toFilterWith(exists, "brand_owners");
      setFilteredData(exists);
      setCategoryList(uniquecategoriesN);
      setBrandOwnersList(uniquebrandOwnersN);
      setCountryList(uniqueccountriesN);
    } else if (
      countryIds.length == 0 &&
      categoryIds.length > 0 &&
      brandOwnersIds.length == 0
    ) {
      const exists = originalData.filter((item) =>
        categoryIds.includes(item.category)
      );

      const uniqueccountriesN = await toFilterWith(exists, "country_title");
      const uniquecategoriesN = await toFilterWith(exists, "category");
      const uniquebrandOwnersN = await toFilterWith(exists, "brand_owners");
      setFilteredData(exists);
      setCategoryList(uniquecategoriesN);
      setBrandOwnersList(uniquebrandOwnersN);
      setCountryList(uniqueccountriesN);
    } else if (
      countryIds.length == 0 &&
      categoryIds.length == 0 &&
      brandOwnersIds.length > 0
    ) {
      const exists = originalData.filter((item) =>
        brandOwnersIds.includes(item.brand_owners)
      );

      const uniqueccountriesN = await toFilterWith(exists, "country_title");
      const uniquecategoriesN = await toFilterWith(exists, "category");
      const uniquebrandOwnersN = await toFilterWith(exists, "brand_owners");
      setFilteredData(exists);
      setCategoryList(uniquecategoriesN);
      setBrandOwnersList(uniquebrandOwnersN);
      setCountryList(uniqueccountriesN);
    } else if (
      countryIds.length == 0 &&
      categoryIds.length > 0 &&
      brandOwnersIds.length > 0
    ) {
      const exists = originalData.filter(
        (item) =>
          categoryIds.includes(item.category) &&
          brandOwnersIds.includes(item.brand_owners)
      );
      const uniqueccountriesN = await toFilterWith(exists, "country_title");
      const uniquecategoriesN = await toFilterWith(exists, "category");
      const uniquebrandOwnersN = await toFilterWith(exists, "brand_owners");
      setFilteredData(exists);
      setCategoryList(uniquecategoriesN);
      setBrandOwnersList(uniquebrandOwnersN);
      setCountryList(uniqueccountriesN);
    } else if (
      countryIds.length > 0 &&
      categoryIds.length == 0 &&
      brandOwnersIds.length > 0
    ) {
      const exists = originalData.filter(
        (item) =>
          countryIds.includes(item.country_title) &&
          brandOwnersIds.includes(item.brand_owners)
      );
      const uniqueccountriesN = await toFilterWith(exists, "country_title");
      const uniquecategoriesN = await toFilterWith(exists, "category");
      const uniquebrandOwnersN = await toFilterWith(exists, "brand_owners");
      setFilteredData(exists);
      setCategoryList(uniquecategoriesN);
      setBrandOwnersList(uniquebrandOwnersN);
      setCountryList(uniqueccountriesN);
    } else if (
      countryIds.length > 0 &&
      categoryIds.length > 0 &&
      brandOwnersIds.length == 0
    ) {
      const exists = originalData.filter(
        (item) =>
          countryIds.includes(item.country_title) &&
          categoryIds.includes(item.category)
      );

      const uniqueccountriesN = await toFilterWith(exists, "country_title");
      const uniquecategoriesN = await toFilterWith(exists, "category");
      const uniquebrandOwnersN = await toFilterWith(exists, "brand_owners");
      setFilteredData(exists);
      setCategoryList(uniquecategoriesN);
      setBrandOwnersList(uniquebrandOwnersN);
      setCountryList(uniqueccountriesN);
    }
  }

  function convertToArray(id) {
    let arr = [];
    if (id.length > 0) {
      id.map((val) => {
        arr.push(val.value);
      });
    }
    return arr;
  }

  const setDropDownValuesModal = async (ids, valueType) => {
    if (valueType === "Country") {
    }
    if (valueType === "Category") {
    }
  };
  const setDropDownValues = async (ids, valueType) => {
    if (valueType === "Country") {
      setSelectedCountryList(ids);
      await loadcorrespondingValues(
        convertToArray(ids),
        convertToArray(selectedCategoryList),
        convertToArray(selectedBrandOwnersList)
      );
    } else if (valueType === "Category") {
      await loadcorrespondingValues(
        convertToArray(selectedCountryList),
        convertToArray(ids),
        convertToArray(selectedBrandOwnersList)
      );
      setSelectedCategoryList(ids);
    } else if (valueType === "Brand Owners") {
      setSelectedBrandOwnersList(ids);
      await loadcorrespondingValues(
        convertToArray(selectedCountryList),
        convertToArray(selectedCategoryList),
        convertToArray(ids)
      );
    }

    //     setInputDisabled(true); //On Every Country And Period Change Edit Button Should Be Disabled
    //     //Loading KBI Values which is dependent on Country and Period Dropdown
  };

  const handleSaveCountry = async (country) => {
    // setCurrentCountry(country);
    // await loadData(country, currentWeek, cchYearMonth, false);
  };

  async function toFilterWith(arr, valueToFilter) {
    let categories = [];
    let uniquecategoriesN = [];

    if (arr.length > 0) {
      let narr = await arr.map((val) => {
        categories.push(val[valueToFilter]?.trim());
      });
      const uniquecategories = [...new Set(categories)];

      let narrr = await uniquecategories.map((val) => {
        let arrBN = {
          label: val,
          value: val,
        };
        uniquecategoriesN.push(arrBN);
      });
      return uniquecategoriesN;
    }
  }

  const handleOkEdit = () => {
    setIsModalOpenEdit(false);
  };
  const handleCancelEdit = () => {
    setIsModalOpenEdit(false);
  };

  async function loadCategoryBrandMapping() {
    setIsLoadingUi(true);
    setSelectedCategoryList([]);
    setSelectedBrandOwnersList([]);
    setSelectedCountryList([]);
    let url =
      BASE_URL +
      INPUT_FORMS +
      EDGE_ECOMM +
      CATEGORY_BRAND_MAPPING +
      ADMIN +
      `?selected_form=${props.formId}`;
    let response = await new APIService().get(url);

    if (response?.error) {
      setIsLoadingUi(false);
      console.log("error in kbivalues API", response.results.message);
    } else {
      if (response?.results?.length > 0) {
        setOriginalDataWithoutFilter(response?.results);
        var filteredArray = response?.results.filter(
          (obj) =>
            !obj.category.includes("NSR") && !obj.category.includes("Volume")
        );

        const updatedData = filteredArray.map((item, index) => ({
          ...item,
          key: item.kbi_id,
        }));

        if (filteredArray.length > 0) {
          const uniquecategoriesN = await toFilterWith(updatedData, "category");
          const uniquebrandOwnersN = await toFilterWith(
            updatedData,
            "brand_owners"
          );
          const uniquecountriesN = await toFilterWith(
            updatedData,
            "country_id"
          );
          const uniquecountriesTitle = await toFilterWith(
            updatedData,
            "country_title"
          );

          setDataSourceCategoriess(uniquecategoriesN);
          setDataSourceBrandOwners(uniquebrandOwnersN);
          setExistingDataSourceCountries(uniquecountriesTitle);
          setCategoryList(uniquecategoriesN);
          setBrandOwnersList(uniquebrandOwnersN);
          setCountryList(uniquecountriesTitle);
          setOriginalData(updatedData);
          setFilteredData(updatedData);
          setIsLoadingUi(false);
        }
      } else {
        setIsLoadingUi(false);
      }
    }
  }

  //AJAX Call To Load Countries
  const loadCountries = async () => {
    let url =
      BASE_URL + ACCOUNTS + USERS + COUNTRIES + `?form_id=${props.formId}`;
    let response = await new APIService().get(url);
    if (response.error) {
      console.log("error in user countrylist API", response.results.message);
    } else {
      if (response?.results?.length > 0) {
        let data = [];
        response.results.map((val) => {
          let arr = {
            label: val["title"],
            value: val["id"],
          };
          data.push(arr);
        });
        setCountryList(data);
        setCountryListForModal(data);
      }
    }
  };

  //useEffect Method
  useEffect(() => {
    // let accessToken = JSON.parse(localStorage.getItem('access_token'))
    let mounted = true;
    if (mounted) {
      loadCountries();
      loadCategoryBrandMapping();
    }
    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [active]);

  const date = moment().format("YYYY-MM-DD");
  const time = moment().format("LT").replace(/\s/g, "");
  const nameOfExportedFile = `CountryCategoryBrandMapping${date}_${time}`;

  const DeleteData = async (data) => {
    setIsLoadingUi(true);
    let url =
      BASE_URL +
      INPUT_FORMS +
      EDGE_ECOMM +
      CATEGORY_BRAND_MAPPING +
      ADMIN +
      DELETE +
      `?selected_form=${props.formId}`;
    let response = await new APIService().post(url, data);
   
    if (response?.error) {
      console.log("error in kbivalues API", response.results.message);
    } else {
      console.log("response", response);

      if (response?.status == 200) {
        deleteRecords();
        await loadCategoryBrandMapping();
      } else {
        setIsLoadingUi(false);
      }
    }
  };
  const setSelectedListInParent = (value) => {
    setSelectedList(value);
  };
  const onChangeAdd = (key) => {
    console.log(key);
  };

  const ExistingCategory = () => {
    const [form] = Form.useForm();
    const [addBrandOwners, setAddBrandOwners] = useState(true)
    const onFinish = async (brandOwnersToAdd) => {
      let lastRecordDataKbi = await getLastRecordKbi();
      console.log("selectedcategoryId", setSelectedcategoryIdData);
      let arr = [];
      let kbi = lastRecordDataKbi.kbi_id + 1;

      for (let i = 0; i < brandOwnersToAdd.brandOwners.length; i++) {
        const brand_owner = brandOwnersToAdd.brandOwners[i].first;

        UNITS.forEach(({ name, multiply_factor, type }) => {
          let obj = {
            brand_owners: `${brand_owner} ${type}`,
            category: selectedcategoryIdData.category,
            category_id: selectedcategoryIdData.category_id,
            country_id: selectedcategoryIdData.country_id,
  
            kbi_id: `KBIBCA${kbi}`,
            unit: name,
            multiply_factor,
  
            form_id: "formecomm",
          };
          kbi = kbi + 1;
          arr.push(obj);
        })
      }

      await submitData(arr);
      await loadCategoryBrandMapping();
      // console.log('Received values of form:', valueId);
    };

    const handleAddChange= (e) => {
      const allValues = form.getFieldsValue();
      if(selectedCountry && selectedCategory && allValues?.brandOwners?.length > 0 && allValues?.brandOwners?.[0] != undefined){
        setAddBrandOwners(false)
      }
      else{
        setAddBrandOwners(true)
      }
     
     }
    const handleChangeCountries = async (valueId) => {
      setSelectedCategory("");
      setSelectedcategoryIdData({});
      setSelectedCountry(valueId);

      const exists = originalData.filter(
        (item) => item.country_title == valueId
      );

      const uniquecategoriesN = await toFilterWith(exists, "category");
      setExistingDataSourceCategoriess(uniquecategoriesN);
    };

    const handleChangeCategory = async (valueId) => {
      //   setSelectedCountry(valueId);
      setSelectedCategory(valueId);

      const exists = originalData.filter(
        (item) =>
          item.country_title == selectedCountry && item.category == valueId
      );
      if (exists?.length > 0) {
        let brandOwners = [];
        for (let i = 0; i < exists.length; i++) {
          const element = exists[i];
          brandOwners.push(element?.brand_owners);
        }
        setSelectedcategoryIdData(exists[0]);
        setSelectedBrandOwners(brandOwners);
        // let brandOwners = originalData.filter(item => item.category_id == exists[0]?.category_id)
        // var filteredArray = brandOwners.filter(obj => obj.category.includes("NSR") && !obj.category.includes("Volume"));
      }
      //   const uniquecategoriesN = await toFilterWith(exists, "category");
      // setExistingDataSourceCategoriess(uniquecategoriesN)
    };
    return (
      <Row span={24} gutter={16}>
        <Col span={12}>
      
          <Row span={24}>Country</Row>
          <Row span={24}>
            <Select
              defaultValue="--select--"
              style={{
                width: "100%",
              }}
              value={selectedCountry}
              onChange={handleChangeCountries}
              options={existingDataSourceCountries}
            />
          </Row>
        </Col>
        <Col span={12}>
          {/* <p >sss</p> */}
          <Row span={24}>Category</Row>
          <Row span={24}>
            <Select
              defaultValue="--select--"
              style={{
                width: "100%",
              }}
              value={selectedCategory}
              onChange={handleChangeCategory}
              options={existingDataSourceCategories}
            />
          </Row>
        </Col>

        <Col span={24} style={{ paddingTop: "10px" }}>
          {/* <p >sss</p> */}
          Brand Owners
          <Form
            form={form}
            onValuesChange={handleAddChange}
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
          >
            {selectedBrandOwners &&
              selectedBrandOwners.map((val, index) => {
                return (
                  <>
                    <Row span={24}>
                      <Typography.Title level={5}>{val}</Typography.Title>
                    </Row>
                  </>
                );
              })}
            <Form.List name="brandOwners" style={{ paddingTop: "30px" }}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <>
                      <Row span={24} justify="space-around">
                        <Col span={22}>
                          <Form.Item
                            {...restField}
                            style={{ marginRight: "5px" }}
                            name={[name, "first"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing value",
                              },
                            ]}
                          >
                            <AutoComplete
                              dataSource={dataSourceBrandOwners}
                              placeholder="Brand Owner"
                              filterOption={(inputValue, option) =>
                                option.value
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }
                            >
                              <Input />
                            </AutoComplete>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Row
                            span={24}
                            justify="end"
                            style={{ paddingTop: "8px" }}
                          >
                            <MinusCircleOutlined
                              onClick={() => remove(name)}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Brand Owners
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Col span={24}>
              <Row span={24} justify="end">
                <Button type="secondary" onClick={handleCancelAdd}>
                  Cancel
                </Button>
                <Form.Item>
                  <Button disabled={addBrandOwners} type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </Form>
        </Col>
      </Row>
    );
  };

  async function getLastRecord() {
    let url =
      BASE_URL +
      INPUT_FORMS +
      EDGE_ECOMM +
      CATEGORY_BRAND_MAPPING +
      ADMIN +
      LAST_RECORD +
      `?selected_form=${props.formId}`;
    let response = await new APIService().get(url);
    let arr = [];

    if (response?.error) {
      console.log("error in kbivalues API", response.results.message);
    } else {
      if (response?.results?.length > 0) {
        let num = 0;
        for (let i = 0; i < response?.results?.length; i++) {
          const element = parseInt(
            response?.results[i]?.kbi_id.replace("KBIBCA", "")
          );
          if (element > num) {
            num = element;
          }
        }
        // var num = response?.results[0]?.kbi_id.replace('KBIBCA','');
        var category_id = response?.results[0]?.["category_id"];

        return { category_id: category_id };
      }
    }
  }

  async function getLastRecordKbi() {
    let url =
      BASE_URL +
      INPUT_FORMS +
      EDGE_ECOMM +
      CATEGORY_BRAND_MAPPING +
      ADMIN +
      LAST_RECORD +
      KBI_ID +
      `?selected_form=${props.formId}`;
    let response = await new APIService().get(url);
    let arr = [];

    if (response?.error) {
      console.log("error in kbivalues API", response.results.message);
      // return console.log("error in kbivalues API", response.results.message);
    } else {
      if (response?.results?.length > 0) {
        let num = 0;
        for (let i = 0; i < response?.results?.length; i++) {
          const element = parseInt(
            response?.results[i]?.kbi_id.replace("KBIBCA", "")
          );
          if (element > num) {
            num = element;
          }
        }
        return { kbi_id: parseInt(num) };
      }
    }
  }

  const generateResponse = async (data, country) => {
    let lastRecordDataCategory = await getLastRecord();

    let lastRecordDataKbi = await getLastRecordKbi();
    let arr = [];

    let objOne = {
      brand_owners: null,
      category: `${data.category} NSR`,
      category_id: lastRecordDataCategory.category_id + 1,
      country_id: country.value,
      // country_title:country.label,
      kbi_id: `KBIBCA${lastRecordDataKbi.kbi_id + 1}`,
      unit: "('000 EUR)",
      multiply_factor: 1000,
      // is_active:true,
      form_id: "formecomm",
    };

    let objTwo = {
      brand_owners: null,
      category: `${data.category} Volume`,
      category_id: lastRecordDataCategory.category_id + 1,
      country_id: country.value,
      // country_title:country.label,
      kbi_id: `KBIBCA${lastRecordDataKbi.kbi_id + 2}`,
      unit: "(UC '000)",
      multiply_factor: 1000,
      // is_active:true,
      form_id: "formecomm",
    };
    arr.push(objOne);
    arr.push(objTwo);

    var kbi = lastRecordDataKbi.kbi_id + 3;
    for (let i = 0; i < data.brand_owners.length; i++) {
      const element = data.brand_owners[i]?.["brand_owners"];
      UNITS.forEach(({ name, multiply_factor, type }) => {
        let obj = {
          brand_owners: `${element} ${type}`,
          category: data.category,
          category_id: lastRecordDataCategory.category_id + 1,
          country_id: country.value,
          // country_title:country.label,
          kbi_id: `KBIBCA${kbi}`,
          // unit: "%",
          // multiply_factor: 1,
          unit: name,
          multiply_factor,
          // is_active:true,
          form_id: "formecomm",
        };
        kbi = kbi + 1;
        arr.push(obj);
      })
    }

    return arr;
  };
  const success = () => {
    Modal.success({
      title: 'A new category has been added to the database.',
      content: (
        <div>
          <p style={{color:'#E61C2A', fontWeight:"bold"}}> When you add a New Category and Brand Owner, Don't forget to update the following link</p>
          <a target="_blank" href="https://cchellenic.sharepoint.com/:x:/r/sites/spaces-BSS-DropZone/Manual%20Submissions/ca/theedge_user_input/Commercial/Ecommerce/Beyond_the_Core_Assortment/BTCA_Target_Data.xlsx?d=w23e93e1e31c64a148f8df2c5f6581b6b&csf=1&web=1&e=1q3Z9c&OR=Teams-HL&CT=1687249538781&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMzA1MDEwMDQyMiIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D">BCTA Target Link</a>
        </div>
      ),
      onOk() {},
    });
    
  
  };

  const deleteRecords = () => {
    Modal.success({
      content: "The deletion of the chosen records was completed successfully",
    });
  };

  const submitData = async (data) => {
    let url =
      BASE_URL +
      INPUT_FORMS +
      EDGE_ECOMM +
      CATEGORY_BRAND_MAPPING +
      ADMIN +
      CREATE +
      `?selected_form=${props.formId}`;
    let response = await new APIService().post(url, data);
    if (response?.error) {
      setIsLoadingModalAdd(false);
      console.log("error in kbivalues API", response.results.message);
    } else {
      console.log("response", response);
      if (response?.status == 201) {
        setIsModalOpenAdd(false);
        setIsLoadingModalAdd(false);
        success();
      } else {
        setIsLoadingModalAdd(false);
      }
    }
  };
  const NewCategory = () => {
    const [form] = Form.useForm();
   const [addBrandOwners, setAddBrandOwners] = useState(true)
    const onFinish = async (values) => {
      setIsLoadingModalAdd(true);
      console.log("Received values of form:", values, selectedCountryNewModal);
      let countryList = countryListForModal.filter(
        (val) => val.value == selectedCountryNewModal
      );

      if (countryList.length > 0) {
        const data = await generateResponse(values, countryList[0]);
        await submitData(data);
        await loadCategoryBrandMapping();
      } else {
        setIsLoadingModalAdd(false);
      }
    };

    const handleChangeCountries = async (valueId) => {
      setSelectedCountryNewModal(valueId);
    };
   const handleAddChange= (e) => {
    const allValues = form.getFieldsValue();
    if(selectedCountryNewModal && allValues?.brand_owners?.length > 0 && allValues?.brand_owners?.[0] != undefined && allValues?.category){
      setAddBrandOwners(false)
    }
    else{
      setAddBrandOwners(true)
    }
   
   }

    return (
      <Spin
        tip="A new category is getting added to the database."
        spinning={isLoadingModalAdd}
        size="large"
      >
        <Form
        form={form}
        onValuesChange={handleAddChange}
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row span={24} gutter={[16, 0]}>
            <Col span={12}>
              <Row span={24}>Country</Row>
              <Row span={24}>
                <Select
                  defaultValue="--select--"
                  style={{
                    width: "100%",
                  }}
                  value={selectedCountryNewModal}
                  onChange={handleChangeCountries}
                  options={countryListForModal}
                />
              </Row>
            </Col>

            <Col span={12}>
              {/* <p >sss</p> */}
              <Row span={24}>Category</Row>
              <Row span={24}>
                <Form.Item
                  // label="Username"
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: "Please input the category!",
                    },
                  ]}
                >
                  <AutoComplete
                    style={{
                      width: 230,
                    }}
                    // onChange={(e) => setSelectedCategoryNewModal(e.target.value)}
                    options={dataSourceCategories}
                    placeholder="Category"
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  >
                    <Input />
                  </AutoComplete>
                </Form.Item>
              </Row>
            </Col>

            <Col span={24}>
              {/* <p >sss</p> */}
              Brand Owners
              <Form.List name="brand_owners">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <>
                        <Row span={24} justify="space-around">
                          <Col span={22}>
                            <Form.Item
                              {...restField}
                              style={{ marginRight: "5px" }}
                              name={[name, "brand_owners"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing value",
                                },
                              ]}
                            >
                              <AutoComplete
                                dataSource={dataSourceBrandOwners}
                                placeholder="Brand Onwer"
                                filterOption={(inputValue, option) =>
                                  option.value
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                                }
                              >
                                <Input  />
                              </AutoComplete>
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <Row
                              span={24}
                              justify="end"
                              style={{ paddingTop: "8px" }}
                            >
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Row>
                          </Col>
                        </Row>
                      </>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Brand Owners
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Col span={24}>
                <Row span={24} justify="end">
                  <Button type="secondary" onClick={handleCancelAdd}>
                    Cancel
                  </Button>
                  <Form.Item>
                    <Button disabled={addBrandOwners} type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Row>
              </Col>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  };

  const itemsAdd = [
    {
      key: "1",
      label: `Add New Category`,
      children: <NewCategory />,
    },
    {
      key: "2",
      label: `Add New Brands in Existing Category`,
      children: <ExistingCategory />,
    },
  ];

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you certain about deleting this Brand Owners?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // function handleDeleteData(){
  //   showDeleteConfirm()
  // }
  return (
    <>
      <Spin
        tip="Your data is being transferred for export."
        spinning={isLoadingExport}
        size="large"
      >
        <Row span={24}>
          <Col span={12}>
            <Row span={24} gutter={[16, 16]}>
              <Col span={8}>
                <DropDownCustomEretail
                  title="Country"
                  options={countryList}
                  setParentDropdownValue={setDropDownValues}
                />
              </Col>
              <Col span={8}>
                <DropDownCustomEretail
                  title="Category"
                  options={categoryList}
                  setParentDropdownValue={setDropDownValues}
                />
              </Col>
              <Col span={8}>
                <DropDownCustomEretail
                  title="Brand Owners"
                  options={brandOwnersList}
                  setParentDropdownValue={setDropDownValues}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row span={24} justify="end" style={{ paddingTop: "20px" }}>
              {/* <Col span={4}> ddd</Col> */}

              <input
              style={{borderRadius:"20px",  color: "#F7F7F7", textDecoration:"none", color:"#000", width:"100px"}}
                // className="brand-category-export-button-w"
                type="button"
                value="Export"
                onClick={() => exportDataHandle()}
                // onClick={exportDataHandle}
              />
              <CSVLink
                style={{ textDecoration: "none", display: "none" }}
                filename={nameOfExportedFile}
                headers={headers}
                data={exportData}
                ref={csvLink}
                target="_blank"
              >
                <Button
                  // style={{ backgroundColor: "#E61C2A", color: "#F7F7F7" }}
                  // icon={<CaretDownFilled />}
                >
                  Export
                </Button>
              </CSVLink>
              {selectedList.length === 0 ? (
                <Button
                  onClick={handleAdd}
                  style={{
                    marginLeft: "10px",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                    borderRadius: "20px",
                    backgroundColor: "#E61C2A",
                    color: "#F7F7F7",
                    // marginTop: "15px",
                  }}
                  type="primary"
                >
                  Add
                </Button>
              ) : (
                <Button
                  onClick={showDeleteConfirm}
                  style={{
                    marginLeft: "10px",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                    borderRadius: "20px",
                    backgroundColor: "#E61C2A",
                    color: "#F7F7F7",
                    // marginTop: "15px",
                  }}
                  type="primary"
                >
                  Delete
                </Button>
              )}
            </Row>
          </Col>
        </Row>

        <Row span={24} style={{ marginTop: "20px" }}>
          <div className="container table-container row p-2">
            <Spin
              tip="Your data is Loading."
              spinning={isLoadingUi}
              size="large"
            >
              {filteredData?.length > 0 ? (
                <div className="brand-category-table-container-new">
                  <TableBeyondCoreAssortment
                    columns={columns}
                    data={filteredData}
                    setSelectedListInParent={setSelectedListInParent}
                  />
                </div>
              ) : (
                ""
              )}
            </Spin>
          </div>
        </Row>
      </Spin>
      <Modal
        //  title="Add Category"
        open={isModalOpenAdd}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
        footer={null}
      >
        <Tabs defaultActiveKey="1" items={itemsAdd} onChange={onChangeAdd} />
      </Modal>

      {/* <Button onClick={success}></Button> */}

      <Modal
        title="Edit Brand Owner"
        open={isModalOpenEdit}
        onOk={handleOkEdit}
        onCancel={handleCancelEdit}
        footer={null}
      >
        <Spin tip="" spinning={isLoadingModalEdit} size="large">
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={onFinishEdit}
            autoComplete="off"
          >
            <Row span={24} gutter={[16, 12]}>
              <Col span={12}>
                <Row span={24}>
                  <Typography.Title level={5}>Country</Typography.Title>
                </Row>
                <Row span={24}>{editableData?.country_title}</Row>
              </Col>

              <Col span={12}>
                <Row span={24}>
                  <Typography.Title level={5}>Category</Typography.Title>
                </Row>
                <Row span={24}>{editableData?.category}</Row>
              </Col>
              <Col span={12}>
                <Row span={24}>
                  <Typography.Title level={5}>Added By</Typography.Title>
                </Row>
                <Row span={24}>{editableData?.added_by}</Row>
              </Col>

              <Col span={12}>
                <Row span={24}>
                  <Typography.Title level={5}>Added At</Typography.Title>
                </Row>
                <Row span={24}>{editableData?.added_at}</Row>
              </Col>

              <Col span={24}>
                <Row span={24}>
                  <Typography.Title level={5}>Brand Owner</Typography.Title>
                </Row>
                <Row span={24}>
                  <Form.Item
                    // label="Brand Owner"
                    name="brandOwner"
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input a value!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Row>
              </Col>

              <Col span={24}>
                <Row span={24} justify="end">
                  <Button type="secondary" onClick={handleOkEdit}>
                    Cancel
                  </Button>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

const mapStateProps = (state) => {
  return {
    formId: state.sidebar.formId,
  };
};

export default connect(mapStateProps)(CategoryBrandMapping);

//test
