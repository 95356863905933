import { createStore, applyMiddleware, compose } from "redux";
import { persistStore } from "redux-persist";
import persistReducer from "./root-reducer";
import thunk from "redux-thunk";

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsDenylist, actionsCreators, serialize...
      })
    : compose;

    const enhancer = composeEnhancers(
        applyMiddleware(thunk),
        // other store enhancers if any
      );

export default () => {
    let store = createStore(persistReducer, enhancer)
    let persistor = persistStore(store)
    return { store, persistor };
}