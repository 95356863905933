import { Spin, Table, Button, message } from "antd";
import React, { FC, Suspense, useEffect, useState } from "react";
// import { v4 as uuidv4 } from 'uuid';
import MultipleSelectCheckmarks from "../../components/dropDownMultiSelectCustom";
import { useParams } from 'react-router-dom'
import { IActiveCustomer, ICountry, ICustomerStatus } from "../../reducer/ECommCustomers/_interfaces";
import { useDispatch, useSelector } from "react-redux";
import { addUpdatedRow, clearError, clearSuccess, loadCountries, loadData, loadDataForApproval, resetUpdateRows, updateData } from "../../actions/eComm/eCommCustomersActions";
import { DEFAULT_COLUMNS } from "./constants";
import EditableCell from "./EditableCell";
import { SET_ERROR, SET_SUCCESS } from "../../reducer/ECommCustomers/constants";
import moment from "moment";

const { v4 } = require("uuid");

interface ParamTypes {
    id: string;
}

const getNewItem = (id:string) => ({
	id,
	country: '',
	country_code: '',
	customer_name: '',
	e_customer_type: '',
	sap_code: '',
	priority: '',
	pfp_implementation: '',
	pfp_implemented: '',
	sellout_sharing: '',
	sellout_ingested: '',
	estimation_list: '',
	input_one: '',
	input_two: '',
	ered_eretail: '',
	ered_fsa: '',
	ered_qc: '',
	ered_qsr: '',
	market_shares: '',
	customer_url: '',
	status: 'created' as ICustomerStatus,
	created_at: '',
	created_by: JSON.parse(localStorage.getItem('access_token') || '{}').user.id || ''
})

const ECommerceCustomersPage:FC = () => {
	const [messageApi, contextHolder] = message.useMessage();
    const { id } = useParams<ParamTypes>();
    const dispatch = useDispatch();
    
    const [selectedCountries, setSelectedCountries] = useState<ICountry[]>([]);

    const countries = useSelector((state:any) => state.eCommCustomers.countries);
    const countriesLoading = useSelector((state:any) => state.eCommCustomers.countriesLoading);
    
    const dataLoading = useSelector((state:any) => state.eCommCustomers.dataLoading);
    const data = useSelector((state:any) => state.eCommCustomers.data);
    const updatedRows = useSelector((state:any) => state.eCommCustomers.updatedRows);
    const success = useSelector((state:any) => state.eCommCustomers.success);
    const error = useSelector((state:any) => state.eCommCustomers.error);

	const handleSave = (row:IActiveCustomer) => {
		const newData = [...data];
		const index = newData.findIndex((item) => row.id === item.id);
		const item = newData[index];
		newData.splice(index, 1, {
		  ...item,
		  ...row,
		});
		dispatch(addUpdatedRow({...row, status: row.status === 'created' ? 'created' : 'updated'}, updatedRows, newData));
	};

	const handleAdd = () => {
		const newRow = getNewItem(v4())
		dispatch(addUpdatedRow(newRow, updatedRows, [...data, newRow]))
	};

	const saveChanges = () => {
		dispatch(updateData(id, updatedRows));
	}

	const tableColumns = DEFAULT_COLUMNS.map((col:any) => {
		if (!col.editable) {
		  	return col;
		}
		return {
			...col,
			onCell: (record:any) => ({
				record,
				inputType: col.dataIndex === 'age' ? 'number' : 'text',
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave,
			}),
		};
	})

    const initialDataLoad = async () => {
        dispatch(loadCountries(id));
    }


	useEffect(() => {
		let params = {}
		if (selectedCountries.length > 0) {
			params = {
				country_id: selectedCountries.map(({ value }) => value).join(',')
			}
		}
		dispatch(loadData(id, 1, params));
	}, [selectedCountries])

    useEffect(() => {
        initialDataLoad();
		return () => { dispatch(resetUpdateRows()) }
    }, []);

	useEffect(() => {
		success && messageApi.open({
			type: 'success',
			content: success,
			style: {zIndex: 10000},
			onClose: () => dispatch(clearSuccess())
		});
	}, [success]);

	useEffect(() => {
		error && messageApi.open({
			type: 'error',
			content: error,
			style: {zIndex: 100000},
			onClose: () => {
				dispatch(clearError())
			}
		});
	}, [error]);

    const setDropDownValues =async (objs:ICountry[], valueType:string) => {
        if (valueType === "Country") setSelectedCountries(objs);
    };

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};

    return (
        <Suspense fallback={<div>An error occured!</div>}>
			{contextHolder}
            <div className="user-view-container">
                <div className="user-view-header row p-0 mb-4 ms-4 mr-4">
                    <div className="col-9 p-0 m-0 row">
                        <div className="col-5 p-0 m-0">
                            <Spin spinning={countriesLoading} size="default">
                                <MultipleSelectCheckmarks
                                    title="Country"
                                    options={countries}
                                    setParentDropdownValue={setDropDownValues}
                                />
                            </Spin>
                        </div>
                        <div className="col-3 p-0 m-0 my-auto user-view-added-at-container d-flex align-items-center">
                            <h3 className="user-view-text-header">Last Updated At:</h3>
                            <h3 className="user-view-text-value">asdasdsad</h3>
                        </div>

                        <div className="col-3 p-0 m-0 my-auto user-view-added-at-container d-flex align-items-center">
                            <h3 className="user-view-text-header">Last Submited By:</h3>
                            <h3 className="user-view-text-value">asdasdsad</h3>
                        </div>
                    </div>

                    {/* <div className="col-2 p-0 m-0 my-auto"></div> */}

                    <div className="col-3 p-0 m-0 d-flex align-items-center justify-content-end">
						<Button
							onClick={handleAdd}
							type="primary"
						>
							Add a row
						</Button>
						<Button
							onClick={handleAdd}
							type="primary"
							className="ms-1 brand-background"
						>
							Add column
						</Button>
						<Button
							onClick={saveChanges}
							type="primary"
							className="ms-1 brand-background"
						>
							Save
						</Button>
                    </div>

                </div>
                <Spin tip="Loading" spinning={dataLoading} size="large">
                    {/* MAIN TABLE */}
                    <Table
                        columns={tableColumns}
                        size="small"
                        dataSource={data}
						pagination={{
							pageSize: 100
						}}
						components={components}
						rowClassName={() => 'editable-row'}
						bordered
						scroll={{
							x: 2620,
							y: 570
						}}
                    />
                </Spin>
            </div>
        </Suspense>
    );
}

export default ECommerceCustomersPage;

const EditableRow = ({ index, ...props }:any) => {
	return (
		<tr {...props} />
	);
};