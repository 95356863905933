import React, { useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import "../App.css"
import { NavLink as Link, Switch, Route, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { GetNavLinks } from "../actions/sidebar_action";
import AdminRoute from './admin/adminRoute';
import ConsolidatedRoute from './consolidated/consolidatedRoute';
import UserRoute from './user/userRoute';
import RequestStatusRoute from './requestStatus/requestStatusRoute';
import roadmapRoute from './roadmapTarget/roadmapRoute';
import { toGetComp, getExternalLink } from '../constant/constants';
import { useMsal } from "@azure/msal-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { getNavLinkIcon } from "../constant/constants";
import { getInfoDetails } from "../constant/constants";
import InfoDialogueBox from "../components/infoDialogueBox"
import CategoryBrandMapping from './formecomm/CategoryBrandMapping';

import ECommerceCustomersPage from './eCommCustomers/ECommerceCustomersPage';
import ECommerceCustomersApprovalPage from './eCommCustomers/ECommerceCustomrsApprovalPage';
import ERetailBPPage from './eRetailBP/ERetailBPPage';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const Home = (props) => {
    const { instance } = useMsal();
    const history = useHistory();
    const location = useLocation();

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const [adminIds, setAdminIds] = React.useState(localStorage.getItem('access_token') ? JSON.parse(localStorage.getItem('access_token')).user.form_admin_list : []);
    const [formList, setFormList] = React.useState(props.forms)
    const [isInfoDialogueBoxOpen, setIsInfoDialogueBoxOpen] = React.useState(false)

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    //tp perform side bar functionality
    const toPerformSidebar = (formId) => {
        props.getNavLink(formId, adminIds)
        const currentLocation = location.pathname.split("/").filter(n => n)
        const getPath = getCurrentPath(currentLocation, true, formId)
        if (getPath) {
            history.push(`${getPath}`)
        }
        handleDrawerClose(); //Perform Sidebar Close
    }

    // To Build A Path Redirect To The Page
    const getCurrentPath = (path, fromSidebar, formId) => {
        if (fromSidebar) {
            if (props.navLinks.some(e => e.id === path[1])) {
                return `/${formId}/${toGetComp(path[0]).constants[0].id}`
            } else {
                return `/${formId}/${toGetComp(path[0]).constants[0].id}`
            }
        } else {
            if (props.navLinks.some(e => e.id === path[1])) {
                return `/${path[0]}/${path[1]}`
            } else {
                return `/${path[0]}/user`
            }
        }
    }

    const getExternalRedirectLinkByForm = () => {
        return getExternalLink(props.formId)
    }

    const handleInfoDialogueBox = () => {
        setIsInfoDialogueBoxOpen(!isInfoDialogueBoxOpen)
    }

    const handleLogout = (instance) => {
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
        localStorage.removeItem("access_token")
    }

    useEffect(() => {
        let newFormList
        if (props.steward === true) {
            newFormList = [...formList, { form_id: 'sustainability', title: 'Sustainability Manual Input Form' }]
            setFormList(newFormList)
        } else {
            newFormList = formList
        }

        const getPath = location.pathname.split("/").filter(n => n)

        if (getPath.length) {
            props.getNavLink(getPath[0], adminIds);
            const getPath1 = getCurrentPath(getPath, false, getPath[0]);

            if (getPath1) {
                history.push(`${getPath1}`)
            }
        } else {
            // To replace 'form1' to first form id from api
            if (formList.length !== 0) {
                props.getNavLink(newFormList[0].form_id, adminIds)
                history.push(`/${newFormList[0].form_id}/${props.navLinks[0]}`)
                history.push(`/${newFormList[0].form_id}/user`)
            }
        }
    }, [])

    return (
        <>
            <Box id='box-container' className="box-container" sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar className="landing-header" position="fixed">
                    <Toolbar >
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className="navbar-company-icon">
                            <img src="/CCH-LOGO-IMAGE.png" />
                        </div>
                        <div className="navlink-container">
                            {
                                props.navLinks ?
                                    props.navLinks.map((ele, i) => {

                                        return (
                                            <Link className={'navLinks'} key={ele.id} to={`/${props.formId}/${ele.id}`} activeClassName="navlink-active">
                                                {getNavLinkIcon(ele.id)}
                                                <p className="navlink-text">{ele.title}</p>
                                            </Link>
                                        )
                                    }) : null
                            }
                        </div>
                        <div className='navbar-logout-container'>
                            <div className="navbar-right-section-icon ">
                                <a target="_blank"
                                    rel="noreferrer"
                                    href={getExternalRedirectLinkByForm() ? getExternalRedirectLinkByForm().link : ""}
                                    title={getExternalRedirectLinkByForm() ? getExternalRedirectLinkByForm().title : ""}
                                >
                                    <img src="/external_link.ico" />
                                </a>
                            </div>
                            {/* Info Button */}
                            <div className="navbar-right-section-icon" title="Info" >
                                <FontAwesomeIcon icon={faInfo} onClick={handleInfoDialogueBox}
                                    style={{ pointerEvents: getInfoDetails(props.formId).enable ? "" : "none" }} />
                            </div>
                            {/* Info Button */}
                            <div className="navbar-right-section-icon" title="Logout" >
                                <FontAwesomeIcon icon={faSignOutAlt} onClick={() => handleLogout(instance)} />
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}>
                    <DrawerHeader >
                        <h3 className="sidebar-header-title">Forms</h3>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider className="sidebar-divider" />
                    {
                        formList.map((ele, i) => {
                            return (
                                <div
                                    className={"sidebar"}
                                    key={ele.form_id}
                                    style={{ padding: 20, cursor: 'pointer', backgroundColor: props.formId === ele.form_id ? "#E61C2A" : '', color: props.formId === ele.form_id ? "#ffffff" : '' }}
                                    onClick={() => toPerformSidebar(ele.form_id)}
                                >
                                    <Typography>
                                        {ele.title}
                                    </Typography>
                                </div>
                            )
                        })
                    }
                </Drawer>
            </Box>
            <div className={window.location.pathname.includes('sustainability') ? "body-container-sustain" : "body-container"}>
                <Switch>
                    <Route path="/:id/approval" component={AdminRoute} />
                    <Route path="/:id/consolidated" component={ConsolidatedRoute} />
                    <Route path="/:id/user" component={UserRoute} />
                    <Route path="/:id/request-status" component={RequestStatusRoute} />
                    <Route path="/:id/emission_2030_targets" component={roadmapRoute} />
                    <Route path="/:id/categoryBrandMapping" component={CategoryBrandMapping} />
                    <Route path="/:id/ecomcust" component={ECommerceCustomersPage} />
                    <Route path="/:id/ecomcustadmin" component={ECommerceCustomersApprovalPage} />
                    <Route path="/:id/eretailbp" component={ERetailBPPage} />
                </Switch>
            </div >
            {/* Info Dialogue Box */}
            {isInfoDialogueBoxOpen &&
                <InfoDialogueBox handleInfoDialogueBox={handleInfoDialogueBox} isInfoDialogueBoxOpen={isInfoDialogueBoxOpen}
                    data={getInfoDetails(props.formId)} formId={props.formId} />}
            {/* Info Dialogue Box */}
        </>
    );
}

const mapStateProps = (state) => {
    return {
        navLinks: state.sidebar.navLink,
        formId: state.sidebar.formId,
        path: state.sidebar.path
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNavLink: (formId, adminIds) => dispatch(GetNavLinks(formId, adminIds))
    }
}

export default connect(mapStateProps, mapDispatchToProps)(Home);