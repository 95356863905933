import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Admin1 from './admin1';
import Admin2 from './admin2';
import Admin3 from './admin3';

const AdminRoute = (props) => {
    const [adminIds, setAdminIds] = React.useState(localStorage.getItem('access_token') ? JSON.parse(localStorage.getItem('access_token')).user.form_admin_list : []);

    const loadComponent = (formId) => {
        switch (formId) {
            case 'formecomm':
                return <Admin1 formId={formId} />
            case 'formsku':
                return <Admin2 formId={formId} />
            case 'formmap':
                return <Admin3 formId={formId} adminIds={adminIds} />;
            default:
                return;
        }
    }

    return (
        <>
            
            {
                loadComponent(props.formId)
            }
        </>
    );
};

const mapStateProps = (state) => {
    return {
        formId: state.sidebar.formId,
        path: state.sidebar.path
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateProps, mapDispatchToProps)(AdminRoute);