export const COUNTRY = 'country';
export const COUNTRY_CODE = 'country_code';
export const PRIORITY = 'priority';

export const DEFAULT_COLUMNS = [
    {
		dataIndex: COUNTRY,
		title: "Country",
		width: '8%',
		editable: true,
		fixed: 'left',
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: "customer_name",
		title: "Customer Name",
		width: '16%',
		editable: true,
		fixed: 'left',
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: "e_customer_type",
		title: "Type of eCustomer",
		width: '8%',
		editable: true,
		fixed: 'left',
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: "sap_code",
		title: "CPL/Customer SAP code",
		width: '8%',
		editable: true,
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: PRIORITY,
		title: "Priority",
		width: '8%',
		editable: true,
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: "pfp_implementation",
		title: "PfP implementation",
		width: '12%',
		editable: true,
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: "pfp_implemented",
		title: " PfP implemented",
		width: '12%',
		editable: true,
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: "sellout_sharing",
		title: "Sell Out Data Sharing",
		width: '8%',
		editable: true,
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: "sellout_ingested",
		title: "Sell Out Data Ingested",
		width: '8%',
		editable: true,
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: "estimation_list",
		title: "Data for reporting estimation list",
		width: '16%',
		editable: true,
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: "input_one",
		title: "Input 1",
		width: '6%',
		editable: true,
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: "input_two",
		title: "Input 2",
		width: '6%',
		editable: true,
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
      	dataIndex: "ered_eretail",
		title: "eRed for eRetail",
		width: '8%',
		editable: true,
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: "ered_fsa",
		title: "eRed for FSA",
		width: '8%',
		editable: true,
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: "ered_qc",
		title: "eRed for QC",
		width: '8%',
		editable: true,
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: "ered_qsr",
		title: "eRed for QSR",
		width: '8%',
		editable: true,
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: "market_shares",
		title: "Market shares data sharing",
		width: '8%',
		editable: true,
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
    {
		dataIndex: "customer_url",
		title: "Customer URL",
		width: '18%',
		editable: true,
        // render: () => (<a>action</a>)
    //   ...getFilterProps("material_id", filterData("material_id")),
    },
];


export const PRIORITY_DROPDOWN = [
    {
        value: 'Low',
        label: 'Low'
    },
    {
        value: 'Medium',
        label: 'Medium'
    },
    {
        value: 'High',
        label: 'High'
    }
]


export const DROPDOWN_COLUMNS = [
    COUNTRY,
    PRIORITY,
]