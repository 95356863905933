import { IState } from "./_interfaces";
import {
    SET_MESSAGE,
    SET_COUNTRIES,
    SET_COUNTRIES_LOADING,

    SET_PERIODS,
    SET_PERIODS_LOADING,

    SET_KBI_MASTER,
    SET_KBI_MASTER_UNEDITED,
    SET_KBI_MASTER_LOADING,
    SET_BEYOND_CORE_RAW_TABLE,
    SET_BEYOND_CORE_RAW_TABLE_LOADING,

    SET_BEYOND_CORE_MASTER_LIST,
    SET_BEYOND_CORE_MASTER_LIST_LOADING,
    SET_BEYOND_CORE_KBI_INPUTS,
    SET_BEYOND_CORE_KBI_INPUTS_PREV_PERIOD,
    SET_KBI_COMMENT,
    SET_LAST_EDITED_BY,
    SET_KBI_INPUT_VALUES_LOADING,
    SET_KBI_INPUT_VALUES,
    SET_KBI_INPUT_VALUES_PREV_PERIOD,
    SET_BEYOND_CORE_KBIS,
    RESET_DATA_E_RETAIL,
    UPLOADING_BP_DATA,
    SET_BP_DATA,
    SET_BEYOND_CORE_KBIS_LOADING
} from "./constants";


const initialState:IState = {
    message: null,

    countriesLoading: false,
    countries: [],

    periods: [],
    periodsLoading: false,

    kbiMasterLoading: false,
    kbiMaster: [],
    kbiMasterUnedited: {},
    
    beyondRawTable: [],
    beyondRawTableLoading: false,
    
    categoryBrandMasterList: {},
    beyondCoreLoading: false,
    beyondCoreKBIInputs: {},
    beyondCoreKBIInputsPrevPeriod: {},

    comment: '',
    kbiValuesLoading: false,
    kbiValues: {},
    kbiValuesPrevPeriod: {},
    lastUpdate: {
        lastUpdatedAt: '-',
        lastAddedBy: '-',
    },
    beyondCoreKBIs: {},
    beyondCoreKBIsLoading: false, 
    uploading: false,
    businessPlan: null,
}

const ECommRetailsReducer = (state:IState = initialState, action:any):IState => {
    const { type, payload } = action;
    
    switch (type) {
        case SET_MESSAGE:
            return {
                ...state,
                message: payload,
            }

        case SET_COUNTRIES_LOADING:
            return {
                ...state,
                countriesLoading: payload
            }
        case SET_COUNTRIES:
            return {
                ...state,
                countries: payload,
            }
            
        case SET_PERIODS_LOADING:
            return {
                ...state,
                periodsLoading: payload
            }
        case SET_PERIODS:
            return {
                ...state,
                periods: payload,
            }
        
        case SET_KBI_MASTER_LOADING:
            return {
                ...state,
                kbiMasterLoading: payload,
            }
        case SET_KBI_MASTER:
            return {
                ...state,
                kbiMaster: payload,
            }
        case SET_KBI_MASTER_UNEDITED:
            return {
                ...state,
                kbiMasterUnedited: payload,
            }

        case SET_BEYOND_CORE_RAW_TABLE:
            return {
                ...state,
                beyondRawTable: payload,
            }
        case SET_BEYOND_CORE_RAW_TABLE_LOADING:
            return {
                ...state,
                beyondRawTableLoading: payload,
            }

            
        case SET_BEYOND_CORE_MASTER_LIST:
            return {
                ...state,
                categoryBrandMasterList: payload,
            }
        case SET_BEYOND_CORE_MASTER_LIST_LOADING:
            return {
                ...state,
                beyondCoreLoading: payload,
            }
        case SET_BEYOND_CORE_KBI_INPUTS:
            return {
                ...state,
                beyondCoreKBIInputs: payload,
            }
        case SET_BEYOND_CORE_KBI_INPUTS_PREV_PERIOD:
            return {
                ...state,
                beyondCoreKBIInputsPrevPeriod: payload,
            }

        case SET_KBI_COMMENT:
            return {
                ...state,
                comment: payload
            }
        case SET_LAST_EDITED_BY:
            return {
                ...state,
                lastUpdate: payload,
            }
            
        case SET_KBI_INPUT_VALUES:
            return {
                ...state,
                kbiValues: payload,
            }
        case SET_KBI_INPUT_VALUES_PREV_PERIOD:
            return {
                ...state,
                kbiValuesPrevPeriod: payload,
            }
        case SET_KBI_INPUT_VALUES_LOADING:
            return {
                ...state,
                kbiValuesLoading: payload,
            }
        case SET_BEYOND_CORE_KBIS:
            return {
                ...state,
                beyondCoreKBIs: payload,
            }
        case SET_BEYOND_CORE_KBIS_LOADING:
            return {
                ...state,
                beyondCoreKBIsLoading: payload,
            }
        case UPLOADING_BP_DATA:
            return {
                ...state,
                uploading: payload,
            }
        case SET_BP_DATA:
            return {
                ...state,
                businessPlan: payload,
            }


        case RESET_DATA_E_RETAIL:
            return initialState;

    }

    return state;
}


export default ECommRetailsReducer