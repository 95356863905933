import { Button, Col, Row, Spin, Tabs } from "antd";
import { FormControl } from "@mui/material";
import React, { FC, Suspense, useEffect, useRef, useState } from "react";
import * as actions from "../../actions/eComm/eCommRetailFSAActions";
import * as selectors from "../../reducer/ECommRetail/selectors";
import { IBeyondCoreData, IBeyondCoreKBI, IKBI, INSR_VolumeData } from "../../reducer/ECommRetail/_interfaces";
import { useDispatch, useSelector } from "react-redux";
import { bpUtils } from "../user/ERetailsFSAPage/utils";
import PreviewTable from "./components/Table";
import { BEYOND_CORE_COLUMNS, NSR_COLUMNS, VOLUME_COLUMNS } from "./constants";

const ERetailBPPage:FC = () => {
    const dispatch = useDispatch();
    
    const fileNSRBPInput = useRef<HTMLInputElement & typeof FormControl>(null);
    const fileBCBPInput = useRef<HTMLInputElement & typeof FormControl>(null);

    const kbiMaster:IKBI[] = useSelector(selectors.kbiMaster);
    const beyondCoreKBIs:IBeyondCoreKBI = useSelector(selectors.beyondCoreKBIs);
    const beyondCoreKBIsLoading:boolean = useSelector(selectors.beyondCoreKBIsLoading);

    const uploading:boolean = useSelector(selectors.uploading);

    const [ nsrFileName, setNsrFileNama ] = useState<string>('');
    const [ bcName, setBcFileNama ] = useState<string>('');

    const [ volumeData, setVolumeData ] = useState<INSR_VolumeData[]>([]);
    const [ volumeDataColumns, seVolumeDataolumns ] = useState(VOLUME_COLUMNS);

    const [ nsrData, setNsrData ] = useState<INSR_VolumeData[]>([]);
    const [ nsrDataColumns, seNSRDataolumns ] = useState(NSR_COLUMNS);

    const [ bcData, setBCData ] = useState<IBeyondCoreData[]>([]);
    const [ bcDataColumns, setBCDataColumns ] = useState(BEYOND_CORE_COLUMNS);

    useEffect(() => {
        dispatch(actions.setUploading(false));
        if (kbiMaster.length === 0) {
            dispatch(actions.loadKBIMaster('formecomm'));
        }

        if (Object.keys(beyondCoreKBIs).length === 0) {
            dispatch(actions.loadBeyondCoreMap());
        }
    }, []);

    useEffect(() => {
        const countrySet = new Set();
        const kbiNameSet = new Set();
        const yearsSet = new Set();

        nsrData.forEach((d:any) => {
            countrySet.add(d.country);
            kbiNameSet.add(d.kbi_name);
            yearsSet.add(d.year);
        })

        seNSRDataolumns(NSR_COLUMNS.map((column) => {
            let newColumn = {
                ...column
            }
            if (column.dataIndex === 'year') {
                newColumn.filters = Array.from(yearsSet).map((c:any) => ({ 
                    text: c as string,
                    value: c
                 }));
            }
            if (column.dataIndex === 'country') {
                newColumn.filters = Array.from(countrySet).map((c) => ({ 
                    text: c as string,
                    value: c as string
                 }));
            }
            if (column.dataIndex === 'kbi_name') {
                newColumn.filters = Array.from(kbiNameSet).map((c) => ({ 
                    text: c as string,
                    value: c as string
                 }));
            }
            return newColumn
        }))
    }, [nsrData]);

    useEffect(() => {
        const countrySet = new Set();
        const kbiNameSet = new Set();
        const yearsSet = new Set();

        volumeData.forEach((d:any) => {
            countrySet.add(d.country);
            kbiNameSet.add(d.kbi_name);
            yearsSet.add(d.year);
        })

        seVolumeDataolumns(VOLUME_COLUMNS.map((column) => {
            let newColumn = {
                ...column
            }
            if (column.dataIndex === 'year') {
                newColumn.filters = Array.from(yearsSet).map((c:any) => ({ 
                    text: c as string,
                    value: c
                 }));
            }
            if (column.dataIndex === 'country') {
                newColumn.filters = Array.from(countrySet).map((c) => ({ 
                    text: c as string,
                    value: c as string
                 }));
            }
            if (column.dataIndex === 'kbi_name') {
                newColumn.filters = Array.from(kbiNameSet).map((c) => ({ 
                    text: c as string,
                    value: c as string
                 }));
            }
            return newColumn
        }))
    }, [volumeData]);

    useEffect(() => {
        const countrySet = new Set();
        const yearsSet = new Set();
        const brandOwnersSet = new Set();

        bcData.forEach((d:any) => {
            countrySet.add(d.country_name);
            yearsSet.add(d.year);
            brandOwnersSet.add(d.brand_owners);
        })

        setBCDataColumns(BEYOND_CORE_COLUMNS.map((column) => {
            let newColumn = {
                ...column
            }
            if (column.dataIndex === 'year') {
                newColumn.filters = Array.from(yearsSet).map((c:any) => ({ 
                    text: c as string,
                    value: c
                 }));
            }
            if (column.dataIndex === 'country_name') {
                newColumn.filters = Array.from(countrySet).map((c) => ({ 
                    text: c as string,
                    value: c as string
                 }));
            }
            if (column.dataIndex === 'brand_owners') {
                newColumn.filters = Array.from(brandOwnersSet).map((c) => ({ 
                    text: c as string,
                    value: c as string
                 }));
            }
            return newColumn
        }))
    }, [bcData]);

    const setNSRVolumeData = async (e:any) => {
        const file:File = e.currentTarget.files[0];
        if (file) {
            await bpUtils.readNSRTable(file, kbiMaster).then((bp) => {
                setNsrData(bp.nsrBP);
                setVolumeData(bp.volumeBP);
                setNsrFileNama(file.name);
            });
        }
    }

    const setBeyondCoreData = async (e:any) => {
        const file:File = e.currentTarget.files[0];
        if (file) {
            await bpUtils.readBCTable(file, beyondCoreKBIs).then((bp) => {
                setBCData(bp);
                setBcFileNama(file.name)
            });
        }
    }

    const uploadData = () => {
        dispatch(actions.uploadBP(nsrData, volumeData, bcData))
        // if (nsrData.length > 0) dispatch(actions.uploadNSRBP(nsrData.filter((n) => n.kbi !== "totalNSR")));
        // if (volumeData.length > 0) dispatch(actions.uploadVolumeBP(volumeData.filter((n) => n.kbi !== "totalVolume")));
        // if (bcData.length > 0) dispatch(actions.uploadBCBP(bcData));
    }
    
    return (
        <Suspense fallback={<div>An error occured!</div>}>
            <Spin
                tip={
                    uploading
                        ? "Uploading BP"
                        : beyondCoreKBIsLoading
                            ? "Loading KBI master data"
                            : "Loading"
                    } 
                spinning={uploading || beyondCoreKBIsLoading}
                size="large"
            >
                <div className="user-view-container">
                    <div className="user-view-header row p-0 m-0">
                        <Row className="pt-4">
                            <Col span={4}>
                                <Button
                                    onClick={() => {
                                        fileNSRBPInput.current?.click();
                                    }}
                                >
                                    Choose NSR/Volume BP per channel
                                </Button>
                                <label>{nsrFileName}</label>

                                <input
                                    ref={fileNSRBPInput}
                                    id="for-nsr"
                                    style={{display: 'none'}}
                                    type="file"
                                    accept=".xlsx"
                                    onChange={setNSRVolumeData}
                                />
                            </Col>
                            <Col span={4}>
                                <Button
                                    onClick={() => {
                                        fileBCBPInput.current?.click();
                                    }}>
                                    Choose NSR/UC per category/brand-owner
                                </Button>
                                <label>{bcName}</label>

                                <input
                                    ref={fileBCBPInput}
                                    id="for-bc"
                                    style={{display: 'none'}}
                                    type="file"
                                    accept=".xlsx"
                                    onChange={setBeyondCoreData}
                                />
                            </Col>
                            <Col span={16} className="d-flex justify-content-end">
                                <Button
                                    onClick={uploadData}
                                    type="primary"
                                    className="ms-1 brand-background"
                                >
                                    Upload to User View
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col span={24}>
                            <Tabs
                                type="card"
                                defaultActiveKey="1"
                                items={[
                                    {
                                        label: 'NSR per channel',
                                        key: '1',
                                        children: (
                                            <PreviewTable
                                                data={nsrData}
                                                tableColumns={nsrDataColumns}
                                            />
                                        )
                                    }, {
                                        label: 'Volume per channel',
                                        key: '2',
                                        children: (
                                            <PreviewTable
                                                data={volumeData}
                                                tableColumns={volumeDataColumns}
                                            />
                                        )
                                    }, {
                                        label: 'NSR/Volume per category/brand-owner',
                                        key: '3',
                                        children: (
                                            <PreviewTable
                                                data={bcData}
                                                tableColumns={bcDataColumns}
                                            />
                                        )
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                </div>
            </Spin>
        </Suspense>
    )
}

export default ERetailBPPage;