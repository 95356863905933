import moment from "moment";
import { ACCOUNTS, ACTIVE_CUSTOMERS, BASE_URL, COUNTRIES, EDGE_ECOMM, INPUT_FORMS, USERS } from "../../constant/apiEndpoints";
import { IActiveCustomer } from "../../reducer/ECommCustomers/_interfaces";
import { SET_COUNTRIES, SET_COUNTRIES_LOADING, SET_DATA, SET_DATA_FOR_APPROVAL, SET_DATA_LOADING, SET_ERROR, SET_SUCCESS, SET_UPDATED_ROWS } from "../../reducer/ECommCustomers/constants"
import { IAPIReturn } from "../../services/_interfaces";
import APIService from "../../services/APIService";


export const loadCountries = (formId:string) => async (dispatch:any) => {
    dispatch({type: SET_COUNTRIES_LOADING, payload: true});
    
    const url = BASE_URL + ACCOUNTS + USERS + COUNTRIES + `?form_id=${formId}`;
    const response:IAPIReturn = await new APIService().get(url);
    
    if (response.error) {
        console.log("error in user countrylist API", response.results.message);
        dispatch({type: SET_ERROR, payload: response.results.message});
    } else {
        const transformedData = response.results.map(({ id, title }:{id:string, title:string}) => ({
            label: title,
            value: id,
        }));
        dispatch({type: SET_COUNTRIES, payload: transformedData});
    }

    dispatch({type: SET_COUNTRIES_LOADING, payload: false});
}

export const loadData = (formId:string, page:number, params:any = {}) => async (dispatch:any) => {
    dispatch({type: SET_DATA_LOADING, payload: true});
    let paramString = Object.entries(params).reduce((a:string, [key, value], i) => a + `&${key}=${value}`, '')

    const url = BASE_URL + INPUT_FORMS + EDGE_ECOMM + ACTIVE_CUSTOMERS + `?selected_form=${formId}${paramString}`;
    const response:IAPIReturn = await new APIService().get(url);
    
    if (response.error) {
        console.log("error in active customers list API", response.results.message);
        dispatch({type: SET_ERROR, payload: response.results.message});
    } else {
        dispatch(setCustomersListData(response.results));
    }

    dispatch({type: SET_DATA_LOADING, payload: false});
}

export const updateData = (formId:string, data:IActiveCustomer[]) => async (dispatch:any) => {
    const url = BASE_URL + INPUT_FORMS + EDGE_ECOMM + ACTIVE_CUSTOMERS + `/?selected_form=${formId}`;
    
    const newData = data.reduce((a:IActiveCustomer[], e:IActiveCustomer) => [
        ...a,
        {
            ...e,
            id: typeof e.id === 'string' ? null : e.id,
            created_at: e.created_at || moment().format(),
        }
    ], []);

    const responseCreate:IAPIReturn = await new APIService().post(url, newData);

   if (responseCreate.error) {
       console.log("error in active customers create list API", responseCreate.results.message);
       dispatch({type: SET_ERROR, payload: responseCreate.results.message});
   } else {
        dispatch({type: SET_SUCCESS, payload: 'Data was send for approval successfully'});
    }
}

export const clearError = () => async (disaptch:any) => {
    disaptch({ type: SET_ERROR, payload: '' })
}

export const clearSuccess = () => async (disaptch:any) => {
    disaptch({ type: SET_SUCCESS, payload: '' })
}

export const resetUpdateRows = () => (dispatch:any) => {
    dispatch({type: SET_UPDATED_ROWS, payload: {
        newUpdatedRows: []
    }});
}

export const addUpdatedRow = (row:IActiveCustomer, updatedRows:IActiveCustomer[], data:IActiveCustomer[]) => async (dispatch:any) => {
    const { id } = row;

    const alreadyEdited = updatedRows.findIndex((currentRow) => id === currentRow.id);
    let newUpdatedRows:IActiveCustomer[] = [];
    
    if (alreadyEdited >= 0) {
        newUpdatedRows = updatedRows.reduce((all:IActiveCustomer[], currentRow:IActiveCustomer) => [
            ...all,
            ...(id === currentRow.id ? [row] : [currentRow])
        ], []);
    } else {
        newUpdatedRows = [ ...updatedRows, row ];
    }
    
    dispatch({type: SET_UPDATED_ROWS, payload: {
        newUpdatedRows,
        data
    }});
} 

export const setCustomersListData = (data:IActiveCustomer[]) => async (dispatch:any) => {
    dispatch({type: SET_DATA, payload: data});
}

export const setDataForApproval = (data:IActiveCustomer[]) => async (dispatch:any) => {
    dispatch({type: SET_DATA_FOR_APPROVAL, payload: data});
}


// Admin

export const loadDataForApproval = (formId:string, page:number, params:any = {}) => async (dispatch:any) => {
    dispatch({type: SET_DATA_LOADING, payload: true});
    let paramString = Object.entries(params).reduce((a:string, [key, value]) => a + `&${key}=${value}`, '')

    const url = BASE_URL + INPUT_FORMS + EDGE_ECOMM + ACTIVE_CUSTOMERS + `/approval?selected_form=${formId}${paramString}`;
    const response:IAPIReturn = await new APIService().get(url);
    
    if (response.error) {
        console.log("error in active customers list API", response.results.message);
        dispatch({type: SET_ERROR, payload: response.results.message});
    } else {
        dispatch(setDataForApproval(response.results));
    }

    dispatch({type: SET_DATA_LOADING, payload: false});
}