import { message } from "antd";
import { FC, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loadCountries, loadDataForApproval } from "../../actions/eComm/eCommCustomersActions";
import { ICountry } from "../../reducer/ECommCustomers/_interfaces";

const { v4 } = require("uuid");

interface ParamTypes {
    id: string;
}

const ECommerceCustomersApprovalPage:FC = () => {
	const [messageApi, contextHolder] = message.useMessage();
    const { id } = useParams<ParamTypes>();
    const dispatch = useDispatch();

    const [selectedCountries, setSelectedCountries] = useState<ICountry[]>([]);
    
    const countries = useSelector((state:any) => state.eCommCustomers.countries);
    const countriesLoading = useSelector((state:any) => state.eCommCustomers.countriesLoading);
    
    const dataLoading = useSelector((state:any) => state.eCommCustomers.dataLoading);
    const data = useSelector((state:any) => state.eCommCustomers.dataForApproval);

    useEffect(() => {
        dispatch(loadCountries(id));
    }, []);

	useEffect(() => {
		let params = {}
		if (selectedCountries.length > 0) {
			params = {
				country_id: selectedCountries.map(({ value }) => value).join(',')
			}
		}

		dispatch(loadDataForApproval(id, 1, params));
	}, [selectedCountries])

    return (
        <Suspense fallback={<div>An error occured!</div>}>
            {contextHolder}
            <div className="user-view-container">
                Approval page
            </div>
        </Suspense>
    );
}

export default ECommerceCustomersApprovalPage;