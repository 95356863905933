import { read, utils } from 'xlsx';
import { IBeyondCoreBP, IBeyondCoreKBI, IKBI } from '../../../../reducer/ECommRetail/_interfaces';
import { MONTHS } from '../../../eRetailBP/constants';

export const KBI_NSR_MAP:any = {
    "Total NSR": 'totalNSR',
    "B_&_M_com": 'KEECOM001',
    "PP_Marketplaces": 'KEECOM002',
    "FSA_Quick_Commerce": 'KEECOM003',
    "eQSR_&_Others": 'KEECOM004',
    "eWHS": 'KEECOM025',
}

export const KBI_VOLUME_MAP:any = {
    "Total Volume": 'totalVolume',
    "B_&_M_com": 'KEECOM005',
    "PP_Marketplaces": 'KEECOM006',
    "FSA_Quick_Commerce": 'KEECOM007',
    "eQSR_&_Others": 'KEECOM008',
    "eWHS": 'KEECOM026',
}

export const readBCTable = async (file:File, beyondCoreKBIs:IBeyondCoreKBI) => {
    const ab = await file.arrayBuffer();
    const wb = read(ab);
    const beyondCoreSheet = wb.Sheets[wb.SheetNames[0]];
    const beyondCoreData: any = utils.sheet_to_json(beyondCoreSheet);

    const newData = beyondCoreData.reduce((acc:IBeyondCoreBP[], curr:any) => {
        const beyoundCoreKBI = Object.values(beyondCoreKBIs).find((kbi) => {
            if (kbi.country_title === curr.Country) {
                if (curr.Year >= 2025 && kbi.brand_owners === "") {
                    return curr.Brand_Owner === kbi.category || curr.Category === kbi.category
                } else {
                    return (kbi.category === curr.Category && kbi.brand_owners === curr.Brand_Owner)
                        || kbi.category === `${curr.Category} ${curr.Brand_Owner}`
                }
            }

            return false;
        });

        if (beyoundCoreKBI) {
            return [
                ...acc,
                ...MONTHS.map((month) => ({
                    period: month,
                    year: curr.Year,
                    kbi: beyoundCoreKBI?.kbi_id,
                    country: beyoundCoreKBI?.country_id,
                    country_name: beyoundCoreKBI?.country_title || curr.Country,
                    value: curr[month],
                    category: curr.Category,
                    brand_owners: curr.Brand_Owner,
                }))
            ]
        } else {
            return acc
        }
    }, [])

    return newData

}

export const readNSRTable = async (file:File, kbiMaster:IKBI[]) => {
    let finalObject:any = {
        nsrBP: [],
        volumeBP: [],
        marketShareBP: [],
    };

    const KBIS:{[key:string]: string} = kbiMaster.reduce((acc, curr) => ({
        ...acc,
        [curr.kbi_id]: curr.title
    }), {
        totalNSR: "Total NSR",
        totalVolume: "Total Volume",
    })

    const ab = await file.arrayBuffer();

    const wb = read(ab);
    const marketshare = wb.Sheets[wb.SheetNames[2]];
    const nsr = wb.Sheets[wb.SheetNames[3]];
    const volume = wb.Sheets[wb.SheetNames[4]];

    const marketshareData: any = utils.sheet_to_json(marketshare);
    const nsrData: any = utils.sheet_to_json(nsr);
    const volumeData: any = utils.sheet_to_json(volume);
    
    const nsrPeriods = nsrData[0];
    const volumePeriods = volumeData[0];
    
    const constructData = (data:any, periods:any, objKey:string) => {
        data.forEach((d:any, i:number) => {
            if (i > 1) {
                finalObject = {
                    ...finalObject,
                    [objKey]: [
                        ...finalObject[objKey],
                        ...Object.keys(d).reduce((acc:any[], key) => {
                            const kbi = objKey === 'nsrBP'
                                ? KBI_NSR_MAP[data[1][key]]
                                : KBI_VOLUME_MAP[data[1][key]]
                            if (kbi) {
                                const kbiName = KBIS[kbi];
    
                                const periodKeyIndexList = key.split('_')
                                const periodKeyIndex = periodKeyIndexList[periodKeyIndexList.length - 1]
                                
                                let period = periods[periodKeyIndex]

                                if (!period) {
                                    const periodKeys = Object.keys(periods);
                                    for (let pIdx = 0; pIdx < periodKeys.length; pIdx++) {
                                        const cPKey = periodKeys[pIdx]?.split('_');
                                        const cPKeyKeyIndex = cPKey?.[cPKey?.length - 1] || 1;

                                        if (Number(periodKeyIndex) < Number(cPKeyKeyIndex)) {
                                            period = periods[periodKeys[pIdx - 1]]
                                            break;
                                        }
                                    }
                                    if (!period) {
                                        period = periods[periodKeys[periodKeys.length - 1]]
                                    }
                                }

                                return [
                                    ...acc,
                                    ...(kbi ? [
                                        {
                                            country: d.__EMPTY,
                                            period, 
                                            year: d.__EMPTY_1,
                                            kbi,
                                            value: isNaN(d[key]) ? null : Number(d[key]), //in case of " " (isNaN makes it 0)
                                            kbi_name: kbiName
                                        }] : [])
                                ]
                            }

                            return acc
                        }, [])
                    ]
                }
            }
        })
    }

    constructData(nsrData, nsrPeriods, 'nsrBP');
    constructData(volumeData, volumePeriods, 'volumeBP');

    return finalObject;
}