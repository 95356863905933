
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const DropDownCountry = (props) => {
    const [selectedValue, setSelectedValue] = React.useState("")
    const [selectedLabel, setSelectedLabel] = React.useState("")

    const handleOnChange = (e) => {
        let selectedText = e.target.options[e.target.selectedIndex].text
        if( props?.handleSaveCountry){
            props?.handleSaveCountry(e.target.value);
        }
        if( props?.handleSaveWeek){
            props?.handleSaveWeek(e.target.value);
        }

        if(props?.handleSaveSalesOrg){
            props?.handleSaveSalesOrg(e.target.value);
        }
        
        setSelectedLabel(selectedText)
        setSelectedValue(e.target.value)
        props.setParentDropdownValue(e.target.value, selectedText, props.title)
    }

     //useEffect Method
     useEffect(() => {
        let mounted = true
        if (mounted) {
            setSelectedValue(props.preSelectedValue);
        }
        return function cleanup() {
            mounted = false
        }
    }, [])
    return (
        <>
            <div>
                <label style={{ paddingLeft: "5px", fontSize: "small" }}>{props.title}</label>
            </div>
            <select
                name="teamName"
                className="form-select form-select-sm"
                onChange={handleOnChange}
                value={selectedValue}
                value={selectedValue !== "" ? selectedValue : ""}
                required
            >
                <option value='' disabled> --Select-- </option>
                {props.options.map(each => (
                    <option
                        className="select-dropdown-option"
                        key={each.label}
                        value={each.value}
                        name={each.label}
                    // defaultValue={each.id == selectedValue}
                    >
                        {each.label}
                    </option>
                ))}
            </select>
        </>
    );
};

export default DropDownCountry;